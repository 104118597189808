import axios, { AxiosError } from "axios";
import toast from "react-hot-toast";
import { redirect } from "react-router-dom";
import routes from "./routes";

// from firebase-functions https uppercases as per json response
export declare type FunctionsErrorCode =
	| "ok"
	| "cancelled"
	| "unknown"
	| "invalid-argument"
	| "deadline-exceeded"
	| "not-found"
	| "already-exists"
	| "permission-denied"
	| "resource-exhausted"
	| "failed-precondition"
	| "aborted"
	| "out-of-range"
	| "unimplemented"
	| "internal"
	| "unavailable"
	| "data-loss"
	| "unauthenticated";
export type ApiError = {
	message: string;
	status: Uppercase<FunctionsErrorCode>;
	details: any;
};

type ApiAction = "list" | "read" | "create" | "update" | "delete";
type ApiPath =
	| "/auth"
	| "/user/profile"
	| "/user/orders"
	| "/user/bookmarks"
	| "/vouchers";

export async function apiRequest<Response = any>(
	action: ApiAction,
	path: ApiPath,
	data?: any,
) {
	return axios
		.post<{ result: Response }>("/api" + path, { data: { action, data } })
		.then((r) => r.data.result)
		.catch((err: AxiosError<{ error: ApiError }>) => {
			throw (
				err.response?.data.error || {
					message: err.message,
					status: err.name,
					details: err.cause,
				}
			);
		});
}

type CachedAction = "list" | "read";
export type CacheKey =
	| `${CachedAction}:${ApiPath}`
	| `${ApiAction}:${ApiPath}/${string}`;
export type CacheData<T = any> = T;

export const cache = new Map<CacheKey, Promise<CacheData>>();

export async function cachedApiRequest(
	action: CachedAction,
	path: ApiPath,
	data?: { id?: string; refresh?: boolean } & Record<string, any>,
) {
	const refresh = Boolean(data?.refresh);
	const key: CacheKey = data?.id
		? `${action}:${path}/${data.id}`
		: `${action}:${path}`;
	const prev = cache.get(key) && (await cache.get(key));
	if (!refresh && prev) {
		return JSON.parse(JSON.stringify(prev));
	}
	if (refresh) {
		toast.loading("refreshing…", { id: "refresh" });
	}
	const d = apiRequest(action, path, data);
	return d
		.then((v) => {
			cache.set(key, d); // only cache successful responses
			return v;
		})
		.catch((err: ApiError) => {
			if (err.status === "UNAUTHENTICATED") throw redirect(routes.signIn);
			throw err;
		})
		.finally(() => toast.dismiss("refresh"));
}

export const localCache = new Map();
