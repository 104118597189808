import { routes } from "@launerlondon/shop-router";
import { MarketingStatus } from "@launerlondon/shop-types";
import { useEffect, useState } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import {
	generatePath,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import NewsletterSignup from "../components/NewsletterSignup";
import { decodeCartShare } from "../lib/cart";
import {
	addCartItem,
	setCartId,
	setCustomerId,
	setMarketingStatus,
} from "../redux/actions";

type Props = ConnectedProps<typeof connector> & React.PropsWithChildren;

const connector = connect(null, {
	setCustomerId,
	setCartId,
	setMarketingStatus,
	addCartItem,
});

const CartShare: React.FC<Props> = (props) => {
	const hash = useParams<{ "*": string }>()["*"];
	const location = useLocation();
	const navigate = useNavigate();
	const [ready, setReady] = useState<boolean>();

	useEffect(() => {
		if (hash) {
			const cid = new URLSearchParams(location.search).get("cid");
			if (cid) {
				props.setMarketingStatus(MarketingStatus.Allowed);
				const [customerId, cartId] = cid.split(" ");
				props.setCustomerId(customerId);
				props.setCartId(cartId);
			}
			decodeCartShare(hash)
				.then((cartData) => cartData.forEach(props.addCartItem))
				.catch(
					(err) => (console.error("Error parsing cartUrl", err), []),
				);

			navigate(generatePath(routes.checkout), {
				state: { hashRedirect: true },
			});
		} else {
			setReady(true);
		}
	}, [hash]);

	if (ready) {
		const hashRedirect = location.state?.hashRedirect;
		return (
			<>
				{props.children}
				{hashRedirect && (
					<NewsletterSignup
						showUnless={MarketingStatus.RefusedProductList}
						delay={5000}
					/>
				)}
			</>
		);
	}
	return null;
};

export default connector(CartShare);
