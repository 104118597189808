import { Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useLocalization } from "@launerlondon/l10n";
import { routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { generatePath, useMatch, useNavigate } from "react-router-dom";
import { sanitizeSearchQuery } from "../lib/utils";

type Props = React.HTMLAttributes<HTMLAnchorElement>;

const isMac = navigator.userAgent.includes("Mac");

export default function SearchField({ className }: Props) {
	const [active, setActive] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();
	const searchQuery = useMatch("/s/:search")?.params.search;
	const { l10n } = useLocalization();
	let timeout: number;

	const submit = useCallback(
		(e?: FormEvent) => {
			e?.preventDefault();
			clearTimeout(timeout);
			const value = inputRef.current?.value || "";
			if (value) {
				const search = sanitizeSearchQuery(value);
				navigate(generatePath(routes.searchView, { search }));
			}
			e && setActive(false);
		},
		[inputRef],
	);

	useEffect(() => {
		function onKeyup(e: KeyboardEvent) {
			if ((isMac ? e.metaKey : e.ctrlKey) && e.key === "k") {
				e.preventDefault();
				setActive(true);
			}
		}
		addEventListener("keydown", onKeyup);
		return () => removeEventListener("keydown", onKeyup);
	}, []);

	const onShow = useCallback(() => {
		if (!active) return;
		inputRef.current?.focus();
		inputRef.current?.setSelectionRange(0, 9999);
	}, [active]);

	const onChange = useCallback(() => {
		clearTimeout(timeout);
		timeout = self.setTimeout(submit, 1e3);
	}, []);

	return (
		<div className={className}>
			<MagnifyingGlassIcon
				className="relative h-5 w-5 cursor-pointer"
				onClick={() => setActive(true)}
			/>
			<Transition
				as="form"
				show={active}
				className={cx(
					"fixed inset-0 z-40 flex items-start justify-center bg-black/50",
					"text-center font-sans",
					"transition-opacity",
				)}
				enterFrom="opacity-0"
				leaveTo="opacity-0"
				acceptCharset="ISO-8859-1"
				onSubmit={submit}
			>
				<div
					className="fixed inset-0"
					onClick={() => setActive(false)}
				/>
				<Transition.Child
					as="label"
					enterFrom="-translate-y-10"
					leaveTo="translate-y-10"
					className="relative mt-40 rounded bg-gray-100/90 p-4 shadow transition-transform"
					afterEnter={onShow}
				>
					<div className="flex items-center">
						<MagnifyingGlassIcon className="h-5 w-5" />
						<input
							ref={inputRef}
							className="ln-subtitle-sans min-w-[300px] border-none bg-transparent text-base leading-none focus:ring-0"
							defaultValue={sanitizeSearchQuery(searchQuery)}
							type="search"
							onChange={onChange}
							placeholder={l10n.getString("search-placeholder")}
							onKeyDown={(e) =>
								e.key === "Escape" && setActive(false)
							}
						/>
						<small className="hidden rounded-full bg-gray-300 px-2 py-1  text-xs text-gray-600 lg:block">
							Shortcut: {isMac ? "⌘" : "Ctrl"} K
						</small>
					</div>
				</Transition.Child>
			</Transition>
		</div>
	);
}
