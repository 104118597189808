import { CartItemSnapOption } from "@launerlondon/products";
import { voucherCurrencies } from "@launerlondon/shared";
import { ProductLoaderData } from "@launerlondon/shop-router";
import { RootState } from "@launerlondon/shop-types";
import cx from "classnames";
import { useEffect, useState } from "react";
import { Range } from "react-range";
import { useSelector } from "react-redux";
import { useActionData, useLoaderData } from "react-router-dom";
import SelectInput from "./SelectInput";

type Props = {
	onChange(value: number, label: string): void;
};

const VoucherSelectInput: React.FC<Props> = (props) => {
	const data = useActionData() as CartItemSnapOption[] | undefined;
	const { rates } = useLoaderData() as ProductLoaderData;
	const [currency, setCurrency] = useState("GBP");
	const [amount, setAmount] = useState(500);
	const userCurrency = useSelector((s: RootState) => s.locale.currency);

	useEffect(() => {
		const label = [amount, currency].join(" ");
		//const value = multipliers.find((m) => m.label === label)?.value;
		const value = amount / (rates[currency] || 0);
		if (value) props.onChange(value, label);
	}, [currency, amount, userCurrency]);

	useEffect(() => {
		if (!data) return;
		data.forEach((opt) => {
			if (opt.type === "voucher_credit") {
				const [$amount, $currency] = opt.value.split(" ");
				if ($amount && $currency) {
					setCurrency($currency);
					setAmount(parseInt($amount));
				}
			}
		});
	}, [data]);

	return (
		<>
			<SelectInput
				id="voucher_currency"
				label="product-selector-voucher-currency"
				defaultValue={currency}
				options={voucherCurrencies.map((c) => ({ label: c, value: c }))}
				onChange={(value: string) => {
					setCurrency(value);
					return 0;
				}}
			/>
			<div className="mb-10 mt-3 grid grid-cols-4 items-center">
				<h3>Amount</h3>
				<div className="col-span-3 mr-2 ">
					<Range
						step={50}
						min={50}
						max={3000}
						values={[amount]}
						onChange={(values) => setAmount(values[0]!)}
						renderTrack={({ props, children }) => (
							<div
								{...props}
								className={cx(
									"relative h-2 rounded bg-gray-100 bg-gray-100",
								)}
							>
								<div className="absolute top-2 w-full text-xs text-gray-300">
									<div className="absolute inset-x-0 top-4 -ml-4 w-7 text-center">
										50
									</div>
									<div className="absolute inset-x-1/3 top-4 -ml-5 w-7">
										1000
									</div>
									<div className="absolute inset-x-2/3 top-4 -ml-4 w-7">
										2000
									</div>
									<div className="absolute right-0 top-4 -mr-2 w-7 text-center">
										3000
									</div>
								</div>
								{children}
							</div>
						)}
						renderThumb={({ props }) => (
							<div
								{...props}
								className="relative h-5 w-5 rounded-full bg-gray-800"
							>
								<div
									className={cx(
										"absolute top-7 w-16",
										"whitespace-nowrap rounded bg-black",
										"p-2 text-center text-xs text-white",
										amount > 2850
											? "right-0"
											: amount > 200
											? "-left-6"
											: "left-0",
									)}
								>
									<div className="relative">
										{amount} {currency}
									</div>
								</div>
							</div>
						)}
					/>
				</div>
			</div>
		</>
	);
};

export default VoucherSelectInput;
