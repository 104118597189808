/// <reference lib="ESNext" />
/// <reference lib="DOM" />
import React, { Children, useState, useEffect } from "react";
import { FluentBundle, FluentResource } from "@fluent/bundle";
import {
	LocalizationProvider as FluentProvider,
	ReactLocalization,
} from "@fluent/react";
import { negotiateLanguages } from "@fluent/langneg";

enum Locale {
	EN = "en",
	ZH = "zh-CN",
}

const defaultLocales =
	typeof navigator === "undefined" ? ["en"] : navigator.languages;
const availableLocales = [Locale.EN, Locale.ZH];

async function fetchLocaleBundles(locales: Locale[]): Promise<FluentBundle[]> {
	const bundles: FluentBundle[] = [];
	for (const locale of locales) {
		let ftl;
		switch (locale) {
			case Locale.EN:
				ftl = import("../../locales/en");
				break;
			case Locale.ZH:
				ftl = import("../../locales/zh-CN");
				break;
		}
		if (ftl !== undefined) {
			const messages = (await ftl).default;
			const bundle = new FluentBundle(locale);
			bundle.addResource(new FluentResource(messages));
			bundles.push(bundle);
		}
	}
	return bundles;
}

const LocalizationProvider: React.FC<
	React.PropsWithChildren<{ locale: string }>
> = (props) => {
	const [l10n, setL10n] = useState<ReactLocalization>();

	useEffect(() => {
		const locales = negotiateLanguages(
			[props.locale, ...defaultLocales],
			availableLocales,
		) as Locale[];
		fetchLocaleBundles(locales).then((bundles) => {
			setL10n(new ReactLocalization(bundles));
		});
	}, [props.locale]);

	if (!l10n) {
		return null;
	}

	return (
		<FluentProvider l10n={l10n}>
			{Children.only(props.children)}
		</FluentProvider>
	);
};

export default LocalizationProvider;
