import { Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-sustainability.jpg";
import royalWarrantUrl from "../assets/royal-warrant-logo.png";
import queenUrl from "../assets/royal-warrant-queen.jpg";
import approachUrl from "../assets/sustainability-approach.jpg";
import biodiversityUrl from "../assets/sustainability-biodiversity.jpg";
import craftspeopleUrl from "../assets/sustainability-craftspeople.jpg";
import figure1Url from "../assets/sustainability-grid-item-1.jpg";
import figure2Url from "../assets/sustainability-grid-item-2.jpg";
import figure3Url from "../assets/sustainability-grid-item-3.jpg";
import figure4Url from "../assets/sustainability-grid-item-4.jpg";
import owlUrl from "../assets/sustainability-owl.jpg";
import planetMarkUrl from "../assets/sustainability-planet-mark.jpg";
import repairUrl from "../assets/sustainability-repair.jpg";
import slowFashionUrl from "../assets/sustainability-slow-fashion.jpg";

const Sustainability: React.FC = () => {
	useMetaTags({
		title: "Corporate and Social Responsibility",
		description:
			"All leathers are sourced with due diligence using European calf skins which meet and are governed by EU legislation.",
		image: bannerUrl,
	});
	return (
		<div className="text-sm">
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<header className="border-b py-10">
				<div className="container grid-cols-2 max-lg:space-y-4 lg:grid">
					<h1 className="ln-title">Our Sustainability Journey</h1>
					<p className="text-lg">
						Launer is an independently owned family business
						creating luxury leather goods since 1941 and a Royal
						Warrant holder since 1968 for the supply of handbags and
						leather goods to Her Late Majesty Queen Elizabeth II.
					</p>
				</div>
			</header>
			<div className="container my-10 grid-cols-2 lg:grid">
				<div className="col-start-2 space-y-4">
					<p>
						It has been under its current ownership since 1981 and
						is recognised globally for its beautiful, iconic and
						timeless designs that endure through the journey of
						life, passed on as treasured heirlooms. They embody a
						sustainable approach by limiting the number of
						collections produced, all handcrafted in England with a
						repairable service.
					</p>
					<p>
						Launer is a personal business with a commitment to
						nurturing, training and empowering their craftspeople.
						It’s a journey that they are committed to, encompassing
						cultural, social and manufacturing methods that retain
						the enduring quality of their creations whilst seeking
						to strive for sustainable improvements.
					</p>

					<div className="border-t pt-6">
						<h2 className="ln-subtitle-sans">Our Key Goals</h2>
						<ul className="ml-4 list-disc">
							<li>
								Our continuation to explore materials that
								represent an authentic alternative without
								compromise
							</li>
							<li>
								Greater emphasis placed for a transparent and
								traceable journey for products supplied from
								tanneries partners
							</li>
							<li>
								A significant reduction of our Carbon emissions
							</li>
							<li>
								A paper-free environment at all Launer locations
								– offices, showroom and factory
							</li>
							<li>
								Set new SBT’s in line with Planet Mark’s
								certification
							</li>
							<li>Produce a Launer ESG report in 2025</li>
							<li>
								To solely work with The Leather Working Group
								accredited suppliers
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className="container">
				<figure className="my-10 grid grid-cols-2 gap-4 md:grid-cols-4">
					<img className="" src={figure1Url} />
					<img className="" src={figure2Url} />
					<img className="" src={figure3Url} />
					<img className="" src={figure4Url} />
				</figure>
			</div>
			<Row.WithImage
				image={approachUrl}
				className="bg-[#F7F0F4] text-left"
				reverse
			>
				<h3 className="ln-subtitle">
					Sustainability: A passionate approach
				</h3>
				<p>
					A journey that began back in the early 80’s when the company
					was bought by the current owner. They held the belief that
					there was an alternative approach to fast fashion
					manufactured abroad, one that celebrated timeless, beautiful
					products crafted using traditional methods in England. The
					brand already enjoyed an illustrious and fascinating
					heritage for fine leather goods supplied to Royalty and
					Society ladies and the vision was to uphold this coveted
					time-honoured approach and legacy.
				</p>
				<p>
					Launer continued to employ artisans skilled in historic
					leather making methods to produce expertly made bags and
					small leather goods and accessories that could last a
					lifetime.
				</p>
				<p>
					To this day, every item is handmade in their distinguished
					Grade II listed Edwardian building in the heart of Walsall,
					an area renowned for its leather manufacturing industry. 
				</p>
			</Row.WithImage>
			<Row.WithImage image={slowFashionUrl} className="text-left">
				<h3 className="ln-subtitle">
					Sustainability through Slow fashion
				</h3>
				<p>
					The original pioneer of Slow Fashion with iconic seasonless
					handbags that stand the test of time. Styles created in the
					70’s, 80’s and 90’s continue to have contemporary appeal,
					season after season, beautifully reimagined using colour and
					leather finishes, demonstrating that beautiful design has
					long term appeal. This approach resonates with customers.
				</p>
				<p>
					The Legacy Collection embodies the spirit of longevity
					featuring 7 of our most iconic handbags from the past five
					decades that are beloved heritage styles. Each one has a
					story and reflects the importance and beauty in design that
					transcend time and represent our detailed, fine leather
					craftsmanship.
				</p>
				<p>
					Furthermore, all products at Launer are made to order,
					producing only what is needed, eliminating excess. All
					designs are sold at full price throughout the year, holding
					their value as investment pieces with a waiting list for the
					handbags. Every item is handcrafted and takes up to some
					weeks to create and then despatch Worldwide.
				</p>
			</Row.WithImage>
			<Row.WithImage image={repairUrl} reverse className="bg-[#F1F6EC]">
				<h3 className="ln-subtitle">
					Sustainability Via our Repair Service
				</h3>
				<p>
					All Launer products are crafted to enjoy for a lifetime.
					Customers maintain them as keepsakes which become treasured
					mementos through the years and passed on down the
					generations.{" "}
				</p>
				<p>
					Much loved bags and accessories enjoy a renewed lease of
					life with The Repair Service carried out by a dedicated team
					of leather craftspeople. By investing in repairs, the life
					of the designs are prolonged
				</p>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#F8F6F6]"
				imageClassName="bg-[#F0EEE8] grid place-items-center max-lg:aspect-auto"
				image={
					<div className="max-w-xl space-y-4 py-10 max-lg:container lg:px-10">
						<h3 className="ln-subtitle">
							Sustainability of production
						</h3>
						<p>
							All products are made to order, a unique business
							model, which eliminates stock and excess items
							housed in storage. Leather supplies and fittings are
							also kept to a minimum using only what is needed.
							This considered approach ensures that every style
							holds its value as it is crafted specifically to the
							requirements of customers and the materials used
							recently delivered.
						</p>
						<p>
							We have adopted a policy that embraces virtually no
							waste by using surplus leather for all inner parts
							of small leather goods, handbag bases, gussets and
							straps. Every piece of leather must meet our
							rigorous quality inspection team’s approval before
							construction.
						</p>
					</div>
				}
			>
				<h3 className="ln-subtitle">Sustainability of packaging</h3>
				<p>
					Packaging is an important aspect of our sustainable mission
					with much consideration has been given to responsible
					sourcing. Since 2023, all our packaging is now ‘FSA
					approved’, fully recyclable and where possible created from
					recycled materials. We use packaging in the most economical
					way whilst maintaining the integrity of the products.
				</p>
				<p>
					New innovations have been integrated into our packaging
					range such as paper rope handles. We continue to work with
					our community of partners and suppliers to eliminate all
					plastic within Launer’s business operations.
				</p>
			</Row.WithImage>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image !top-0"
								style={{ backgroundImage: `url(${owlUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner h-[400px] bg-white lg:h-[800px]"
			/>
			<Row.WithImage
				image={queenUrl}
				className="text-left"
				imageClassName="lg:m-10"
			>
				<img
					src={royalWarrantUrl}
					className="block w-36 max-lg:mx-auto"
				/>
				<h3 className="ln-subtitle">
					Sustainability and The Royal Warrant
				</h3>
				<p>
					The granting of a Royal Warrant is one of the most
					prestigious accolades a British company can be awarded. For
					more than 50 years, Launer has held the warrant for handbags
					and small leather goods to Her Late Majesty Queen Elizabeth
					II.
				</p>
				<p>
					To qualify and maintain a warrant for this unprecedented
					length of time a company must demonstrate a significant
					commitment and programme of sustainability in their
					approach, practices and policies from product, methods,
					sourcing and workforce.
				</p>
			</Row.WithImage>
			<Row.WithImage
				image={craftspeopleUrl}
				reverse
				className="bg-[#F7F0F4]"
			>
				<h3 className="ln-subtitle">Sustainability and Craftspeople</h3>
				<p>
					The heartbeat of Launer is their craftspeople – women and
					men with a passion and exceptional skill for crafting the
					remarkable leather goods that the brand is synonymous
					for. These valued skilled artisans take many months and even
					years to train with highly valued apprenticeships in the art
					of traditional leather making, a journey the company is
					committed to and all from the local area.{" "}
				</p>
				<p>
					The ethos and culture reflects a diverse and inclusive
					collective community with the focus on encouragement,
					nurture and incentivisation. The pride each person feels in
					each finished piece which may be seen through the pictures
					customers send, on Royalty and dignitaries.
				</p>
			</Row.WithImage>
			<Row.WithImage image={planetMarkUrl} className="bg-[#F8F6F6]">
				<h3 className="ln-subtitle">
					Planet Mark Accreditation – helping to reduce our Carbon
					footprint
				</h3>
				<p>
					An important step for Launer is the partnership with Planet
					Mark who, since 2013 has embarked on a mission to empower
					change for a brighter future.
				</p>
				<p>
					This will help Launer reduce our carbon emissions with a
					transition to a net zero organisation. We can make the right
					and informed decisions and one which our customers will
					embrace.
				</p>
				<p>
					We are setting our science-based targets (SBT’s) during this
					year’s accreditation period in line with meeting our total
					carbon reduction goal this year. SBT’s are targets adopted
					by companies to reduce emissions. They are considered SBTs
					if they are in line with what the most recent scientific
					research has established as necessary to achieve objectives.
				</p>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#F0EEE8]"
				imageClassName="bg-[#F1F6EC] grid place-items-center max-lg:aspect-auto"
				image={
					<div className="max-w-xl space-y-4 py-10 max-lg:container lg:px-10">
						<h3 className="ln-subtitle">
							Resources and Waste Management
						</h3>
						<p>
							We are taking significant steps, working with Planet
							Mark by controlling our consumption of natural
							resources both internally and with suppliers: water,
							gas and electricity. We  monitor the consumption of
							each one and dedicated to reduce all elements where
							possible.
						</p>
						<p>
							We are also reducing our waste raw material products
							and making sure they are recycled, donated or
							reused. We also regularly check and update on the
							chemical legislations of products used during
							production and use only the most environmentally
							friendly alternatives. 
						</p>
					</div>
				}
			>
				<h3 className="ln-subtitle">Health and Safety</h3>
				<p>
					Launer has always placed paramount importance in the
					creation of a safe environment for our employees in all our
					business segments. We continuously work to improve our
					management of health and safety information.
				</p>
				<p>
					Training and supervision is implemented to enable employees
					to avoid hazards and to positively promote the health and
					safety of everyone while at work.
				</p>
				<p>
					Relevant employees are trained in first aid and fire
					evacuation procedures, with all safety equipment tested and
					maintained on a regular basis.
				</p>
			</Row.WithImage>
			<Row.WithImage
				image={biodiversityUrl}
				imageClassName="bg-left-bottom lg:m-10"
			>
				<h3 className="ln-subtitle">Biodiversity and Community</h3>
				<p>
					Launer is committed in our pursuit of diagnosing our impacts
					on biodiversity across our entire supply chain and further
					define our goals based on our SBT’S.
				</p>
				<p>
					Our mission within our own Launer community is the
					continuation and retention of staff at all levels providing
					an environment for them to prosper, develop and excel.
					Launer colleagues are encouraged and presented with
					opportunities on a monthly basis as part of their ongoing
					mentor and development programme.
				</p>
				<p>
					At Launer, the community at large in the local area is
					important and how we may best provide support. For some
					time, we have provided insight and materials to local
					community groups to enhance and further their craft and in
					some cases, introduce them to the tradition of leathercraft.
				</p>
				<p>
					Alongside this, is a longstanding relationship with The
					Queen Elizabeth Scholarship Trust (QEST) that transforms the
					careers of aspiring crafts people by funding their training
					and education, creating pathways to excellence and
					strengthening the future of the UK’s craft sector. This
					charity is close to Launer’s own core values and the overall
					preservation of all British craft. Items such as the QEST
					Limited Edition Handbag have been made in partnership with
					the charity where a proportion of the proceeds are donated
					to QEST.
				</p>
				<p>
					Launer is committed to furthering relationships with local
					charities and community groups in order to extend the
					knowledge of learning and craft in the UK.
				</p>
			</Row.WithImage>
		</div>
	);
};

export default Sustainability;
