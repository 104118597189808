import { RelatedPages } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-manufacturing.jpg";
import image2Url from "../assets/manufacturing-factory.jpg";
import image1Url from "../assets/manufacturing-workmanship.jpg";
import related3Url from "../assets/related-craftsmanship.jpg";
import related2Url from "../assets/related-heritage.jpg";
import related1Url from "../assets/related-royalwarrant.jpg";

const Manufacturing: React.FC = () => {
	useMetaTags({
		title: "British Manufacturing",
		description:
			"Handcrafted in Britain is part of the Launer legacy with each and every handbag, purse and wallet all made from the very same factory",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2 className="font-display">British Manufacturing</h2>
					<div>
						<p>
							Launer’s heritage of craftmanship is as illustrious
							as its refined products. The company has remained
							loyal in its pursuit of British manufacturing
							throughout its impressive history, equating to over
							half a century.
						</p>
					</div>
				</header>

				<div className="ln-section-grid gap-10">
					<figure>
						<img src={image1Url} className="block" />
					</figure>
					<div>
						<p>
							Handcrafted in Britain is part of the Launer legacy
							with each and every handbag, purse and wallet all
							made from the very same factory – a magnificent and
							imposing Edwardian building previously used for the
							manufacturer of fine leather bridlery in the
							previous century .
						</p>
						<p>
							Launer’s philosophy has always been to nurture and
							prosper local talent, young and old, developing
							apprenticeship and training programmes that result
							in true artisans.
						</p>
						<p className="ln-pullout">
							The craftspeople and infinite expertise that goes
							into the making of a single Launer product, gives an
							indication of the outstanding skill and dedication
							of each and every worker and why at Launer we call
							them artisans.
						</p>
						<p>
							Through its flexible hours scheme it enables all
							staff with different needs to achieve the best
							work/life balance and thus spawns contented
							craftsman – men and women whose longevity of service
							to Launer spans decades in many circumstances.
						</p>
						<figure>
							<img src={image2Url} />
							<figcaption className="ln-figcaption col-span-2">
								<p>
									Handcrafted in Britain is part of the Launer
									legacy with each and every handbag, purse
									and wallet all made from the same factory.
								</p>
							</figcaption>
						</figure>
					</div>
				</div>
			</div>
			<RelatedPages
				pages={[
					{
						title: "Royal Warrant",
						link: "/royal-warrant",
						image: related1Url,
					},
					{
						title: "Heritage",
						link: "/heritage-story",
						image: related2Url,
					},
					{
						title: "Craftsmanship",
						link: "/craftsmanship",
						image: related3Url,
					},
				]}
			/>
		</div>
	);
};

export default Manufacturing;
