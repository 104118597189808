type FBInit = (eventType: "init", pixelId: string) => void;
type EventName =
	| "PageView"
	| "ViewContent"
	| "AddToCart"
	| "InitiateCheckout"
	| "Purchase";
type BaseParams = {
	content_type?: "product" | "product_group";
	content_ids?: string[];
	content_name?: string;
	currency?: string;
	num_items?: number;
	value?: number;
	contents?: { id: string; quantity: number }[];
};
type FBTrackEvent = (
	eventType: "track",
	eventName: EventName,
	params?: BaseParams,
) => void;

declare global {
	interface Window {
		fbq?: any & FBInit & FBTrackEvent;
		_fbq?: any;
	}
}

const FACEBOOK_PIXEL_ID = String(import.meta.env.VITE_FACEBOOK_PIXEL_ID);

function consent(yes: boolean) {
	// https://developers.facebook.com/docs/meta-pixel/implementation/gdpr
	if (yes) {
		self.fbq("consent", "grant");
		self.fbq("dataProcessingOptions", ["LDU"], 0, 0);
		return;
	}
	self.fbq("consent", "revoke");
	self.fbq("dataProcessingOptions", []);
}

export async function install(): Promise<FBTrackEvent | void> {
	return new Promise((resolve) => {
		if (self.fbq) {
			consent(true);
			return resolve(self.fbq);
		}
		const n: any = (self.fbq = function () {
			n.callMethod
				? n.callMethod.apply(n, arguments)
				: n.queue.push(arguments);
		});
		if (!self._fbq) {
			self._fbq = n;
		}
		n.push = n;
		n.loaded = true;
		n.version = "2.0";
		n.queue = [];

		const script = document.createElement("script");
		script.async = true;
		script.src = "https://connect.facebook.net/en_US/fbevents.js";
		document.body.appendChild(script);

		consent(true);
		self.fbq("init", FACEBOOK_PIXEL_ID);
		resolve(self.fbq);
	});
}

export function uninstall() {
	if (!self.fbq) return;
	consent(false);
}

export const trackEvent: FBTrackEvent = async (
	eventType,
	eventName,
	params,
) => {
	const fbq = await install();
	fbq && fbq(eventType, eventName, params);
};
