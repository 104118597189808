import {
	createContext,
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

type PermType = "essential" | "analytics" | "marketing";
type CookiePerms = Record<PermType, boolean>;
type HookData = [CookiePerms, Dispatch<SetStateAction<CookiePerms>>, string];

const dnt = Boolean(navigator.doNotTrack);
const defaultPermsCode = dnt ? "000" : "011";
const defaultPerms = { essential: false, analytics: !dnt, marketing: !dnt };

function setCookie(cname: string, cvalue: string | null, exdays = 7) {
	const d = new Date();
	if (cvalue === null) {
		exdays = -1;
	}
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();
	let cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	if (location.host.match("launer.com")) {
		cookie += ";domain=launer.com";
	}
	document.cookie = cookie;
}

function getPermsCode() {
	return (
		document.cookie.match(/cookie_consent=(\d{3})/)?.pop() ||
		defaultPermsCode
	);
}

export function getConsentCookie() {
	const perms = getPermsCode();
	return perms.split("").reduce<CookiePerms>((p, c, i) => {
		const value = Boolean(Number(c));
		const key = ["essential", "analytics", "marketing"][i] as
			| PermType
			| undefined;
		if (key) p[key] = value;
		return p;
	}, defaultPerms);
}

const context = createContext<HookData>([
	defaultPerms,
	() => defaultPerms,
	defaultPermsCode,
]);

export const CookieContext: React.FC<React.PropsWithChildren> = (props) => {
	const [cookieConsent, setCookieConsent] = useState(defaultPerms);

	useEffect(() => updateState(getConsentCookie()), []);

	const updateState = useCallback((value: SetStateAction<CookiePerms>) => {
		setCookie("cookie_consent", Object.values(value).map(Number).join(""));
		return setCookieConsent(value);
	}, []);

	return (
		<context.Provider value={[cookieConsent, updateState, getPermsCode()]}>
			{props.children}
		</context.Provider>
	);
};

export const useCookieConsent = () => useContext(context);
