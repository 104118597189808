import { MessageForm } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-about.jpg";

const Wholesale: React.FC = () => {
	useMetaTags({
		title: "Wholesale accounts",
		description:
			"Our wholesale division is happy to assist with enquiries and advise on becoming an official accredited Launer stockist.",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container pb-20">
				<header className="ln-intro-grid">
					<h2 className="font-display">Wholesale accounts</h2>
					<div>
						<p>
							Our wholesale division is happy to assist with
							enquiries and advise on becoming an official
							accredited Launer stockist. Launer is sold in
							countries Internationally including Japan, United
							States, China, Korea and Italy. Available in
							prestige locations and department stores with
							shop-in-shop presentations a key feature.
						</p>
						<p>
							We will be delighted to discuss distribution and
							agency representation and offer support from our
							marketing team to deliver the Launer service that we
							are renowned for.
						</p>
					</div>
				</header>
				<div className="ln-section-grid gap-10">
					<div>
						<h3 className="ln-subtitle">Launer London Ltd</h3>
					</div>
					<div>
						<p>
							Classic House
							<br />
							365A Limpsfield Road
							<br />
							Warlingham
							<br />
							Surrey
							<br />
							CR6 9HA
							<br />
							United Kingdom
							<br />
							UK tel: 01883 625 562
							<br />
							International tel: 0044 1883 625 562
							<br />
							Email: sales@launer.com
						</p>
						<p className="mb-4">
							Please send us a message using the form below.
						</p>
						<MessageForm
							action="wholesale"
							fields={[
								{ title: "Name", required: true },
								{
									title: "Email",
									type: "email",
									required: true,
								},
								{ title: "Subject" },
								{
									title: "Message",
									type: "textarea",
									required: true,
								},
							]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Wholesale;
