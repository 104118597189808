import stylesheet, { colors, fonts, media } from ".";

export default stylesheet({
	container: {
		...fonts.sans,
		display: "block",
		//gridColumn: '1 / 5',
	},
	label: {
		display: "block",
		fontSize: "0.875rem",
		paddingTop: "0.5em",
		paddingBottom: "0.125em",
		lineHeight: 1.4,
		userSelect: "none",
		[media.mobile]: { fontSize: "0.75em" },
	},
	input: {
		appearance: "none",
		display: "block",
		font: "inherit",
		fontSize: "1rem",
		lineHeight: 1.5,
		width: "100%",
		outline: `1px solid #DEDEDE`,
		borderWidth: 0,
		borderRadius: 0,
		padding: ".25em .5em",
		boxSizing: "border-box",
		backgroundColor: "white",
		":focus": { outline: `1px solid ${colors.accent}` },
		":-moz-ui-invalid": {
			outline: "1px solid tomato",
			boxShadow: "none",
		},
	},
});
