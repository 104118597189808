import {
	Localized,
	LocalizedColors,
	useLocalization,
} from "@launerlondon/l10n";
import {
	CartItemSnapOption,
	Product,
	ProductImage,
	filterCustomOptions,
	fmtProductName,
} from "@launerlondon/products";
import {
	defaultRoundFactor,
	findSku,
	fmtSku,
	getDeliveryTimes,
	getItemTotal,
} from "@launerlondon/shared";
import { Gallery, Price, Share, Tabs } from "@launerlondon/shop-components";
import BookmarkButton from "@launerlondon/shop-components/src/BookmarkButton";
import { useConfig } from "@launerlondon/shop-hooks";
import type { RootState } from "@launerlondon/shop-types";
import cx from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import collections from "../../assets/collections.json";
import { AddToCartButton } from "../components";
import ProductItemSelectors from "./ProductItemSelectors";

type Props = { product: Product };
type HeaderProps = Props & {
	bespoke: Boolean;
	className?: string;
	freeDelivery?: boolean;
};

function isSwatchOption<T extends CartItemSnapOption>(
	o: T,
): o is Extract<T, { type: "swatch" }> {
	return o.type === "swatch";
}

const ProductItemHeader: React.FC<HeaderProps> = (props) => {
	const [model, color] = fmtProductName(props.product.name);
	return (
		<header className={props.className}>
			<h1 className="ln-title max-lg:text-lg">
				<Localized prefix="product-model" children={model} />
			</h1>
			<div>
				{props.freeDelivery && (
					<h4 className="table rounded-sm bg-[#B7AEAE] p-1 text-xs text-white max-lg:hidden lg:mb-6">
						Complimentary delivery
					</h4>
				)}
				<h2 className="ln-subtitle-sans pb-1 text-sm max-lg:my-1">
					{props.bespoke ? (
						<Localized id="product-customised_label" />
					) : (
						<LocalizedColors colors={color} />
					)}
				</h2>
			</div>
		</header>
	);
};

const ProductItem: React.FC<Props> = ({ product }) => {
	const { l10n } = useLocalization();
	const locale = useSelector((s: RootState) => s.locale);
	const country = locale.country;
	const displayCurrency = locale.currency.toLowerCase() as "gbp";
	const [options, setOptions] = useState<CartItemSnapOption[]>([]);
	const [bespoke, setBespoke] = useState(false);
	const [price, setPrice] = useState(0);
	const skus = useConfig();
	const freeDelivery =
		Boolean(findSku(product.sku, skus.handbags)) ||
		Boolean(findSku(product.sku, skus.luggage));
	const has360 = findSku(product.sku, skus.with360);
	const hasExact360 = skus.with360.includes(product.sku);
	const hasSkuPreview = findSku(product.sku, skus.withPreview);
	const hasOptionPreview = options.some(
		(o) => o.type === "swatch" && o.meta.previewId,
	);
	const skuGroup = fmtSku(product.sku).group;
	const hasPreview = hasSkuPreview && hasOptionPreview;
	const delivery = getDeliveryTimes(skuGroup, country);
	const isVoucher = Boolean(findSku(product.sku, skus.vouchers));
	const isExotic = product.category.includes("exotic");

	const itemsToBeAdded = [{ id: "", sku: product.sku, options }];

	const galleryImages: ProductImage[] = useMemo(() => {
		const i = product.images;
		const videoId = collections.find((c) => c.slug === product.category)
			?.videoId;
		const video = { thumb: videoId ? `${videoId}.video` : undefined };
		const path360 = { thumb: has360 ? `${has360}.360` : undefined };
		const preview = {
			thumb: hasPreview ? `${hasPreview}.preview` : undefined,
		};
		return [
			i.product,
			...i.gallery,
			i.model,
			video,
			path360,
			preview,
		].filter((i): i is ProductImage => Boolean(i?.thumb));
	}, [product, has360, hasPreview]);

	useEffect(() => {
		setOptions([]);
		setPrice(0);
		setBespoke(false);
	}, [product.sku]);

	useEffect(
		() => setPrice(product.price[displayCurrency]),
		[product, displayCurrency],
	);

	useEffect(() => {
		const opts = filterCustomOptions(options);
		const totalAmount = getItemTotal(
			{ price: product.price, options: opts },
			defaultRoundFactor,
		);
		setBespoke(opts.length > 0);
		setPrice(totalAmount[displayCurrency]);
	}, [options]);

	const onSelectorChange = useCallback((option: CartItemSnapOption) => {
		/**
		 * delay parallel calls from many selectors in order to correctly
		 * populate pre opts value this shouldn't be necessary and
		 * needs to be refactored
		 */
		setTimeout(() =>
			setOptions((opts) => {
				const prev = opts.find((o) => {
					if (isSwatchOption(o) && isSwatchOption(option)) {
						return o.meta.label === option.meta.label;
					}
					return option.type === o.type;
				});
				// ADD OPTIONS
				if (prev === undefined) {
					return [...opts, option];
				}
				// REPLACE EXISTING
				const newOptions = [...opts];
				newOptions.splice(opts.indexOf(prev), 1, option);
				return newOptions;

				// REMOVE
				// @return opts.filter(o => !Object.is(o,prev));
			}),
		);
	}, []);

	return (
		<article className="container my-10 flex w-full flex-col justify-between md:my-20">
			<ProductItemHeader
				product={product}
				bespoke={bespoke}
				freeDelivery={freeDelivery}
				className="mb-8 grid grid-cols-2 items-end border-b max-lg:hidden"
			/>
			<div className="grid-cols-2 gap-10 lg:grid">
				<div className="relative mb-5">
					{galleryImages.length && (
						<Gallery
							sku={product.sku}
							images={galleryImages}
							options={options}
							defaultImage={
								hasExact360
									? galleryImages.find((p) =>
											p.thumb.endsWith(".360"),
									  )
									: undefined
							}
						/>
					)}
					<BookmarkButton
						className={cx(
							"absolute right-2 top-2 z-10 lg:hidden",
							//hasSwatchUpdate && "hidden"
						)}
						options={options}
						product={product}
					/>
				</div>
				<div>
					<div className="max-lg:my-10 max-lg:flex max-lg:flex-col max-lg:items-center">
						<ProductItemHeader
							product={product}
							bespoke={bespoke}
							className="text-center lg:hidden"
							freeDelivery={freeDelivery}
						/>
						<Price
							className="text-lg tracking-wide"
							value={price}
						/>
					</div>
					<ProductItemSelectors
						product={product}
						onChange={onSelectorChange}
					/>
					<div
						className={cx(
							"max-lg:sticky max-lg:p-4",
							"max-lg:mt-8 max-lg:border-t",
							"bottom-0 left-0 z-40 w-full bg-white",
							"flex flex-col lg:my-10",
						)}
					>
						<div className="flex items-center justify-between gap-4">
							<AddToCartButton
								className="mt-0 w-auto lg:ml-0"
								items={itemsToBeAdded}
								product={product}
							/>
							<BookmarkButton
								className={cx(
									//hasSwatchUpdate && "hidden",
									"max-lg:hidden",
								)}
								options={options}
								product={product}
								label="Wishlist"
							/>
						</div>
					</div>
					<Tabs
						labels={[
							l10n.getString("product-tabs-description-title"),
							product.features && "Features",
							product.size && "Size",
							isExotic && l10n.getString("product-tabs-exotic"),
							l10n.getString("product-tabs-delivery-title"),
							l10n.getString("product-tabs-share-title"),
						]}
					>
						<div className="[&_a]:text-accent [&_a]:underline">
							<Localized
								html
								prefix="product-description"
								children={product.description}
							/>
						</div>
						{product.features && (
							<Localized
								html
								prefix="product-features"
								children={product.features}
							/>
						)}
						{product.size && (
							<Localized html children={product.size} />
						)}
						{isExotic && <Localized id="product-exotic" />}
						<Localized
							html
							id="product-delivery"
							elems={{
								a: (
									<a
										className="underline underline-offset-2"
										target="_blank"
										href="https://launer.com/delivery-information"
									/>
								),
								b: <strong className="whitespace-nowrap" />,
								br: <div className="mb-4" />,
							}}
							vars={{
								voucher: isVoucher.toString(),
								country: l10n.getString(`country-${country}`, {
									the: "the",
								}),
								customs:
									country === "GB"
										? ""
										: " " +
										  l10n.getString(
												"product-delivery-customs",
										  ),
								production: delivery?.production || "",
								shipping: delivery?.shipping || "",
							}}
						/>
						<Share
							title={l10n.getString("product-tabs-share-text")}
							img={galleryImages[0]?.large}
						/>
					</Tabs>
				</div>
			</div>
		</article>
	);
};

export default ProductItem;
