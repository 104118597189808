import { Localized } from "@launerlondon/l10n";
import { AnimatePresence } from "framer-motion";
import Modal from "./Modal";
import SignInForm from "./SignInForm";

type Props = {
	show: boolean;
	checkoutMode?: boolean;
	onSuccess: () => void;
	onCancel: () => void;
};

const backgroundImage = new URL("../assets/bg-login.jpg", import.meta.url);

const ModalSignIn: React.FC<Props> = (props) => {
	return (
		<AnimatePresence>
			{props.show && (
				<Modal
					className="md:max-w-2xl md:flex-row md:p-0"
					onCancel={() => props.onCancel()}
				>
					<div
						className="hidden h-full w-full bg-cover md:block"
						style={{
							backgroundImage: `url(${backgroundImage})`,
						}}
					/>
					<div className="mt-6 w-full md:p-4">
						<h2 className="font-display text-sm uppercase tracking-widest text-black">
							<Localized
								id={
									props.checkoutMode
										? "sign-in-checkout-title"
										: "sign-in-title"
								}
							/>
						</h2>
						{props.checkoutMode && (
							<p className="mt-4 text-sm">
								<Localized id="sign-in-checkout-text" />
							</p>
						)}
						<SignInForm
							className="my-8 w-full"
							onSuccess={() => {
								props.onSuccess();
							}}
						/>
						{props.checkoutMode ? (
							<div className="my-8">
								<h4 className="ln-subtitle-sans mb-4 text-sm">
									<Localized id="sign-in-checkout-or" />
								</h4>
								<button
									className="text-sm underline"
									onClick={props.onCancel}
								>
									<Localized id="sign-in-checkout-guest" />
								</button>
							</div>
						) : (
							<div className="p-4 text-left text-sm">
								<h3 className="mb-2 uppercase tracking-wide">
									<Localized id="sign-in-benefits-title" />
								</h3>
								<ul className="list-inside list-disc space-y-1 text-xs tracking-wide">
									<Localized
										html
										id="sign-in-benefits-points"
									/>
								</ul>
							</div>
						)}
					</div>
				</Modal>
			)}
		</AnimatePresence>
	);
};

export default ModalSignIn;
