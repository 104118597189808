import { routes } from "@launerlondon/shop-router";
import { useEffect, useRef, useState } from "react";
import { useFetcher } from "react-router-dom";
import PinEntry from "./PinEntry";
import cx from "classnames";
import { Localized } from "@launerlondon/l10n";

type Props = {
	onSuccess?: () => void;
	className?: string;
};

const SignInForm: React.FC<Props> = (props) => {
	const refPinEntry = useRef<PinEntry>(null);
	const fetcher = useFetcher<{ email?: string; loggedIn?: boolean }>();
	const email = fetcher.data?.email;
	const loggedIn = fetcher.data?.loggedIn;
	const [showPin, setShowPin] = useState(false);

	useEffect(() => setShowPin(email !== undefined), [email]);
	useEffect(() => {
		loggedIn && props.onSuccess && props.onSuccess();
	}, [loggedIn]);

	if (loggedIn) {
		return null;
	}

	return (
		<div
			className={cx(
				"flex items-center justify-center font-normal",
				props.className,
			)}
		>
			{showPin && (
				<div className="flex flex-col items-center gap-4 text-center">
					<p className="text-sm">
						<Localized id="sign-in-pin-title" />
					</p>
					<PinEntry
						ref={refPinEntry}
						length={4}
						onComplete={(token) =>
							fetcher.submit(
								{ email: email!, token },
								{ method: "put", action: routes.signIn },
							)
						}
						focus
						inputStyle={{
							border: "none",
							backgroundColor: "#EFEFEF",
							borderRadius: 4,
						}}
					/>
					<footer className="mt-4 flex gap-2 text-xs">
						<button
							className="text-accent-500 underline"
							onClick={() => {
								fetcher.submit(
									{ email: email! },
									{ method: "post", action: routes.signIn },
								);
								refPinEntry.current?.clear();
							}}
						>
							<Localized id="sign-in-pin-resend" />
						</button>
						<span>or</span>
						<button
							className="text-accent-500 underline"
							onClick={() => {
								setShowPin(false);
								fetcher.submit(null, { action: routes.signIn });
							}}
						>
							<Localized id="sign-in-pin-change-email" />
						</button>
					</footer>
				</div>
			)}

			{!showPin && (
				<fetcher.Form
					action={routes.signIn}
					method="post"
					className="mx-auto flex w-full max-w-sm flex-col items-center space-y-4"
				>
					<input
						name="email"
						type="email"
						className="focus:border-accent-500 w-full p-2 text-center focus:ring-0"
						placeholder="email@domain.com"
						required
					/>
					<button className="button max-w-full text-xs">
						<Localized id="sign-in-button" />
					</button>
				</fetcher.Form>
			)}
		</div>
	);
};

export default SignInForm;
