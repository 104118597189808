import { useLayoutEffect } from "react";
import { useNavigationType, useLocation } from "react-router-dom";

const ScrollToTop: React.FC = () => {
	const action = useNavigationType();
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		if (action === "PUSH") {
			setTimeout(() => scrollTo({ top: 0, behavior: "smooth" }), 100);
		}
	}, [pathname, action]);

	return null;
};

export default ScrollToTop;
