import { getTotalVouchers } from "./voucher";
import countries from "../../assets/countries.json";
import currencies from "../../assets/currencies.json";
import postcodes from "../../assets/postcodes.json";
import shippingGroups from "../../assets/shipping-rates.json";
import states from "../../assets/states.json";
import "./retailOrder.h";

export const defaultRoundFactor = 0.2;
export const smallRoundFactor = 2;
export const decimalRoundFactor = 100;

function isDigitalGood(sku: string) {
	return sku.startsWith("0001");
}

export const taxRates: Record<string, number> = {
	AE: 5,
	AT: 20,
	AU: 10,
	BE: 21,
	BG: 20,
	CA: 0,
	CH: 7.7,
	CN: 21,
	CY: 19,
	CZ: 21,
	DE: 19,
	DK: 25,
	EE: 20,
	ES: 21,
	FI: 24,
	FR: 20,
	GB: 20,
	GR: 24,
	HK: 0,
	HR: 25,
	HU: 27,
	IE: 23,
	IS: 24,
	IT: 22,
	JP: 10,
	LI: 7.7,
	LT: 21,
	LU: 17,
	LV: 21,
	MO: 0,
	MT: 18,
	MY: 10,
	NL: 21,
	NO: 25,
	NZ: 15,
	PL: 23,
	PT: 23,
	QA: 5,
	RO: 19,
	SA: 15,
	SE: 25,
	SG: 9,
	SI: 22,
	SK: 20,
	TW: 5,
	US: 0,
};

export const taxRatesCA: Record<string, number> = {
	AB: 5,
	BC: 12,
	MB: 12,
	NB: 15,
	NL: 15,
	NT: 5,
	NS: 15,
	NU: 5,
	ON: 13,
	PE: 15,
	QC: 14.98,
	SK: 11,
	YT: 5,
};

export const taxRatesUS: Record<string, number> = {
	AL: 9.22,
	AK: 1.76,
	AS: 0,
	AZ: 8.4,
	AR: 9.51,
	CA: 8.68,
	CO: 7.72,
	CT: 6.35,
	DE: 0,
	DC: 6,
	FM: 5,
	FL: 7.08,
	GA: 7.32,
	GU: 2,
	HI: 4.44,
	ID: 6.03,
	IL: 8.82,
	IN: 7.0,
	IA: 6.94,
	KS: 8.69,
	KY: 6.0,
	LA: 9.52,
	ME: 5.5,
	MH: 4,
	MD: 6.0,
	MA: 6.25,
	MI: 6.0,
	MN: 7.46,
	MS: 7.07,
	MO: 8.25,
	MT: 0.0,
	NE: 6.94,
	NV: 8.23,
	NH: 0,
	NJ: 6.6,
	NM: 7.83,
	NY: 8.52,
	NC: 6.98,
	ND: 6.96,
	MP: 0,
	OH: 7.23,
	OK: 8.95,
	OR: 0.0,
	PW: 0,
	PA: 6.34,
	PR: 10.5,
	RI: 7.0,
	SC: 7.46,
	SD: 6.4,
	TN: 9.55,
	TX: 8.19,
	UT: 7.19,
	VT: 6.24,
	VI: 0,
	VA: 5.73,
	WA: 9.23,
	WV: 6.5,
	WI: 5.43,
	WY: 5.33,
};

export function hasIncludedVatPricing(country: string): boolean {
	return [
		"AT",
		"AU",
		"BE",
		"BG",
		"CH",
		"CY",
		"CZ",
		"DE",
		"DK",
		"EE",
		"ES",
		"FI",
		"FR",
		"GR",
		"HR",
		"HU",
		"IE",
		"IS",
		"IT",
		"LI",
		"LT",
		"LU",
		"LV",
		"MT",
		"NL",
		"NO",
		"PL",
		"PT",
		"RO",
		"SE",
		"SI",
		"SK",
	].includes(country);
}

export const needsTaxAdded: hasCountryCustomTax = (country) =>
	["CA", "CN", "US"].includes(country);

export const convertAmount: convertAmount = (sampleAmount, value, currency) => {
	const basePrice = sampleAmount[currency] || sampleAmount.gbp;
	const amountEntries = Object.entries(sampleAmount) as [Currency, number][];
	return amountEntries.reduce<Amount>((p, [k, v]) => {
		p[k] = basePrice ? (v / basePrice) * value : value;
		return p;
	}, {} as Amount);
};

export const mergeAmounts: mergeAmounts = (amounts) => {
	return amounts.reduce<Amount>((p, c) => {
		Object.entries(c).forEach((kv) => {
			const [k, v] = kv as [Currency, number];
			p[k] = roundValue((p[k] || 0) + v, decimalRoundFactor);
		});
		return p;
	}, {} as Amount);
};

export const setAmountValues: setAmountValues = (amount, fmtFn) => {
	const entries = Object.entries(amount) as [Currency, number][];
	return entries.reduce<Amount>((p, [k, v]) => {
		p[k] = fmtFn(v, k);
		return p;
	}, {} as Amount);
};

export const subtractAmounts = (a: Amount, b: Amount) => {
	return setAmountValues(a, (v, k) => v - (b[k] || 0));
};

export const roundDecimals = (value: number) => {
	// https://stackoverflow.com/a/11832950
	return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const roundValue = (value: number, roundFactor: number) => {
	if (!value) return 0;
	const v = Math.round(value * roundFactor) / roundFactor;
	return v || value;
};

export const roundAmountValues: roundAmountValues = (amount, roundFactor) => {
	return setAmountValues(amount, (v) => roundValue(v, roundFactor));
};

export const getItemOptionTotal: getItemOptionTotal = (
	{ multiplier },
	itemPrice,
	roundFactor,
) => {
	const prices = setAmountValues(itemPrice, (v) => v * multiplier);
	return roundAmountValues(prices, roundFactor);
	//return setAmountValues( prices, (v) => Math.floor((Math.ceil(Math.trunc(v) * 0.5) / 0.5) * 0.2) / 0.2);
};

export const getItemPrice: getItemPrice = ({ price }, roundFactor) => {
	return roundAmountValues(price, roundFactor);
};

export const getItemTotal: getItemTotal = ({ price, options }, roundFactor) => {
	let fmtPrice = price;
	if (options) {
		const optionAmounts = options.map<Amount>((o) =>
			getItemOptionTotal(o, price, roundFactor),
		);
		fmtPrice = mergeAmounts([price, ...optionAmounts]);
	}
	return roundAmountValues(fmtPrice, roundFactor);
};

export const getOrderShippingTotal: getOrderShippingTotal = (
	order,
	roundFactor = smallRoundFactor,
) => {
	if (order.total) return order.total.shipping;

	const { country } = order.customer.address;
	const currency = currencies.find((c) => c.country === country)?.currency;
	// exclude digital goods from shipping
	const skuGroups = order.items
		.filter((i) => !isDigitalGood(i.sku))
		.map(({ sku }) => sku.split("-")[0] || "0000");

	const shippingKey =
		currency === "gbp" ||
		currency === "eur" ||
		currency === "usd" ||
		currency === "cny"
			? currency
			: "default";

	const shippingTotal = skuGroups.reduce((p, c) => {
		const rate = (shippingGroups.find((g) => g.skus.includes(c)) ||
			shippingGroups.find((g) => g.skus.includes("*")))?.[shippingKey];
		return p + (rate || 0);
	}, 0);

	const shippingCaps = shippingGroups.map((r) => r[shippingKey]);

	// Shipping rates are always capped  to the lowest group
	let shippingCapPrice = shippingCaps.reduce(
		(p, c) => (p ? p : shippingTotal >= c ? c : 0),
		0,
	);
	const capLevel = shippingGroups
		.filter((g) => g[shippingKey] === shippingCapPrice)
		.pop()?.level;

	// add free shipping for group 3 in all currencies
	if (capLevel === 3) {
		shippingCapPrice = 0;
	}

	const shippingPriceBase =
		shippingKey == "default" ? "gbp" : (currency as Currency);
	const fmtAmount = convertAmount(
		order.items[0].price,
		shippingCapPrice,
		shippingPriceBase,
	);
	// keep base shipping intact following gbp price
	if (fmtAmount.base && fmtAmount.gbp) {
		fmtAmount.base = fmtAmount.gbp;
	}

	return roundAmountValues(fmtAmount, roundFactor);
};

function upgradeLegacyTotal(total: Total) {
	const discount = total.discount || setAmountValues(total.gross, () => 0);
	const paid = total.paid || subtractAmounts(total.gross, discount);
	return { ...total, discount, paid };
}

export const getOrderTotal: getOrderTotal = (order, roundFactor) => {
	if (order.total) {
		return upgradeLegacyTotal(order.total);
	}

	const { country, state } = order.customer.address;

	const shipping = getOrderShippingTotal(order, smallRoundFactor);
	const subtotal = mergeAmounts(
		order.items.map((i) => getItemTotal(i, roundFactor)),
	);

	// skip tax for digital goods
	const taxableSubtotal = mergeAmounts(
		order.items.map((i) => {
			// TODO dynamically assert with findSku(sku, skus.vouchers)
			const item = isDigitalGood(i.sku)
				? { ...i, price: setAmountValues(i.price, () => 0) }
				: i;
			return getItemTotal(item, roundFactor);
		}),
	);

	const tax = getTotalTax({ subtotal: taxableSubtotal }, country, state);
	const net = getTotalNet({ subtotal: taxableSubtotal }, country, state);

	const gross = needsTaxAdded(country)
		? mergeAmounts([subtotal, shipping, tax])
		: mergeAmounts([subtotal, shipping]);

	const discount = getTotalVouchers(order.vouchers, gross);
	const paid = subtractAmounts(gross, discount);

	const total = { subtotal, shipping, tax, net, gross, discount, paid };
	return total;
};

export const getTotalNet: getTotalNet = (
	{ subtotal, net },
	country = "GB",
	state,
) => {
	if (net) {
		return net;
	}
	if (needsTaxAdded(country)) {
		return { ...subtotal };
	}
	const tax = getTotalTax({ subtotal }, country, state);
	return setAmountValues(subtotal, (v, k) => v - (tax[k] || 0));
};

type getTaxRateArgs = {
	country: string;
	state?: string;
	subtotal?: Amount;
};
export const getTaxRate = ({
	country = "GB",
	state = "DE",
	subtotal,
}: getTaxRateArgs): number => {
	if (country === "US") {
		return taxRatesUS[state] || 0;
	}
	if (country === "CA") {
		return taxRatesCA[state] || 0;
	}
	if (country === "AU" && (subtotal?.aud || 0) < 1000) {
		return 0;
	}
	if (taxRates[country] === undefined) {
		return taxRates.GB || 0;
	}
	return taxRates[country] || 0;
};

export const calculateTaxRate = (
	total: Pick<Total, "subtotal" | "tax">,
	country = "GB",
): number => {
	const currency = Object.keys(total.subtotal)[0] as "gbp";
	const subtotal = total.subtotal[currency] || 0;
	const tax = total.tax[currency] || 0;
	let taxRate = tax / subtotal;
	const taxIncluded = !needsTaxAdded(country);

	if (taxIncluded) {
		taxRate = tax / (subtotal - tax);
	}
	return parseFloat((taxRate * 100).toFixed(2));
};

export const getTotalTax: getTotalTax = (
	{ subtotal, tax },
	country = "GB",
	state = "DE",
) => {
	if (tax) {
		return tax;
	}
	const taxRate = getTaxRate({ country, state, subtotal }) / 100;
	const taxIncluded = !needsTaxAdded(country);
	const taxValue = setAmountValues(subtotal, (v) => {
		if (taxIncluded) {
			return roundDecimals(v - v / (taxRate + 1));
		}
		return roundDecimals(v * taxRate);
	});
	return taxValue;
};

export const getOrderNotes: getOrderNotes = (order) => {
	if (!order.events) {
		return {};
	}
	return {
		customer: order.events[0].message,
		factory: order.events.find((e) => e.type === "approved")?.message,
	};
};

export const getOrderLastEvent: getOrderLastEvent = (order, type) => {
	const events = type
		? order.events.filter((e) => e.type === type)
		: order.events;
	return events.slice(-1).pop() as ReturnType<getOrderLastEvent>;
};

export const pushOrderEvent: pushOrderEvent = (order, event) => {
	// @ts-expect-error: Allow internal implementation to override status;
	order.status = event.type;
	const fmtEvent: Event = { ...event, date: event.date || new Date() };
	order.events.push(fmtEvent);
	return fmtEvent;
};

export const hasVendorPayment: hasVendorPayment = (order) => {
	return ["apple", "google", "microsoft"].includes(order.payment.type);
};

export function shouldShowTax(order: Pick<RetailOrderBase, "customer">) {
	const { country, state } = order.customer.address;
	return ["CA", "US"].includes(country)
		? state !== undefined
		: country !== "GB" && Object.keys(taxRates).includes(country);
}

export function validateOrder(order: Pick<RetailOrderBase, "customer">) {
	const { customer } = order;
	const codes = {
		1010: "missing-customer-name",
		1020: "missing-customer-email",
		1021: "invalid-customer-email",
		1030: "missing-customer-tel",
		1040: "missing-customer-address",
		1041: "missing-customer-city",
		1042: "missing-customer-country",
		1043: "invalid-customer-country",
		1044: "missing-customer-postcode",
		1045: "missing-customer-state",
		1046: "invalid-customer-state",
	};
	function err(code: keyof typeof codes) {
		const data = codes[code as keyof typeof codes];
		const e = new Error(data) as Error & { code: number };
		e.code = code;
		return e;
	}
	if (!customer.name) {
		throw err(1010);
	}
	if (!customer.email) {
		throw err(1020);
	}
	const email =
		/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
	if (!email.test(customer.email)) {
		throw err(1021);
	}
	if (!customer.phone) {
		throw err(1030);
	}
	if (!customer.address || !customer.address.line1) {
		throw err(1040);
	}
	if (!customer.address.city) {
		throw err(1041);
	}
	if (!customer.address.country) {
		throw err(1042);
	}

	const countryCodes = countries.map((c) => c.value);
	if (!countryCodes.includes(customer.address.country)) {
		throw err(1043);
	}

	if (
		!customer.address.postal_code &&
		!postcodes.disabled.includes(customer.address.country)
	) {
		throw err(1044);
	}

	const countryStates = states
		.find((s) => s.country === customer.address.country)
		?.states.map((s) => s.value);
	if (countryStates) {
		if (!customer.address.state) {
			throw err(1045);
		}
		if (!countryStates.includes(customer.address.state)) {
			throw err(1046);
		}
	}

	return true;
}

export function countryToCurrency(country: string): string | undefined {
	return currencies.find((c) => c.country === country)?.currency;
}
