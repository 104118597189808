import type { MarketingStatus, Meta } from "@launerlondon/shop-types";
import axios from "axios";
import type { Action, ActionCreator, Reducer } from "redux";
import type { RootThunkAction } from "../store";

type MetaAction =
	| (Action<"SET_CUSTOMER_ID"> & { value?: string })
	| (Action<"SET_CART_ID"> & { value?: string })
	| (Action<"SET_MARKETING_STATUS"> & { value: MarketingStatus })
	| (Action<"SET_PENDING_ITEMS"> & { value?: Meta["pendingItems"] });

type MetaReducer = Reducer<Meta, MetaAction>;
type MetaActionCreator = ActionCreator<MetaAction>;

const initialState: Meta = { ma: 0 };

const MetaReducer: MetaReducer = (state = initialState, action): Meta => {
	if (action.type === "SET_CUSTOMER_ID") {
		if (state.customerId === action.value) {
			return state;
		}
		return { ...state, customerId: action.value };
	}
	if (action.type === "SET_CART_ID") {
		if (state.cartId === action.value) {
			return state;
		}
		return { ...state, cartId: action.value };
	}
	if (action.type === "SET_MARKETING_STATUS") {
		if (state.ma === action.value) {
			return state;
		}
		return { ...state, ma: action.value };
	}
	if (action.type === "SET_PENDING_ITEMS") {
		return { ...state, pendingItems: action.value };
	}
	return state;
};

const setCustomerId: MetaActionCreator = (value) => ({
	type: "SET_CUSTOMER_ID",
	value,
});
const setCartId: MetaActionCreator = (value) => ({
	type: "SET_CART_ID",
	value,
});
const setMarketingStatus: MetaActionCreator = (value) => ({
	type: "SET_MARKETING_STATUS",
	value,
});
const setPendingItems: MetaActionCreator = (value) => ({
	type: "SET_PENDING_ITEMS",
	value,
});

type MetaCreateCustomer = (
	email: string,
	optIn: boolean,
) => RootThunkAction<MetaAction, Promise<void>>;
const createCustomer: MetaCreateCustomer =
	(email, optIn) => async (dispatch, getState) => {
		const { locale } = getState();
		const r = await axios
			.post("/api/mcec?action=createCustomer", {
				email,
				language: locale.lang,
				countryCode: locale.country,
				optIn,
			})
			.catch((err) => {
				alert(err.message);
				throw err;
			});
		dispatch(setCustomerId(r.data.customerId));
	};

export default MetaReducer;
export {
	setCustomerId,
	setCartId,
	setMarketingStatus,
	setPendingItems,
	createCustomer,
};
