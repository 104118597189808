import { NavLink } from "react-router-dom";
import cx from "classnames";
import { useLocalization } from "@launerlondon/l10n";
type Props = {
	title?: string | false;
	className?: string;
	pages: Array<{
		title?: string;
		link: string;
		image: string;
	}>;
};

const RelatedPages: React.FC<Props> = (props) => {
	const { l10n } = useLocalization();
	const title =
		props.title === undefined
			? l10n.getString("product-recommend-title")
			: props.title;
	return (
		<aside className="mt-20 text-center">
			{title && (
				<h3 className="ln-subtitle my-10 border-t pt-10 text-gray-600">
					{title}
				</h3>
			)}
			<div className={cx("grid gap-[1px] md:flex", props.className)}>
				{props.pages.map((p) => (
					<NavLink
						key={p.title}
						to={p.link}
						style={{
							backgroundImage: `url(${p.image}`,
						}}
						className={cx(
							"flex flex-1 items-center justify-center bg-cover bg-center text-white",
							props.pages.length > 2 ? "pb-[35%]" : "pb-[50%]",
						)}
					>
						<span className="hidden">{p.title}</span>
					</NavLink>
				))}
			</div>
		</aside>
	);
};

export default RelatedPages;
