import { MessageForm } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-careers.jpg";

const Careers: React.FC = () => {
	useMetaTags({
		title: "Careers",
		description:
			"We are passionate about training and employing staff across all spectrums of the community and promote equality and diversity.",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container pb-20">
				<header className="ln-intro-grid">
					<h2 className="font-display">Careers</h2>
					<div>
						<p>
							We are passionate about training and employing staff
							across all spectrums of the community and promote
							equality and diversity. It can take up to two years
							to fully train a member of staff to be fully
							immersed in the craft of Launer make and production.
						</p>
					</div>
				</header>
				<div className="ln-section-grid gap-10">
					<div>
						<p>
							It is with pride and acknowledgement that we have
							employed many craftspeople for decades, nurturing
							and encourage the very young and those that wish
							retrain in later life.
						</p>
						<p>
							Each and every employee at Launer is integral to the
							success of the business and its continued pursuit of
							the highest standards.
						</p>
						<p>
							If you are interested in working with us at Launer,
							please get in touch via the form below clearly
							outlining your reasons for wanting to work with us
							and any relevant experience. Though we are unable to
							respond to all applications, we will contact all
							successful applicants when a new position becomes
							available.
						</p>
						<MessageForm
							action="careers"
							fields={[
								{ title: "Name", required: true },
								{
									title: "Email",
									type: "email",
									required: true,
								},
								{ title: "Telephone number", type: "tel" },
								{ title: "Subject" },
								{
									title: "Message",
									required: true,
									type: "textarea",
								},
							]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Careers;
