import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export default function CollectionHatherton() {
	useMetaTags({
		title: "The Hatherton Colllection | Luxury Luggage",
		description:
			"Discover The Hatherton Collection for luxury leather luggage handmade in Britian. Made to order & customisable. Complimentary worldwide shipping on all bags.",
		image: "/images/collections/hatherton-banner.jpg",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				image="/images/collections/hatherton-banner.jpg"
				className="bg-black text-sm text-[#E5E5E5] lg:text-right"
				contentClassName="space-y-10 place-self-end mb-4"
			>
				<h1 className="ln-title flex flex-col">
					<span className="text-sm">The</span>{" "}
					<span className="text-4xl">Hatherton </span>{" "}
					<span className="text-sm">Collection</span>
				</h1>
				<p className="max-w-[320px] max-lg:mx-auto">
					Embark upon a journey of unparalleled luxury with the launch
					of our new Hatherton Collection.
				</p>
			</Row.WithImage>
			<Row.Single>
				<p>
					A range of refined luggage pieces that will elevate the
					discerning person’s voyage. This immaculate travel inspired
					range has been eighteen months in development and is now
					ready to venture on its maiden voyage. Starting with 15
					exemplary pieces for the modern globe-trotter, where style
					and function seamlessly unify, proudly bearing the lauded
					hallmarks the House of Launer is synonymous with.
				</p>
			</Row.Single>
			<Row.WithImage
				reverse
				image="/images/collections/hatherton-cabin-bags.jpg"
				className="bg-[#1F2338] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">HATHERTON CABIN BAGS</h2>
					<p className="text-sm">
						Caviar Black / Havana Brown / Windsor Tan / Oxford Blue
					</p>
				</header>
				<p className="my-10">
					Supreme travel bags handcrafted in our British atelier by a
					single skilled artisan. Designed with engineered precision,
					our cabin bags feature the signature Launer hallmarks
					synonymous with our legacy.
				</p>
				<Link
					to="/c/cabin-bags"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["3142-01", "3141-01", "3141-02", "3140-01"]}
			/>
			<Products
				className="container"
				skus={["3140-02", "3140-03", "3140-05", "3140-06"]}
			/>
			<div
				className={twJoin(
					"max-lg:hidden",
					"aspect-square max-h-[60vh] w-full",
					"bg-gray-200 bg-cover bg-center lg:bg-fixed",
					"bg-[url('/images/collections/hatherton-feature-1.jpg')]",
				)}
			/>
			<Row.WithImage
				image="/images/collections/hatherton-history.jpg"
				className="bg-[#492723] text-[#EBE7E7]"
				imageClassName="lg:m-10"
			>
				<p>
					The name derives from Launer’s atelier in Walsall, an
					Edwardian listed building originally known as The Hatherton
					Saddlery Works. Since its beginnings it has existed as a
					centre of excellence for leathergoods including saddlery and
					handbags, and a fitting homage to Launer’s eighty plus
					illustrious years.
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/hatherton-portfolio-cases.jpg"
				className="bg-[#2E2727] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Hatherton Portfolio Case</h2>
					<p className="text-sm">
						Caviar Black / Havana Brown / Windsor Tan / Oxford Blue
					</p>
				</header>
				<p className="my-10">
					The Hatherton Portfolio cases carry your passport and
					essential travel documents in distinguished slimline style.
					The scotch grain ensures a polished performance that endures
					for your on-the-go needs.
				</p>
				<Link
					to="/c/portfolio-cases"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["3009-01", "3009-03", "3009-04", "3009-02"]}
			/>
			<Products
				className="container"
				skus={["3149-01", "3148-01", "3148-02", "3147-01"]}
			/>
			<Row.WithImage
				image="/images/collections/hatherton-retractable-portfolio.jpg"
				className="bg-[#492723] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Hatherton Tablet Folio Case</h2>
					<p className="text-sm">
						Caviar Black / Havana Brown / Windsor Tan / Oxford Blue
					</p>
				</header>
				<p className="my-10">
					The Hatherton Tablet case features Launer's traditional
					leathercraft work. A discreet case, slim in appearance with
					two handles that easily retract if desired. It features an
					elegant circular engraved rear lock fashioned from
					gold-plated brass and key.
				</p>
				<Link
					to="/c/portfolio-cases"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["3145-01", "3145-02", "3146-01", "3146-02"]}
			/>
			<Row.WithImage
				reverse
				image="/images/collections/hatherton-feature-2.jpg"
				className="bg-[#291D11] text-[#EBE7E7]"
			>
				<p>
					The Hatherton Collection of luxury luggage exudes design
					excellence with an unprecedented level of detail and
					engineered precision. Crafted in a new, robust leather,
					scotch grain, combining an elegant appearance with
					reassuring endurance.
				</p>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/hatherton-wash-bags.jpg"
				className="bg-[#1F2338] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Hatherton Wash bag</h2>
					<p className="text-sm">
						Caviar Black / Havana Brown / Windsor Tan / Oxford Blue
					</p>
				</header>
				<p className="my-10">
					The essential toiletry bag is reimagined with a timeless
					elegance. A stylish element of the luggage kit, our
					Hatherton wash bag is handcrafted in our British atelier by
					a single skilled artisan.
				</p>
				<Link
					to="/c/wash-bags"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products className="container" skus={["3136-01", "3136-02"]} />
			<div
				className={twJoin(
					"max-lg:hidden",
					"aspect-square max-h-[60vh] w-full",
					"bg-gray-200 bg-cover bg-center",
					"bg-[url('/images/collections/hatherton-feature-3.jpg')]",
				)}
			/>
			<Row.WithImage
				image="/images/collections/hatherton-handcraft.jpg"
				className="bg-[#492723] text-[#EBE7E7]"
				imageClassName="lg:m-10"
			>
				<p>
					Travel in luxurious style with the ultimate men’s and
					women’s luggage, portfolio cases, wash bags, wallets, cover
					cases and essential accessories.
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/hatherton-passport-holders.jpg"
				className="bg-[#2E2727] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">HATHERTON Passport Holder</h2>
					<p className="text-sm">Caviar Black / Scarlet</p>
				</header>
				<p className="my-10">
					Protect your passport with our immensely stylish passport
					cover that will endure for years. The Hatherton passport
					cover is ideal for the discerning traveller who requires the
					ultimate in luxury accessories.
				</p>
				<Link
					to="/s/passport-holder"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["486-03", "620-05", "670-07", "670-06"]}
			/>
			<Row.WithImage
				image="/images/collections/hatherton-feature-4.jpg"
				className="bg-[#492723] text-[#EBE7E7]"
			>
				<p>
					Each piece emits an unmitigating sense of style maintained
					via its structure and use of scotch grain and sumptuous
					suede lining. A captivating surface texture that is polished
					yet incredibly resilient and comes in a choice of four first
					class shades: Caviar Black, Oxford Blue, Havana Brown and
					Windsor Tan.
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/hatherton-luggage-accessories.jpg"
				className="bg-[#1F2338] text-[#EBE7E7]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">HATHERTON Luggage tag</h2>
					<p className="text-sm">
						Caviar Black / Havana Brown / Windsor Tan / Oxford Blue
					</p>
				</header>
				<p className="my-10">
					Ensure your valuable luggage is labelled in the most secure
					yet discreet manner with The Hatherton luggage tag. Created
					with the same exquisite hallmarks that Launer is renowned
					for, it has an inner sleeve to store your personal details,
					secured with a gold, silver or stealth finish buckle and
					strap, which attaches to any luggage bag in The Hatherton
					range.
				</p>
				<Link
					to="/c/luggage-accessories"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-white"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["281-01", "281-02", "282-01", "282-02"]}
			/>
			<Row.WithImage
				image="/images/collections/hatherton-keylock.jpg"
				className="bg-[#F6F6F6]"
				imageClassName="lg:m-10"
			>
				<p>
					Adventure into customisation and make Hatherton truly
					personal with an unprecedented number of considered options
					including fittings in signature gold, silver, and stealth,
					Faraday® shielding and hand embossed lettering. 
				</p>
			</Row.WithImage>
			<div className="container py-10">
				<h3 className="ln-subtitle text-xs">
					Exuding style and longevity, Hatherton is a consummate
					destination for luxury explorers.
				</h3>
			</div>
		</div>
	);
}
