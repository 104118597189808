import { CartItemSnap } from "@launerlondon/products";
import { routes } from "@launerlondon/shop-router";
import { Buffer } from "buffer";
import { generatePath } from "react-router-dom";

enum CartItemOptionType {
	swatch = 1,
	wallet_size = 2,
	phone_size = 3,
	tablet_size = 4,
	lettering = 5,
	gold_corners = 6,
	shielding = 7,
	lining = 9,
	foiling_passport = 10,
	strap_fitting = 11,
	emblem = 12,
	strap_style = 13,
	voucher_credit = 14,
	fittings = 15,
}
function getItemType(t: string) {
	return CartItemOptionType[t as "swatch"] || 0;
}

export function sortCartItemOptions(item: CartItemSnap) {
	item.options.sort((a, b) => getItemType(a.type) - getItemType(b.type));
	return item;
}

export async function decodeCartShare(hash: string): Promise<CartItemSnap[]> {
	const buf = Buffer.from(hash, "base64");
	const { inflate } = await import("pako");
	const data = Buffer.from(inflate(buf)).toString().trim();
	const lines = data.split("\n");
	return lines
		.map((l) => {
			const [id, sku, ...opts] = l.split(",");
			if (!id || !sku) return;
			const item: CartItemSnap = { id, sku, options: [] };
			while (opts.length) {
				const [typeV, value, multiplierV, defaultV, label, id] =
					opts.splice(0, 6);
				const type = parseInt(typeV || "0") || 0;
				const multiplier = parseFloat(multiplierV || "0");
				let typeLabel: keyof typeof CartItemOptionType;

				switch (type) {
					case CartItemOptionType.shielding:
						item.options.push({
							type: "shielding",
							value: value ? true : undefined,
							multiplier,
						});
						break;
					case CartItemOptionType.gold_corners:
						item.options.push({
							type: "gold_corners",
							value: parseInt(value || "0"),
							multiplier,
							default: parseInt(value || "0") === 0,
							meta: {
								id: id || "",
								label: label || "",
								thumb: "",
							},
						});
						break;
					case CartItemOptionType.wallet_size:
						typeLabel ??= "wallet_size";
					case CartItemOptionType.phone_size:
						typeLabel ??= "phone_size";
					case CartItemOptionType.tablet_size:
						typeLabel ??= "tablet_size";
						item.options.push({
							default: Boolean(defaultV),
							type: typeLabel as "wallet_size",
							value,
							multiplier,
						});
						break;
					case CartItemOptionType.lettering:
						item.options.push({
							type: "lettering",
							value,
							multiplier,
						});
						break;
					case CartItemOptionType.swatch:
						item.options.push({
							type: "swatch",
							default: Boolean(defaultV),
							value,
							multiplier,
							meta: {
								id: id || "",
								label: label || "",
								thumb: "",
							},
						});
						break;
				}
			}
			return item;
		})
		.filter((item): item is CartItemSnap => item !== undefined);
}

export async function encodeCartShare(items: CartItemSnap[]) {
	const data = items
		.reduce<string[]>((p, c) => {
			const fields = [
				c.id,
				c.sku,
				...c.options
					.filter((o) => o.value !== undefined)
					.flatMap<any[]>((o) => {
						if (!getItemType(o.type)) {
							return [];
						}
						// type, value, multiplier, default, label
						const fields = [
							getItemType(o.type),
							o.value,
							o.multiplier || undefined,
							"default" in o && o.default ? 1 : undefined,
							o.type === "swatch" ? o.meta.label : undefined,
							o.type === "swatch" || o.type === "strap_fitting"
								? o.meta.id
								: undefined,
						];
						return fields;
					}),
			];
			p.push(fields.join(","));
			return p;
		}, [])
		.join("\n");

	const { deflate } = await import("pako");
	return Buffer.from(deflate(data)).toString("base64");
}

export async function getCartUrl(items: CartItemSnap[]) {
	const hash = await encodeCartShare(items);
	const path = generatePath(routes.checkout, { "*": hash });
	return `${location.origin}${path}`;
}
