import { getCurrentHub, init } from "@sentry/react";
import { extractTraceparentData, Integrations } from "@sentry/tracing";

const version = String(import.meta.env.VITE_PKG_VERSION);
const environment = String(import.meta.env.MODE);
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

export const install = () => {
	if (!SENTRY_DSN) {
		console.error("missing sentry dsn");
		return;
	}
	const client = getCurrentHub().getClient();
	if (client) {
		client.getOptions().enabled = true;
		return;
	}

	const traceparent =
		document.head.querySelector<HTMLMetaElement>(
			'meta[name="sentry-trace"]',
		)?.content || "";

	init({
		dsn: SENTRY_DSN,
		release: `shop@${version}`,
		environment,
		integrations: [
			new Integrations.BrowserTracing({
				tracingOrigins: [location.origin, /.*.cloudfunctions.net/],
				idleTimeout: 30e3,
				beforeNavigate: (ctx) => ({
					...ctx,
					...extractTraceparentData(traceparent),
					name: location.pathname,
				}),
			}),
		],
		tracesSampleRate: 0.1,
	});
};

export const uninstall = () => {
	const client = getCurrentHub().getClient();
	if (!client) return;
	client.getOptions().enabled = false;
};
