import { Collection, getCollection } from "@launerlondon/shop-collections";
import { useEffect, useState } from "react";

export function useCollection(slug: string) {
	const [collection, setCollection] = useState<
		Collection | undefined | null
	>();

	useEffect(() => {
		getCollection(slug).then((p) => setCollection(p || null));
		return () => setCollection(undefined);
	}, [slug]);

	return collection;
}
