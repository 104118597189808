import cx from "classnames";
import type { Field } from ".";

type SelectOption = { value: string; title: string; disabled?: true };

type Select = Field<
	React.SelectHTMLAttributes<HTMLSelectElement> & { options?: SelectOption[] }
>;

const Select: Select = ({ onChange, options = [], className, ...props }) => {
	return (
		<label className={cx("ln-field", className)}>
			<span>{props.title}</span>
			<select
				{...props}
				onChange={(e) => onChange?.call(undefined, e.target.value)}
			>
				{options.map((o, i) => (
					<option key={i} value={o.value} disabled={o.disabled}>
						{o.title}
					</option>
				))}
			</select>
		</label>
	);
};

export default Select;
