import { Amount } from "@launerlondon/shared";
import { RootState } from "@launerlondon/shop-types";
import { currencyToLang } from "@launerlondon/shop-utils";
import { useSelector } from "react-redux";

type Props = {
	className?: string;
	value?: number | Amount;
	showZero?: true;
	currency?: string;
};

const Price: React.FC<Props> = ({ value, showZero, currency, ...props }) => {
	const ccy =
		currency || useSelector((state: RootState) => state.locale.currency);
	if (value === undefined) {
		return null;
	}
	const v =
		typeof value === "number"
			? value
			: value[ccy.toLowerCase() as keyof Amount] || 0;
	const lang = currencyToLang(ccy);
	const price = v.toLocaleString(lang, {
		style: "currency",
		currency: ccy,
	});
	if (v === 0 && !showZero) return null;
	return <span {...props}>{price}</span>;
};

export default Price;
