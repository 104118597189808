import { Localized, useLocalization } from "@launerlondon/l10n";
import { Modal } from "@launerlondon/shop-components";
import { MarketingStatus, RootState } from "@launerlondon/shop-types";
import cx from "classnames";
import { AnimatePresence } from "framer-motion";
import {
	FormEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import backgroundImage from "../../assets/newsletter-bg.jpg";
import { Input } from "../components";
import { createCustomer, setMarketingStatus } from "../redux/actions";

type Props = ConnectedProps<typeof connector> &
	React.HTMLAttributes<HTMLDivElement> & {
		showUnless: MarketingStatus;
		email?: string;
		delay?: number;
	};

const connector = connect(
	(state: RootState) => ({
		marketingStatus: state.meta.ma,
	}),
	{
		createCustomer,
		setMarketingStatus,
	},
);

const NewsletterSignup: React.FC<Props> = (props) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [registered, setRegistered] = useState(false);
	const { l10n } = useLocalization();

	const emailRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		const $open =
			props.marketingStatus !== MarketingStatus.Allowed &&
			props.marketingStatus < props.showUnless;
		const timeout = setTimeout(() => setOpen($open), props.delay || 0);
		return () => clearTimeout(timeout);
	}, [props.marketingStatus]);

	const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
		async (e) => {
			e.preventDefault();
			const email = emailRef.current?.value;
			if (email) {
				setLoading(true);
				await props.createCustomer(email, true);
				setLoading(false);
				setRegistered(true);
				setTimeout(
					() => props.setMarketingStatus(MarketingStatus.Allowed),
					2000,
				);
			}
		},
		[],
	);

	const onCancel = useCallback(() => {
		setOpen(false);
		props.setMarketingStatus(props.showUnless);
	}, [props.marketingStatus]);

	const shouldOpen =
		open && props.marketingStatus !== MarketingStatus.Allowed;

	return (
		<AnimatePresence>
			{shouldOpen && (
				<Modal
					onCancel={onCancel}
					className={cx(
						"md:max-w-xl md:flex-row md:p-0",
						props.className,
					)}
				>
					<div
						className="hidden h-full w-full bg-cover md:block"
						style={{
							backgroundImage: `url(${backgroundImage})`,
						}}
					/>
					<div className="w-full md:p-4">
						<h2 className="font-display mt-4 uppercase tracking-widest">
							<Localized id="newsletter-title" />
						</h2>
						<p className="mt-2 text-sm">
							<Localized id="newsletter-subtitle" />
						</p>
						{registered ? (
							<h3 className="text-accent-500 my-10 md:min-h-[189px]">
								<Localized id="newsletter-confirm" />{" "}
							</h3>
						) : (
							<form
								onSubmit={onSubmit}
								className="my-5 space-y-4 md:my-10"
							>
								<Input
									required
									ref={emailRef}
									type="email"
									defaultValue={props.email}
									placeholder={l10n.getString(
										"newsletter-email-placeholder",
									)}
								/>
								<button
									className="button"
									disabled={loading}
									children={l10n.getString(
										"newsletter-button",
									)}
								/>
								<div className="text-xs leading-normal">
									<Localized
										id="newsletter-footer"
										elems={{
											url: (
												<Link
													className="border-b border-gray-400"
													to="/terms-conditions-privacy"
												/>
											),
										}}
										children={<div />}
									/>
								</div>
							</form>
						)}
					</div>
				</Modal>
			)}
		</AnimatePresence>
	);
};

export default connector(NewsletterSignup);
