import { useLocalization } from "@launerlondon/l10n";
import { countries, postcodes, states } from "@launerlondon/shared";
import { AddressData, RootState } from "@launerlondon/shop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FieldChangeProps, Input, Select } from ".";
import { setCountry } from "../../redux/actions";
import { media } from "../../styles";

type AddressProps = FieldChangeProps<AddressData | undefined> & {
	value?: AddressData;
};

const Address: React.FC<AddressProps> = ({ value, onChange }) => {
	const { l10n } = useLocalization();
	const [inValue, setValue] = useState<AddressData>({});
	const country = useSelector((s: RootState) => s.locale.country);
	const dispatch = useDispatch();

	useEffect(() => {
		value && setValue(value);
	}, [value]);

	const onCountryUpdate = useCallback((value: string) => {
		dispatch(setCountry(value));
		setValue((v) => ((v.country = value), v));
	}, []);

	const countryStates = states.find((e) => e.country === inValue.country)
		?.states;

	const showPostcode =
		inValue.country && !postcodes.disabled.includes(inValue.country);

	const link = useCallback(
		<T extends AddressData, K extends keyof AddressData, V = T[K]>(
			key: K,
		) => {
			return (v: V) => {
				setValue((prev) => {
					if (prev?.[key] === v) {
						return prev;
					}
					const next = { ...prev, [key]: v };
					if (v === undefined) delete next[key];
					onChange?.call(undefined, next);
					return next;
				});
			};
		},
		[onChange],
	);

	useEffect(() => {
		setValue((v) => ((v.country = country), v));
	}, [country]);

	useEffect(() => {
		setValue((p) => {
			// reset state if country states changes and it's not valid
			if (
				!countryStates ||
				!countryStates.find((s) => s.value === p.state)
			) {
				delete p.state;
			}
			// reset postcode once country doesn't require it
			if (!showPostcode) {
				delete p.postal_code;
			}
			return p;
		});
	}, [countryStates, showPostcode]);

	return (
		<>
			<Input
				css={{ [media.tablet]: { gridColumn: "auto / span 2" } }}
				title={l10n.getString("checkout-field-address")}
				value={inValue.line1}
				minLength={5}
				required
				onChange={link("line1")}
			/>
			<Input
				css={
					showPostcode
						? {}
						: { [media.tablet]: { gridColumn: "auto / span 2" } }
				}
				title={l10n.getString("checkout-field-city")}
				value={inValue.city}
				minLength={3}
				required
				onChange={link("city")}
			/>
			{showPostcode && (
				<Input
					title={l10n.getString("checkout-field-postcode")}
					value={inValue.postal_code}
					minLength={4}
					maxLength={10}
					required
					onChange={link("postal_code")}
				/>
			)}
			<Select
				css={
					countryStates
						? {}
						: { [media.tablet]: { gridColumn: "auto / span 2" } }
				}
				title={l10n.getString("checkout-field-country")}
				value={inValue.country}
				onChange={onCountryUpdate}
				required
				options={countries}
			/>
			{countryStates && (
				<Select
					title={l10n.getString(
						`checkout-field-state--${inValue.country}`,
						null,
						l10n.getString("checkout-field-state"),
					)}
					value={inValue.state || ""}
					onChange={link("state")}
					required
					options={[
						{ title: "Please select", value: "", disabled: true },
						...countryStates,
					]}
				/>
			)}
		</>
	);
};

export default Address;
