import { combineReducers } from "redux";
import meta from "./MetaReducer";
import locale from "./LocaleReducer";
import cart from "./CartReducer";

const RootReducer = combineReducers({
	meta,
	locale,
	cart,
});

export default RootReducer;
