import { ProductSnap } from "@launerlondon/products";
import { ProductListItem } from "@launerlondon/shop-components";
import { AppLoaderData, routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { Fragment, useEffect, useRef } from "react";
import { useIsVisible } from "react-is-visible";
import { useRouteLoaderData } from "react-router-dom";
import { trackViewItemList } from "../lib/analytics";

type Props = {
	title?: string;
	titleTag?: "h1" | "h2";
	products: ProductSnap[];
	headerClassName?: string;
	divider?: React.ReactElement;
};

const ProductListSection: React.FC<Props> = (props) => {
	const { listViewMode } = useRouteLoaderData(routes.home) as AppLoaderData;
	const ref = useRef<HTMLElement>(null);
	const isVisibile = useIsVisible(ref);
	const TitleTag = props.titleTag || "h2";

	useEffect(
		() => void isVisibile && trackViewItemList(props.products, props.title),
		[isVisibile, props.title, props.products],
	);

	return (
		<section ref={ref} className="container pb-4 text-gray-800">
			{props.title ? (
				<TitleTag
					className={cx(
						TitleTag === "h1" ? "ln-title" : "ln-subtitle",
						"top-0 z-20 lg:sticky",
						"bg-white/95",
						"my-10 py-4 text-center",
						props.headerClassName,
					)}
				>
					{props.title}
				</TitleTag>
			) : (
				<div className="h-10" />
			)}
			<div
				className={cx(
					"grid gap-x-2 gap-y-10 py-2 md:gap-x-4",
					listViewMode === "tight"
						? "grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
						: "grid-cols-1 md:grid-cols-2 xl:grid-cols-3",
				)}
			>
				{props.products.map((p, i, arr) => {
					const divide =
						props.divider &&
						arr.length > 20 &&
						Math.floor(arr.length / 2) === i;
					return (
						<Fragment key={i}>
							{divide && (
								<div
									className={cx(
										listViewMode === "tight"
											? "col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-5"
											: "col-span-1 md:col-span-2 xl:col-span-3",
										"empty:hidden",
									)}
								>
									{props.divider}
								</div>
							)}
							<ProductListItem key={i} product={p} />
						</Fragment>
					);
				})}
			</div>
		</section>
	);
};

export default ProductListSection;
