import { getPost, getPosts, Post } from "@launerlondon/shop-posts";
import { useEffect, useState } from "react";

export function usePosts(categories?: string | string[]) {
	const [posts, setPosts] = useState<Post[]>([]);
	useEffect(() => {
		getPosts(categories).then(setPosts);
		return () => setPosts([]);
	}, [categories]);
	return posts;
}

export function usePost(id: string) {
	const [post, setPost] = useState<Post | undefined | null>();
	useEffect(() => {
		getPost(id).then((p) => setPost(p || null));
		return () => setPost(undefined);
	}, [id]);
	return post;
}
