import { useState } from "react";
import cx from "classnames";

import { Range as ReactRange, getTrackBackground } from "react-range";
type Props = {
	min: number;
	max: number;
	step?: number;
	defaultValues?: number[];
	onChange?: (values: number[]) => void;
	className?: string;
};

const Range: React.FC<Props> = (props) => {
	const [values, setValues] = useState(props.defaultValues || [0, 100]);
	return (
		<div
			className={cx(
				"mx-4 mt-4 flex flex-wrap justify-center",
				props.className,
			)}
		>
			<ReactRange
				values={values}
				step={props.step || 1}
				min={props.min}
				max={props.max}
				onChange={(values) => {
					setValues(values);
					props.onChange?.call(null, values);
				}}
				renderTrack={({ props: p, children }) => (
					<div
						onMouseDown={p.onMouseDown}
						onTouchStart={p.onTouchStart}
						className="my-4 flex w-full"
						style={p.style}
					>
						<div
							ref={p.ref}
							className="h-1 w-full self-center rounded text-gray-600"
							style={{
								background: getTrackBackground({
									values,
									colors: ["#ccc", "currentColor", "#ccc"],
									min: props.min,
									max: props.max,
								}),
							}}
						>
							{children}
						</div>
					</div>
				)}
				renderThumb={({ index, props: p }) => (
					<div
						{...p}
						className="flex h-4 w-4 items-center justify-center rounded-full bg-gray-600"
					>
						<div className="absolute -top-8 p-1 text-xs">
							{values[index]?.toLocaleString()}
						</div>
					</div>
				)}
			/>
		</div>
	);
};

export default Range;
