import { Localized, useLocalization } from "@launerlondon/l10n";
import { Link } from "react-router-dom";
import stylesheet, { mixins } from "../styles";

type Props = {
	title?: string;
	text?: string;
	url?: string;
	green?: true;
	center?: true;
	className?: string;
};

const Disclaimer: React.FC<Props> = (props) => {
	const { l10n } = useLocalization();
	const link = l10n.getString(props.url || "");
	const externalLink = link.startsWith("http");

	return (
		<div
			css={[
				styles.container,
				props.green && styles.containerGreen,
				props.center && { textAlign: "center" },
			]}
			className={props.className}
		>
			{props.title && (
				<Localized
					id={props.title}
					children={<h4 css={styles.title} />}
				/>
			)}
			<Localized
				id={props.text}
				attrs={{ href: true }}
				elems={{
					a: externalLink ? (
						<a
							className="underline"
							href={link}
							target="_blank"
							rel="noopener noreferrer"
						/>
					) : (
						<Link to={link} className="underline" />
					),
				}}
				children={<p css={{ textDecoration: "none" }} />}
			/>
		</div>
	);
};

const styles = stylesheet({
	container: {
		...mixins.alert,
		lineHeight: 1.6,
		marginTop: 20,
		p: { margin: 0 },
		a: { color: "inherit" },
	},
	containerGreen: {
		color: "#60A160",
		backgroundColor: "#E1FAE1",
	},
	title: {
		lineHeight: 1.4,
		marginTop: 0,
		marginBottom: 5,
		color: "inherit",
	},
});

export default Disclaimer;
