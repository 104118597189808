import { CartItemSnapOption, getRates } from "@launerlondon/products";
import { RetailOrder, VoucherRef } from "@launerlondon/shared";
import { Profile } from "@launerlondon/shared-types";
import { LoaderFunction, defer, redirect } from "react-router-dom";
import { cachedApiRequest, localCache } from "./api";
import routes from "./routes";

function shouldRefresh(request: Request) {
	return Boolean(new URL(request.url).searchParams.get("refresh"));
}
export function isLoggedIn() {
	return /loggedIn=true/.test(document.cookie);
}

export type AppLoaderData = {
	listViewMode: string;
	loggedIn: boolean;
	bookmarks: Promise<
		{ sku: string; hash?: string; options?: Array<CartItemSnapOption> }[]
	>;
};
export const appLoader: LoaderFunction = ({ request }) => {
	const loggedIn = isLoggedIn();
	const response: AppLoaderData = {
		listViewMode: localCache.get("listViewMode") || "tight",
		loggedIn,
		bookmarks: loggedIn
			? cachedApiRequest("list", "/user/bookmarks", {
					refresh: shouldRefresh(request),
			  })
			: Promise.resolve([]),
	};
	return defer(response);
};

export const authLoader: LoaderFunction = ({ request }) => {
	if (!isLoggedIn()) {
		const { pathname } = new URL(request.url);
		return redirect(`${routes.signIn}?next=${pathname}`);
	}
	return null;
};

export type CheckoutLoaderData = {
	vouchers: VoucherRef[];
	profile: Promise<Profile> | undefined;
};
export const checkoutLoader: LoaderFunction = async ({ request }) => {
	const loggedIn = isLoggedIn();
	const response: CheckoutLoaderData = {
		vouchers: localCache.get("vouchers") || [],
		profile: loggedIn
			? cachedApiRequest("read", "/user/profile", {
					refresh: shouldRefresh(request),
			  })
			: undefined,
	};
	return defer(response);
};

export type ProfileLoaderData = {
	profile: Promise<Profile>;
};
export const profileLoader: LoaderFunction = ({ request }) => {
	const r: ProfileLoaderData = {
		profile: cachedApiRequest("read", "/user/profile", {
			refresh: shouldRefresh(request),
		}),
	};
	return defer(r);
};

export type AccountOrderListLoaderData = {
	orders: Promise<Array<RetailOrder & { id: string }>>;
};
export const accountOrderListLoader: LoaderFunction = async ({ request }) => {
	const r: AccountOrderListLoaderData = {
		orders: cachedApiRequest("list", "/user/orders", {
			refresh: shouldRefresh(request),
		}),
	};
	return defer(r);
};

export type AccountOrderViewLoaderData = {
	profile: Promise<Profile>;
	order: Promise<RetailOrder & { id: string }>;
};
export const accountOrderViewLoader: LoaderFunction = async ({
	params,
	request,
}) => {
	const r: AccountOrderViewLoaderData = {
		profile: cachedApiRequest("read", "/user/profile", {
			refresh: shouldRefresh(request),
		}),
		order: cachedApiRequest("read", "/user/orders", {
			id: params.id,
			refresh: shouldRefresh(request),
		}),
	};
	return r;
};

export type ProductLoaderData = {
	rates: Awaited<ReturnType<typeof getRates>>;
};
export const productLoader: LoaderFunction = async () => {
	return defer({
		rates: await getRates(),
	} as ProductLoaderData);
};
