import {
	Amount,
	Currency,
	decimalRoundFactor,
	defaultRoundFactor,
	getItemOptionTotal,
	mergeAmounts,
} from "./retailOrder";

export type VoucherEventType =
	| "create"
	| "redeem"
	| "refund"
	| "enable"
	| "disable"
	| "share";

export type VoucherEvent = {
	type: VoucherEventType;
	message?: string;
	date: Date;
	amount?: number;
	order?: { id: string; ref: number };
};

export type VoucherRef = {
	id: string;
	amount: number;
	currency: string;
};

export type VoucherRaw = VoucherRef & {
	initialAmount: number;
	code: string;
	createdAt: Date;
	events: [VoucherEvent, ...VoucherEvent[]];
	disabled?: true;
};

export type VoucherRefBalanced = VoucherRef & {
	multiplier: number;
	residual?: number;
};

export type VoucherRefBalancedWithAmount = VoucherRef & {
	multiplier: Amount;
	residual?: Amount;
};

export type VoucherSnap = {
	id: string;
	code: string;
	createdAt: Date;
};

export function tsToDate(date: any & { _seconds?: number; seconds?: number }) {
	const secs = date.seconds || date._seconds || 0;
	return new Date(secs * 1000);
}

export const voucherCurrencies = ["GBP", "EUR", "USD"];
export const voucherFormat = "AA0-AAA-0AA-AA0";

export function isVoucherCodeValid(code?: string): code is string {
	const format = new RegExp(voucherFormat.replace(/[A0]/g, "\\w"));
	if (!code) {
		throw new Error("missing voucher code");
	}
	if (code && !format.test(code)) {
		throw new Error("Invalid voucher format, please try again");
	}
	return true;
}

export function parseVoucherValue(value: string) {
	const [amountStr, currencyStr] = value.split(" ");
	const amount = parseInt(amountStr || "");
	const currency = currencyStr || "";
	return { amount, currency };
}

export async function generateVoucher(
	amount: number,
	currency: string,
	order?: { id: string; ref: number },
) {
	if (isNaN(amount) || !voucherCurrencies.includes(currency)) {
		throw new Error("missing properly formatted amount field i.e: 100 GBP");
	}
	const now = new Date();
	const event: VoucherEvent = {
		type: "create",
		date: now,
		amount,
	};
	if (order) {
		event.order = { id: order.id, ref: order.ref };
	}

	const { generate } = await import("voucher-codes-generator");
	const voucher: Omit<VoucherRaw, "id"> = {
		createdAt: now,
		code: generate(voucherFormat),
		initialAmount: amount,
		amount,
		events: [event],
		currency,
	};

	return voucher;
}

export function getVoucherExpiryDate(voucher: { createdAt: Date }) {
	const date = tsToDate(voucher.createdAt);
	date.setUTCFullYear(date.getUTCFullYear() + 1);
	return date;
}

export function isVoucherExpired(voucher: { createdAt: Date }) {
	return getVoucherExpiryDate(voucher) < new Date();
}

export function getVoucherMultiplier(voucher: VoucherRef, amount: Amount) {
	const currency = voucher.currency.toLowerCase() as Currency;
	const value = voucher.amount;
	const baseValue = amount[currency];
	return baseValue ? value / baseValue : 0;
}

export function getVoucherAmount(voucher: VoucherRef, amount: Amount) {
	return getItemOptionTotal(
		{ multiplier: getVoucherMultiplier(voucher, amount) },
		amount,
		defaultRoundFactor,
	);
}

export function balance(vouchers: VoucherRef[], total: Amount) {
	const multipliers = vouchers
		.map<VoucherRefBalanced>((v) => ({
			...v,
			multiplier: getVoucherMultiplier(v, total),
		}))
		.sort((a, b) => (a.multiplier > b.multiplier ? -1 : 1));
	let next = 0;
	return multipliers
		.map(({ multiplier: m, ...voucher }) => {
			next += m;
			if (next <= 1) return { ...voucher, multiplier: m };
			const residual = next - 1;
			const multiplier = m - residual;
			if (multiplier < 0) return;
			return { ...voucher, multiplier, residual };
		})
		.filter((v): v is VoucherRefBalanced => v !== undefined);
}

export function balanceWithAmounts(vouchers: VoucherRef[], total: Amount) {
	return balance(vouchers, total).map<VoucherRefBalancedWithAmount>(
		({ multiplier, residual, ...rest }) => ({
			...rest,
			multiplier: getItemOptionTotal(
				{ multiplier },
				total,
				decimalRoundFactor,
			),
			residual: residual
				? getItemOptionTotal(
						{ multiplier: residual },
						total,
						decimalRoundFactor,
				  )
				: undefined,
		}),
	);
}

export function getTotalVouchers(vouchers: VoucherRef[], total: Amount) {
	return mergeAmounts(
		balanceWithAmounts(vouchers, total).map((v) => v.multiplier),
	);
}

export function isVoucherValid(voucher?: VoucherRaw): voucher is VoucherRaw {
	if (!voucher) {
		throw new Error("invalid-voucher");
	}
	if (voucher.disabled) {
		throw new Error("disabled-voucher");
	}
	if (isVoucherExpired(voucher)) {
		throw new Error("expired-voucher");
	}
	if (voucher.amount <= 0) {
		throw new Error("exhausted-voucher");
	}
	return true;
}

export function getVoucherStatus(voucher?: VoucherRaw) {
	try {
		isVoucherValid(voucher);
		return "active";
	} catch (err: any) {
		return err.message.replace("-voucher", "");
	}
}
