import { findSku } from "@launerlondon/shared";
import skus from "../skus";
import { CartItemSnapOption } from "./cartItem";

function rewriteCompoundSearchQuery(
	search: string,
	terms: Record<string, string[]>,
) {
	const t = Object.keys(terms)
		.filter((k) => k.includes("-"))
		.map((k) => k.replace(/-/g, " "))
		.join("|");

	return search.replace(new RegExp(`\\b${t}\\b`), (m) =>
		m.replaceAll(" ", "-"),
	);
}

export function fmtSearchQuery(search: string): RegExp[] {
	const syn = [
		["blue", "turquoise", "marine", "cobalt"],
		["purple", "lilac", "cherry"],
		["orange", "tangerine", "spiced orange"],
		["red", "scarlet"],
		["pink", "magenta", "rose"],
		["black", "noir"],
		["bag", "handbag"],
		["fob", "ring"],
		["phone", "iphone"],
		["tablet", "ipad"],
		["spectacles", "specs", "glasses"],
		["tray", "jewellery"],
		["passport", "travel"],
	];
	const terms: Record<string, string[]> = {
		"large-purse": ["primrose"],
		"medium-purse": ["azalea"],
		"small-purse": ["tulip"],
		coin: ["poppy", "daisy"],
		purse: ["tulip", "primrose", "azalea", "ivy", "freesia", "poppy"],
		zip: ["jasmine", "lilly"],
	};

	const keywords = rewriteCompoundSearchQuery(search, terms).split(" ");

	return keywords.map((k) => {
		const s = (syn.filter((t) => t.includes(k))[0] || [k]).concat(
			terms[k] || [],
		);
		const m = new RegExp(`\\b(${s.join("|")})`, "i");
		return m;
	});
}

export function fmtProductName(name: string): Array<string | undefined> {
	if (name === "\u00A0–\u00A0") {
		return [];
	}
	return name.split(/ [-–—] /);
}

export function filterCustomOptions(options: CartItemSnapOption[]) {
	return options.filter((o) => {
		//if ("default" in o) return !o.default;
		if ("default" in o && o.default !== undefined) return !o.default;
		return o.value !== undefined;
	});
}

export function isDigitalItem(item: { sku: string }) {
	return Boolean(findSku(item.sku, skus.vouchers));
}

export const isVoucher = isDigitalItem;

export const voucherCreditLabel = "Voucher credit";

export function isVoucherCreditOption(option: { name: string }) {
	return option.name === voucherCreditLabel;
}

export function isCartDigital(items: { sku: string }[]) {
	return items.every(isDigitalItem);
}
