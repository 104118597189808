import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Order } from "@launerlondon/shop-components";
import { AccountOrderViewLoaderData, routes } from "@launerlondon/shop-router";
import { Suspense } from "react";
import {
	Await,
	Link,
	useAsyncValue,
	useFetcher,
	useLoaderData,
} from "react-router-dom";

const OrderView: React.FC = () => {
	const { submit } = useFetcher();
	const order = useAsyncValue() as Awaited<
		AccountOrderViewLoaderData["order"]
	>;
	return (
		<div>
			<header className="mb-4 flex justify-between text-xs">
				<Link className="flex underline" to={routes.accountOrderList}>
					<ChevronLeftIcon className="h-4 w-4" />
					Back to orders
				</Link>
				<button
					className="underline"
					onClick={() => submit({ refresh: "true" })}
				>
					Refresh order
				</button>
			</header>
			<Order order={order} />
		</div>
	);
};

const AccountOrderView: React.FC = () => {
	const data = useLoaderData() as AccountOrderViewLoaderData;
	return (
		<Suspense fallback="loading order…">
			<Await resolve={data.order}>
				<OrderView />
			</Await>
		</Suspense>
	);
};

export default AccountOrderView;
