import { useMetaTags } from "@launerlondon/shop-hooks";
import { Row } from "@launerlondon/shop-components";
import { Link } from "react-router-dom";
import { routes } from "@launerlondon/shop-router";

const bannerImg = "/images/pages/customise-banner.jpg";

const Customise: React.FC = () => {
	useMetaTags({
		title: "Innovator of colour customisation",
		description:
			"Enquire further for details of our exclusive Customisation Service. Launer is a pioneer of luxury leather customisation, a unique service -  every item can be crafted to the customer’s choice choosing from our Colour Portfolio.",
		image: bannerImg,
	});

	return (
		<div className="text-center">
			<div className="bg-gray-50 text-left">
				<div className="container grid max-lg:py-8 lg:grid-cols-2">
					<div className="grid items-center">
						<div className="space-y-2 text-sm lg:max-w-lg">
							<h1 className="ln-subtitle">CUSTOMISATION</h1>
							<p>
								Exclusive, creative and personal. Get creative
								with the colour, material and fixtures of your
								choice.
							</p>
						</div>
					</div>
					<div
						className="aspect-[4/2] bg-gray-50 bg-cover bg-center max-lg:hidden"
						style={{ backgroundImage: `url(${bannerImg})` }}
					/>
				</div>
			</div>
			<Row.WithImage
				reverse
				className="text-left"
				contentClassName="lg:pl-0 lg:place-self-center lg:justify-self-start"
				imageClassName="lg:m-12 lg:ml-0"
				image="/images/pages/customise-material.jpg"
			>
				<h2 className="ln-subtitle">Exterior Material</h2>
				<p>
					A Launer article created by you, with no limitations when
					you select from our extensive Colour Portfolio of over 60
					colour shades and leather finishes: smooth calf, patent,
					mock croc and metallics provide inspiring choices.
				</p>
				<p>
					Our leathers are sourced from certified and ethical
					tanneries. Every aspect of the exterior can be customised:
					flaps, body, gussets, handles and straps.
				</p>
				<p>
					Every article in our collection, from handbags, to purses,
					wallets and accessories can be reimagined in a colour(s) and
					leather finish of your choice. It’s a luxury milestone,
					unique to Launer and handcrafted to our renowned impeccable
					standard. There is no additional cost for this bespoke
					service, should you opt for the same calf or patent leather
					finishes.
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				className="bg-[#F8F6F6]"
				image="/images/pages/customise-animation.gif"
				imageClassName="bg-white bg-[length:60%]"
			>
				<h2 className="ln-subtitle">3D VISUALISATION</h2>
				<p>
					Our exclusive made to order service has become so successful
					that we wanted to develop it further to provide a truly
					interactive experience. Our 3d visualiser enables an
					enhanced customisation journey displaying an authentic
					rendition of the colours and materials in our Colour
					Portfolio.
				</p>
				<button className="underline">
					Try it with our classic Traviata
				</button>
			</Row.WithImage>
			<Row.WithImage image="/images/pages/customise-lining.jpg">
				<h2 className="ln-subtitle">LINING</h2>
				<p>
					We offer suede or woven (for framed handbags) linings that
					have been specially sourced and selected for their sumptuous
					appearance and luxurious touch. Any colour from our stunning
					palette of 9 shades can be selected.
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				className="bg-[#CCE0FF]"
				image="/images/pages/customise-lettering.jpg"
			>
				<h2 className="ln-subtitle">LETTERING</h2>
				<p>
					Make your creation unique with embossing; the addition of
					initials on handbags and accessories, creating a treasured
					personal product, the hallmark of a prestige item.{" "}
				</p>
				<p>
					A traditional and specialised skill that is carried out by
					our experienced craftspeople and applied in a range of foil
					finishes; gold, silver or blind. They can be placed
					virtually anywhere on a style if desired with customer
					service on hand to advise the best placement.
				</p>
			</Row.WithImage>
			<Row.WithImage image="/images/pages/customise-fittings.jpg">
				<h2 className="ln-subtitle">FITTINGS</h2>
				<p>
					Our fittings are crafted in Italy with exceptional detail
					and elegance; each one polished and refined to achieve an
					exquisite lustrous finish.{" "}
				</p>
				<p>
					We offer gold plated or palladium plated to suit your
					handbag or accessory creation.
				</p>
			</Row.WithImage>
			<Row.WithImage reverse image="/images/pages/customise-emblem.jpg">
				<h2 className="ln-subtitle">EMBLEM</h2>
				<p>
					The twisted rope icon is the trade marked signature emblem
					of Launer, it is recognisbale as a symbol of heritage and
					craftsmanship and embodies our quiet luxury philosophy.{" "}
				</p>
				<p>
					It adopts many guises and also comes in flat form or crystal
					embellished in gold or palladium finish and scaled in size.
				</p>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#F8F8F8]"
				image="/images/pages/customise-gold-corners.jpg"
				imageClassName="lg:m-12 lg:mr-0"
			>
				<h2 className="ln-subtitle">GOLD PLATED CORNERS</h2>
				<p>
					Gold plated tipped corners can be applied to our men’s
					wallets and credit card cases adding protection and a
					stylish, refined appearance.
				</p>
				<p>
					<Link className="underline" to="/s/eight-card-wallet">
						See how gold corners look on our classic eight card
						wallet
					</Link>
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/pages/customise-shielding.jpg"
				imageClassName="lg:m-12 lg:ml-0"
			>
				<h2 className="ln-subtitle">FARADAY SHIELDING</h2>
				<p>
					Faraday® Shielding ensures an increased level of security
					by prevention on leather goods. It helps shield your credit
					card information with its patented covering, providing added
					reassurance in a discreet manner.
				</p>
			</Row.WithImage>
			<Row.WithImage
				image="/images/pages/customise-straps.jpg"
				className="bg-[#F8F8F8]"
				imageClassName="lg:m-12 lg:mr-0"
			>
				<h2 className="ln-subtitle">STRAPS</h2>
				<p>
					Straps are integral at Launer, they accompany most of our
					handbag styles, transforming top handle chic to informal
					cross body elegance.
				</p>
				<p>
					Now, you can refresh your bag in an instant with our new
					service{" "}
					<Link to="/p/010-01/handbag-strap" className="underline">
						Strap Collection
					</Link>
					, which covers all the colours and finishes in our range to
					suit your mood and occasion.
				</p>
			</Row.WithImage>
			<Row.Single className="lg:py-14">
				<h2 className="ln-subtitle">CUSTOMER SERVICE</h2>
				<p>
					Our customer service department is on hand to assist with
					all aspects of customisation and personalisation services
					and for any other queries.
				</p>
				<p>
					You can also order a selection of swatches from our Colour
					Portfolio. For further details{" "}
					<Link to={routes.contact} className="underline">
						please contact us
					</Link>
					.
				</p>
			</Row.Single>
		</div>
	);
};

export default Customise;
