import { Localized } from "@launerlondon/l10n";
import { useMetaTags, usePosts } from "@launerlondon/shop-hooks";
import { Post } from "@launerlondon/shop-posts";
import { routes } from "@launerlondon/shop-router";
import { useLayoutEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";
import { Link, generatePath } from "react-router-dom";

const NewsListItem: React.FC<{ post: Post }> = ({ post }) => {
	const imageRef = useRef<HTMLImageElement>(null);
	const isVisible = useIsVisible(imageRef);
	const [img, setImg] = useState<string>();
	useLayoutEffect(() => {
		if (isVisible && !img) {
			setImg(post.images[0]?.medium);
		}
	}, [img, isVisible]);
	return (
		<article className="border">
			<Link
				to={generatePath(routes.newsView, {
					id: post.id,
					"*": post.slug,
				})}
			>
				<div
					ref={imageRef}
					className="bg-gray-50 bg-cover pb-[100%]"
					style={{
						backgroundImage: `url(${img?.replace(
							".webp",
							".jpeg",
						)})`,
					}}
				/>
				<header className="space-y-2 p-2">
					<h3 className="font-sans text-lg font-light leading-tight">
						{post.title}
					</h3>
					<h4 className="text-xs">
						{post.date.toLocaleDateString(undefined, {
							dateStyle: "long",
						})}
					</h4>
				</header>
			</Link>
		</article>
	);
};

const NewsList: React.FC = () => {
	const posts = usePosts();
	useMetaTags({ title: "News/Journal" }, []);

	return (
		<div className="container">
			<h1 className="font-display my-10 text-2xl font-light">
				<Localized id="menu-news-press" />
			</h1>
			<div className="my-10 grid gap-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{posts.length === 0 && <div>sorry, no posts...</div>}
				{posts.map((post) => (
					<NewsListItem key={post.id} post={post} />
				))}
			</div>
		</div>
	);
};

export default NewsList;
