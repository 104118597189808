import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export default function CollectionCleverColourCombinations() {
	useMetaTags({
		title: "Clever Colour Combinations",
		description:
			"Venture into the world of Launer and discover our inspiring colour customisation service. The ability to transform a handbag or any of our products is so easy when you choose from any of the 60 plus shades.",
		image: "/images/collections/clever-colour-combinations-banner.webp",
	});
	return (
		<div className="text-center max-xl:text-sm">
			<div
				className={twJoin(
					"aspect-square max-h-[60vh] w-full",
					"bg-gray-200 bg-cover bg-[left_-20rem_top] lg:bg-center",
					"bg-[url('/images/collections/clever-colour-combinations-banner.webp')]",
				)}
			/>
			<Row.Single className="text-base">
				<h1 className="ln-title">Clever Colour Combinations</h1>
				<p>
					Venture into the world of Launer and discover our inspiring
					colour customisation service. The ability to transform a
					handbag or any of our products is so easy when you choose
					from any of the 60 plus shades. Our hero edit demonstrates
					how compelling each style looks when a duo or trio colour
					combination is applied, it can be a handle, a flap or even a
					gusset, the possibilities are endless at no additional cost.
				</p>
			</Row.Single>
			<Products
				className="container"
				skus={["2914-13", "3126-06", "3097-11", "3124-01"]}
			/>
			<Products
				className="container"
				skus={["3108-03", "2914-03", "3124-08", "3011-03"]}
			/>
			<Row.WithImage
				reverse
				image="/images/collections/clever-colour-combinations-encore.webp"
				className="bg-[#FBF1ED] text-[#746C6C]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Encore</h2>
					<h3 className="text-sm">Very Berry / Dusky Rose</h3>
				</header>
				<p className="my-10">
					Very Berry is the predominant colour used on the Encore with
					Dusky Rose applied to the flap. A peerless style with
					understated detailing that is the essence of
					irresistibility. The Encore has been garnering plaudits
					since it launched in 1986 and is from our Legacy Collection
				</p>
				<Link
					to="/p/2914-13/encore-very-berry-dusky-rose"
					className="!mt-20 inline-block text-sm uppercase tracking-widest"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["2947-09", "3113-01", "3124-02", "3097-26"]}
			/>
			<Products
				className="container"
				skus={["2914-16", "3111-01", "3087-05", "3110-01"]}
			/>
			<Row.WithImage
				image="/images/collections/clever-colour-combinations-iris.webp"
				className="bg-[#FBF1ED] text-[#746C6C]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Iris</h2>
					<h3 className="text-sm">Spiced Orange / Buttermilk</h3>
				</header>
				<p className="my-10">
					Warm tones of Buttermilk are combined with intense Spiced
					Orange to stunning effect on Iris. Delightfully slight it's
					a style of immense appeal, introduced in celebration of our
					80th anniversary. Timeless, stylish and an investment in
					British craftsmanship.
				</p>
				<Link
					to="/p/3124-01/iris-spiced-orange-buttermilk"
					className="!mt-20 inline-block text-sm uppercase tracking-widest"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["738-08", "805-14", "785-09", "874-05"]}
			/>
			<Row.WithImage
				reverse
				image="/images/collections/clever-colour-combinations-six-card-holder.webp"
				className="bg-[#FBF1ED] text-[#746C6C]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Six Card Holder</h2>
					<h3 className="text-sm">Sage / Bone White</h3>
				</header>
				<p className="my-10">
					The essential card holder is elevated with the use of colour
					and detail. Deep tones of Sage are contrasted with the
					porcelain like Bone White. It fits neatly into handbags and
					holds six cards with two inner side pockets.
				</p>
				<Link
					to="/p/882-04/six-card-case-sage-bone-white"
					className="!mt-20 inline-block text-sm uppercase tracking-widest"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
		</div>
	);
}
