import { ProductListItem } from "@launerlondon/shop-components";
import { useProducts } from "@launerlondon/shop-hooks";
import { AppLoaderData, routes } from "@launerlondon/shop-router";
import { Suspense } from "react";
import { Await, useAsyncValue, useRouteLoaderData } from "react-router-dom";

type Bookmark = Awaited<AppLoaderData["bookmarks"]>[number];

const Comp: React.FC<{ bookmark: Bookmark }> = (props) => {
	const { sku, options } = props.bookmark;
	const product = useProducts([sku])[0];
	if (product) {
		return (
			<ProductListItem product={product} hidePrices options={options} />
		);
	}
	return null;
};

const View: React.FC = () => {
	const bookmarks = useAsyncValue() as Bookmark[];
	if (bookmarks.length === 0) {
		return <div>No products have been added to your wishlist yet…</div>;
	}
	return (
		<div className="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4">
			{bookmarks.map((b, idx) => (
				<Comp key={idx} bookmark={b} />
			))}
		</div>
	);
};

const AccountBookmarks: React.FC = () => {
	const data = useRouteLoaderData(routes.home) as AppLoaderData;
	return (
		<Suspense fallback="loading...">
			<Await
				resolve={data.bookmarks}
				errorElement={<p>Could not load bookmarks.</p>}
			>
				<View />
			</Await>
		</Suspense>
	);
};

export default AccountBookmarks;
