import type { Timestamp } from "../lib/retailOrder";

export const version = import.meta.env?.VITE_PKG_VERSION?.toString() || "0.0.0";

function currencyToLocale(code: string): string {
	const langs = {
		AED: "en-AE",
		AUD: "en-AU",
		BRL: "pt-BR",
		CAD: "en-CA",
		CHF: "de-CH",
		CNY: "zh-CN",
		DKK: "da-DK",
		EUR: "de-DE",
		GBP: "en-GB",
		HKD: "zh-HK",
		NOK: "no-NO",
		NZD: "en-NZ",
		SEK: "sv-SE",
		SGD: "zh-SG",
		USD: "en-US",
	};
	if (code in langs) {
		const c = code as keyof typeof langs;
		return langs[c];
	}
	return "en-GB";
}

export function fmtOrderRef(ref: number): string {
	return ref.toString().padStart(4, "0");
}

export function fmtPrice(
	amount: number,
	currency = "GBP",
	currencyDisplay = "symbol",
): string {
	const locale = currencyToLocale(currency);
	return amount.toLocaleString(locale, {
		style: "currency",
		currency: currency === "base" ? "GBP" : currency,
		currencyDisplay,
	});
}

export function fmtProductName(name: string): string[] {
	return name.split(/ [-–—] /);
}

export const toDate = (date: Timestamp): Date => {
	return date.toDate ? date.toDate() : date;
};

export function getSwatchColor(
	swatches: typeof import("../../assets/swatches.json"),
	swatchId: string,
) {
	return swatches.flatMap((g) => g.children).find((s) => s.id === swatchId)
		?.color;
}

export function fmtSku(sku: string) {
	const [group, model] = sku.split("-");
	const skuGroup = group?.trim() || "";
	const skuModel = model?.trim() || "";
	return { group: skuGroup, model: skuModel };
}

export function getDeliveryTimes(sku: string, country: string) {
	const group = parseInt(fmtSku(sku).group);
	const small = [
		10, 11, 99, 100, 101, 102, 103, 112, 201, 202, 203, 204, 205, 206, 228,
		232, 244, 245, 254, 268, 291, 399, 477, 478, 486, 496, 501, 517, 610,
		620, 659, 670, 685, 686, 717, 726, 738, 739, 746, 759, 785, 794, 805,
		806, 872, 874, 882, 920, 925, 938, 975,
	];
	const europe = [
		"AD",
		"AL",
		"AT",
		"AX",
		"BA",
		"BE",
		"BG",
		"BY",
		"CH",
		"CY",
		"CZ",
		"DE",
		"DK",
		"EE",
		"ES",
		"FI",
		"FO",
		"FR",
		"GB",
		"GG",
		"GR",
		"HR",
		"HU",
		"IE",
		"IM",
		"IC",
		"IT",
		"JE",
		"LI",
		"LT",
		"LU",
		"LV",
		"MC",
		"MD",
		"MK",
		"MT",
		"NL",
		"NO",
		"PL",
		"PT",
		"RO",
		"RU",
		"SE",
		"SI",
		"SJ",
		"SK",
		"SM",
		"UA",
		"VA",
	];
	const isSmall = small.includes(group);
	const isEurope = europe.includes(country.toUpperCase());
	const production =
		group === 3141
			? "4-6 weeks"
			: group === 3142
			? "6-8 weeks"
			: isSmall
			? "2–3 weeks"
			: "3–4 weeks";
	const shipping = isEurope
		? "3 days"
		: country === "US"
		? "5 days"
		: "7–10 days";
	return { production, shipping };
}

export function getPaymentCurrencyByCountry(
	country?: string,
): "USD" | "GBP" | "EUR" {
	switch (country) {
		case "US":
		case "CA":
			return "USD";
		case "AT":
		case "BE":
		case "BG":
		case "CH":
		case "CY":
		case "CZ":
		case "DE":
		case "DK":
		case "EE":
		case "ES":
		case "FI":
		case "FR":
		case "GR":
		case "HR":
		case "HU":
		case "IE":
		case "IS":
		case "IT":
		case "LI":
		case "LT":
		case "LU":
		case "LV":
		case "MT":
		case "NL":
		case "NO":
		case "PL":
		case "PT":
		case "RO":
		case "SE":
		case "SI":
		case "SK":
			return "EUR";
		default:
			return "GBP";
	}
}

export function getTrackingUrl(company?: string, trackingNumber?: string) {
	return company === "DHL Parcel UK"
		? `https://track.dhlparcel.co.uk/?con=${trackingNumber}`
		: company === "Royal Mail"
		? `https://www.royalmail.com/track-your-item#/tracking-results/${trackingNumber}`
		: company === "DSV"
		? "https://www.ups.com/track"
		: "https://launer.com";
}

export function findSku(sku: string, skuList: string[]) {
	const { group } = fmtSku(sku);
	if (skuList.some((s) => s === `!${sku}`)) return;
	const r = skuList
		.filter((s) => s === group || s === sku)
		.sort((a, b) => (a.length > b.length ? -1 : 1))[0];
	return r;
}

export function allowKlarnaPayment(country: string) {
	return [
		"AT",
		"BE",
		"CH",
		"CZ",
		"DE",
		"DK",
		"ES",
		"FI",
		"FR",
		"GB",
		"GR",
		"IE",
		"IT",
		"NL",
		"NO",
		"PL",
		"PT",
		"SE",
	].includes(country);
}

export function allowAfterpayPayment(country: string) {
	return ["AU", "CA", "US", "GB", "NZ"].includes(country);
}
