import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useProducts } from "@launerlondon/shop-hooks";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { twJoin, twMerge } from "tailwind-merge";
import ProductListItem from "./ProductListItem";

import "swiper/css";
import "swiper/css/navigation";

type Props = {
	className?: string;
	products: string[];
};

function Button(props: { next?: boolean }) {
	const swiper = useSwiper();
	return (
		<button
			className={twJoin(
				"absolute inset-y-0 z-10 px-4",
				props.next ? "right-0" : "left-0",
			)}
			onClick={() =>
				props.next ? swiper.slideNext() : swiper.slidePrev()
			}
		>
			{props.next ? (
				<ChevronRightIcon className="h-6 w-6" />
			) : (
				<ChevronLeftIcon className="h-6 w-6" />
			)}
		</button>
	);
}

export default function ProductCarousel(props: Props) {
	const products = useProducts(props.products);
	return (
		<Swiper
			className={twMerge("w-full mix-blend-multiply", props.className)}
			modules={[Autoplay]}
			slidesPerView={1}
			centeredSlides
			autoplay={{ delay: 5000 }}
			loop
		>
			<Button />
			{products.map((p) => (
				<SwiperSlide key={p.sku}>
					{({ isActive }) => (
						<ProductListItem
							product={p}
							className={twJoin(
								"mx-4 normal-case tracking-normal transition-opacity duration-300",
								isActive ? "" : "opacity-0",
							)}
							imageContainerClassName={twJoin(
								"bg-transparent",
								"mx-auto aspect-[4/3] max-w-[400px] pb-0",
								"[&_.group]:hover:scale-100",
							)}
							skipBaseImage
							hideNewFlag
							hideBookmarkButton
						/>
					)}
				</SwiperSlide>
			))}
			<Button next />
		</Swiper>
	);
}
