import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { getSwatchOptionLocaleId, Localized } from "@launerlondon/l10n";
import { CartItemSnapOption } from "@launerlondon/products";
import { Price, SwatchPicker } from "@launerlondon/shop-components";
import type { RootState } from "@launerlondon/shop-types";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActionData } from "react-router-dom";

type RawSwatch = { id: string; name: string; thumb: string };
export type Swatch = RawSwatch & { group: string };
type SwatchGroup = { id: string; name: string; children: RawSwatch[] };

type Props = {
	label: string;
	items: string[];
	initialValue: string;
	onChange(swatch: Swatch): number;
};

const SwatchSelector: React.FC<Props> = ({
	label,
	initialValue,
	items,
	onChange,
}) => {
	const data = useActionData() as CartItemSnapOption[] | undefined;
	const { currency, country } = useSelector((s: RootState) => s.locale);
	const [swatches, setSwatches] = useState<Swatch[]>([]);
	const [selected, setSelected] = useState<Swatch>();
	const [price, setPrice] = useState(0);
	const [open, setOpen] = useState(false);
	const thumb = selected?.thumb.replace(".webp", ".jpeg");

	useEffect(() => {
		import("@launerlondon/shared/assets/swatches.json")
			.then<SwatchGroup[]>((d) => d.default)
			.then((groups) => {
				setSwatches(
					groups.flatMap<Swatch>((group) => {
						return group.children.map((s) => ({
							...s,
							group: group.name,
						}));
					}),
				);
			});
	}, []);

	useEffect(() => {
		if (!selected) return;
		setPrice(onChange(selected));
	}, [selected, currency, country]);

	useEffect(() => {
		swatches.length &&
			setSelected(swatches.find((i) => i.id === initialValue));
	}, [swatches, initialValue]);

	useEffect(() => {
		if (!data || !swatches) return;
		data.forEach((opt) => {
			if (opt.type === "swatch" && opt.meta.label === label) {
				const sel = swatches.find((s) => s.id === opt.meta.id);
				setSelected(sel);
			}
		});
	}, [data, swatches, currency, country]);

	return (
		<>
			<div
				className="grid cursor-pointer grid-cols-4 items-center"
				onClick={() => setOpen(true)}
			>
				<span>
					<Localized
						id={getSwatchOptionLocaleId(label)}
						children={label}
					/>
				</span>
				<div className="col-span-3 flex items-center gap-2 bg-gray-50 p-2 lg:gap-8">
					<span
						className="block h-6 w-6 shrink-0 rounded-sm bg-cover"
						style={
							thumb ? { backgroundImage: `url(${thumb})` } : {}
						}
					/>
					<span className="ln-subtitle-sans h-3 truncate text-xs leading-none lg:h-auto">
						<Localized prefix="swatch" children={selected?.name} />
					</span>
					<div className="ml-auto flex items-center gap-2">
						<Price className="h-3 text-xs" value={price} />
						<ChevronRightIcon className="mr-2 h-3 w-3 text-gray-400" />
					</div>
				</div>
			</div>
			<AnimatePresence>
				{open && (
					<SwatchPicker
						label={label}
						value={selected}
						items={items}
						swatches={swatches}
						onCancel={() => setOpen(false)}
						onChange={(s) => {
							setOpen(false);
							setSelected(s);
							setPrice(onChange(s));
						}}
					/>
				)}
			</AnimatePresence>
		</>
	);
};

export default SwatchSelector;
