import { Localized, useLocalization } from "@launerlondon/l10n";
import type {
	Event as RetailOrderEvent,
	PaymentType,
} from "@launerlondon/shared";
import { Modal } from "@launerlondon/shop-components";
import { useStripe } from "@stripe/react-stripe-js";
import { AnimatePresence } from "framer-motion";
import QRCode from "qrcode.react";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import iconUrl from "../../assets/favicon.png";
import { Button } from "../components";
import stylesheet, { colors, media } from "../styles";

type Props = {
	onCancel(): void;
	type: PaymentType;
	event: RetailOrderEvent | undefined;
};

type QRCodeModalProps = {
	type: PaymentType;
	url: string;
	onCancel: Props["onCancel"];
};

const isMobile = (): boolean => {
	const device = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	const mq = matchMedia(media.mobile.replace("@media ", "")).matches;
	return device || mq;
};

const QRCodeModal: React.FC<QRCodeModalProps> = ({ type, url, onCancel }) => {
	const { l10n } = useLocalization();
	const [mobileView, setMobileView] = useState(isMobile());
	const onLinkClick: MouseEventHandler = useCallback(
		(e) => {
			e.preventDefault();
			window.open(url);
		},
		[url],
	);
	return (
		<Modal
			title={l10n.getString(`checkout-modal-title--qr-${type}`)}
			onCancel={onCancel}
			css={styles.container}
		>
			{mobileView ? (
				<>
					<Button
						css={styles.qrButton}
						onClick={onLinkClick}
						title={l10n.getString(
							`checkout-modal-button--qr--${type}`,
						)}
					/>
					<small css={styles.footer}>
						<Localized id="checkout-modal-footer--qr--mobile" />
						<br />
						<a
							css={styles.footerLink}
							onClick={() => setMobileView(false)}
						>
							<Localized id="checkout-modal-footer-link--qr--mobile" />
						</a>
					</small>
				</>
			) : (
				<>
					<Localized
						id="checkout-modal-subtitle--qr"
						children={<p css={styles.text} />}
					/>
					<QRCode
						value={url}
						level="L"
						size={320}
						imageSettings={{
							src: iconUrl,
							excavate: true,
							height: 40,
							width: 40,
						}}
						css={styles.qrcode}
					/>
					<small css={styles.footer}>
						<Localized id="checkout-modal-footer--qr" />
						<br />
						<a
							css={styles.footerLink}
							target="_blank"
							rel="noreferrer"
							href={url}
							onClick={onLinkClick}
						>
							<Localized id="checkout-modal-footer-link--qr" />
						</a>
						.
					</small>
				</>
			)}
		</Modal>
	);
};

const CartPaymentRedirect: React.FC<Props> = ({ type, event, onCancel }) => {
	const stripe = useStripe();
	const isDone = event?.message === "succeeded";
	const url = event?.url;
	const needsQR =
		type === "alipay" ||
		type === "wechat" ||
		type === "klarna" ||
		type === "afterpay_clearpay";

	useEffect(() => {
		if (!isDone && url && !needsQR) {
			stripe?.confirmCardPayment(url);
		}
	}, [url, needsQR]);

	if (needsQR && url) {
		return (
			<AnimatePresence>
				<QRCodeModal type={type} url={url} onCancel={onCancel} />;
			</AnimatePresence>
		);
	}

	return null;
};

const styles = stylesheet({
	container: {
		maxWidth: 400,
		padding: 20,
		textAlign: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	text: {
		fontSize: 13,
		lineHeight: 1.4,
		margin: 0,
	},
	soloText: {
		fontSize: 12,
		lineHeight: 1.4,
		marginTop: "-1.25em",
	},
	qrcode: {
		display: "block",
		margin: "1em auto",
	},
	qrButton: {
		marginTop: -10,
		marginBottom: 20,
	},
	footer: {
		display: "block",
		fontSize: 11,
		lineHeight: 1.4,
		margin: "1em auto",
	},
	footerLink: {
		fontSize: 14,
		fontWeight: "bold",
		color: colors.darkerGray,
		textDecoration: "none",
	},
	button: {
		display: "block",
		margin: "2em auto 0 auto",
	},
});

export default CartPaymentRedirect;
