import { getShareUrl } from "@launerlondon/shop-utils";
import cx from "classnames";
import Icon from "./Icon";

const providers = ["facebook", "twitter", "pinterest", "whatsapp"] as const;
type Provider = (typeof providers)[number];

type Props = {
	title?: string;
	url?: string;
	img?: string;
	className?: string;
};

const Share: React.FC<Props> = ({
	title,
	img,
	className,
	url = location.href,
}) => {
	const onClick = (provider: Provider): void => {
		const media = getShareUrl(provider, url, document.title, img);
		const { width, height, url: mediaUrl } = media;
		const l = screen.width / 2 - width / 2;
		const t = screen.height / 2 - height / 2;
		const options = `width=${width},height=${height},left=${l},top=${t}`;
		window.open(mediaUrl, "share", options);
	};
	return (
		<aside className={cx("space-y-3", className)}>
			<h3 className="font-light">{title}</h3>
			<div className="flex gap-3">
				{providers.map((s) => (
					<button
						key={s}
						className="jutify-center flex flex-col items-center rounded-sm bg-gray-100 p-2 text-gray-500 hover:text-gray-700"
						onClick={() => onClick(s)}
					>
						<Icon icon={s} className="h-5 w-5" />
					</button>
				))}
			</div>
		</aside>
	);
};

export default Share;
