import { Localized, useLocalization } from "@launerlondon/l10n";
import { countries } from "@launerlondon/shared";
import { Modal } from "@launerlondon/shop-components";
import type { Currency, RootState } from "@launerlondon/shop-types";
import { AnimatePresence } from "framer-motion";
import { ChangeEventHandler, useCallback, useEffect, useRef } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import currencies from "../../assets/currencies-shop.json";
import {
	detectLocale,
	setCountry,
	setCurrency,
	setLang,
} from "../redux/actions";

type Props = ConnectedProps<typeof connector> &
	React.HTMLAttributes<HTMLDivElement> & {
		visible: boolean;
		onCancel(): void;
	};

const connector = connect(({ locale }: RootState) => ({ locale }), {
	setLang,
	setCurrency,
	setCountry,
	detectLocale,
});

const CurrencyOption: React.FC<{ code: Currency }> = ({ code }) => {
	const { l10n } = useLocalization();
	return (
		<option value={code}>
			{code} – {l10n.getString(`locale_selector-currency--${code}`)}
		</option>
	);
};

const LocaleSelector: React.FC<Props> = ({
	onCancel,
	visible,
	locale,
	setLang,
	setCurrency,
	setCountry,
	detectLocale,
}) => {
	const { l10n } = useLocalization();
	const refLang = useRef<HTMLSelectElement>(null);
	const refCountry = useRef<HTMLSelectElement>(null);
	const refCcy = useRef<HTMLSelectElement>(null);

	useEffect(detectLocale, [detectLocale]);

	useEffect(() => {
		/* refresh on locale change */
		const lang = refLang.current;
		const currency = refCcy.current;
		const country = refCountry.current;
		if (lang !== null) {
			lang.value = locale.lang;
		}
		if (currency !== null) {
			currency.value = locale.currency;
		}
		if (country !== null) {
			country.value = locale.country;
		}
	}, [locale]);

	const onLangChange = useCallback(() => {
		const value = refLang.current?.value;
		value && setLang(value);
	}, [setLang]);

	const onCurrencyChange = useCallback(() => {
		const value = refCcy.current?.value;
		value && setCurrency(value);
	}, [setCurrency]);

	const onCountryChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
		(e) => {
			const value = e.target.value;
			value && setCountry(value);
		},
		[setCountry],
	);

	return (
		<AnimatePresence>
			{visible && (
				<Modal
					title={l10n.getString("locale_selector-title")}
					onCancel={() => onCancel()}
					className="items-stretch gap-4 p-10 !text-left"
				>
					<label>
						<span className="text-xs uppercase tracking-wide text-gray-400">
							<Localized id="locale_selector-currency-label" />
						</span>
						<select
							ref={refCcy}
							className="block w-full rounded border-none bg-gray-100 p-2 text-sm focus:ring-0"
							value={locale.currency}
							onChange={onCurrencyChange}
						>
							{currencies.map((c) => (
								<CurrencyOption key={c} code={c as Currency} />
							))}
						</select>
					</label>
					<label>
						<span className="text-xs uppercase tracking-wide text-gray-400">
							<Localized id="locale_selector-shipping-label" />
						</span>
						<select
							ref={refCountry}
							className="block w-full rounded border-none bg-gray-100 p-2 text-sm focus:ring-0"
							value={locale.country}
							onChange={onCountryChange}
						>
							{countries.map((c) => (
								<Localized
									id={`locale_selector-shipping--${c.value}`}
									key={c.value}
									children={
										<option
											value={c.value}
											children={c.title}
										/>
									}
								/>
							))}
						</select>
					</label>
					<label>
						<span className="text-xs uppercase tracking-wide text-gray-400">
							<Localized id="locale_selector-lang-label" />
						</span>
						<select
							className="block w-full rounded border-none bg-gray-100 p-2 text-sm focus:ring-0"
							ref={refLang}
							value={locale.lang}
							onChange={onLangChange}
						>
							<Localized
								id="locale_selector-lang--en"
								children={<option value="en" />}
							/>
							<Localized
								id="locale_selector-lang--zh-CN"
								children={<option value="zh-CN" />}
							/>
						</select>
					</label>
				</Modal>
			)}
		</AnimatePresence>
	);
};

export default connector(LocaleSelector);
