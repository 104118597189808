import { Disclosure } from "@headlessui/react";
import {
	ChatBubbleOvalLeftEllipsisIcon,
	ChevronLeftIcon,
	ChevronUpIcon,
	GiftIcon,
	ShareIcon,
	SwatchIcon,
} from "@heroicons/react/24/outline";
import { Localized, useLocalization } from "@launerlondon/l10n";
import {
	RetailOrderRaw,
	defaultRoundFactor,
	getOrderTotal,
	getTaxRate,
	getTotalTax,
	shouldShowTax,
	subtractAmounts,
} from "@launerlondon/shared";
import { CartVoucherRedeem, Price } from "@launerlondon/shop-components";
import { fmtCurrency } from "@launerlondon/shop-utils";
import cx from "classnames";
import { useEffect, useMemo, useState } from "react";
import { InfoModal } from "../components";
import { trackBeginCheckout } from "../lib/analytics";
import CartShareModal from "./CartShareModal";

type Props = {
	order: RetailOrderRaw;
	checkoutStatus: string;
	onSubmit(): void;
	onEdit(): void;
	testMode?: boolean;
};

type TabProps = {
	icon: typeof ChevronUpIcon;
	title: string;
	subtitle: string;
	children: any;
	className?: string;
	open?: boolean;
};

const Tab: React.FC<TabProps> = (props) => {
	return (
		<Disclosure defaultOpen={props.open}>
			{({ open }) => (
				<div className="border text-sm">
					<Disclosure.Button className="flex w-full items-center gap-4 p-4 leading-none">
						<props.icon className="h-4 w-4" />
						<Localized
							id={props.title}
							children={<h4 className="font-semibold" />}
						/>
						<h5 className="overflow-hidden text-ellipsis whitespace-nowrap">
							<Localized id={props.subtitle} />
						</h5>
						<ChevronUpIcon
							className={cx(
								"ml-auto h-4 w-4",
								open && "rotate-180 transform",
							)}
						/>
					</Disclosure.Button>
					<Disclosure.Panel
						className={cx("border-t p-8", props.className)}
					>
						{props.children}
					</Disclosure.Panel>
				</div>
			)}
		</Disclosure>
	);
};

const OrderSummary: React.FC<Props> = (props) => {
	const { order, onSubmit, checkoutStatus, testMode } = props;
	const { l10n } = useLocalization();
	const { currency: displayCurrency } = order.customer;
	const total = useMemo(
		() => getOrderTotal(order, defaultRoundFactor),
		[order],
	);
	const hasBespoke = useMemo(
		() => order.items.some((i) => i.options?.some((o) => !o.default)),
		[order],
	);
	const [hasCheckedOut, setHasCheckedOut] = useState(false);

	useEffect(() => {
		/**
		 * FIXME order is triggering multiple time unecessarily, adjust how
		 * order is composed so it only fires once there are effective changes
		 */
		if (checkoutStatus === "checkout" && !hasCheckedOut) {
			setHasCheckedOut(true);
			trackBeginCheckout(order);
		}
	}, [
		checkoutStatus,
		order.payment.currency,
		order.items,
		total.gross,
		hasCheckedOut,
	]);

	const { country, state } = order.customer.address;
	const showTax = shouldShowTax(order);
	const shipping = total.shipping[displayCurrency] || 0;
	const tax = getTotalTax(total, country, state)[displayCurrency] || 0;
	const taxRate = tax
		? getTaxRate({ country, state, subtotal: total.subtotal })
		: undefined;
	const isLoading = ["pending", "polling"].includes(checkoutStatus);

	const paymentCurrency = order.payment.currency;
	const discountedGross = subtractAmounts(total.gross, total.discount);

	const requirePayment = !["offline", "voucher"].includes(order.payment.type);
	const showDisclaimer =
		order.payment.type &&
		requirePayment &&
		order.payment.currency !== order.customer.currency;

	const totalLabel = fmtCurrency(
		discountedGross[paymentCurrency] || 0,
		paymentCurrency,
		"code",
	);

	const buttonLabel = requirePayment
		? l10n.getString("cart-summary-button--clicked", { total: totalLabel })
		: l10n.getString("cart-summary-button--clicked--place");

	return (
		<div className="space-y-4">
			<div className="space-y-4 bg-gray-100 p-4">
				<div className=" flex items-end justify-between border-b pb-1">
					<h2 className="ln-subtitle truncate">
						<Localized id="cart-summary-title" />
					</h2>
					{checkoutStatus === "checkout" && (
						<button
							className="flex items-center gap-1 truncate text-sm underline"
							onClick={props.onEdit}
						>
							<ChevronLeftIcon className="h-3 w-3" />
							Edit bag
						</button>
					)}
				</div>
				<ul className="text-sm leading-loose">
					<li className="flex justify-between">
						<span>
							<Localized
								id="cart-summary-items"
								vars={{ n: order.items.length }}
							/>
						</span>
						<Price
							value={total.subtotal[displayCurrency]}
							showZero
						/>
					</li>
					{showTax && (
						<li className="flex justify-between">
							<span>
								<Localized
									id="cart-summary-tax"
									children={<span>Tax and duties</span>}
								/>{" "}
								{taxRate && <small>({taxRate}%)</small>}
							</span>
							<span>
								<Price value={tax} showZero />
							</span>
						</li>
					)}
					<li className="flex justify-between">
						<Localized
							id="cart-summary-shipping"
							children={<span />}
						/>
						<span>
							<Price value={shipping} showZero />
						</span>
					</li>
					{total.discount.gbp > 0 && (
						<li className="flex justify-between">
							<span>Discount</span>
							<span>
								-<Price value={total.discount} showZero />
							</span>
						</li>
					)}
					<li className="flex justify-between border-b border-t py-2 font-medium text-gray-700">
						<span>
							<Localized
								id="cart-summary-total"
								children={<span />}
							/>
							{showDisclaimer && (
								<InfoModal
									title={l10n.getString(
										"cart-summary-currency_disclaimer-title",
										{
											currency: paymentCurrency,
										},
									)}
								>
									<Localized
										id="cart-summary-currency_disclaimer-text1"
										children={<p />}
									/>
									<Localized
										id="cart-summary-currency_disclaimer-text2"
										children={<p />}
									/>
								</InfoModal>
							)}
						</span>
						<span>
							<Price value={discountedGross} showZero />
						</span>
					</li>
				</ul>
				<button
					className={cx(
						"button border-black text-sm font-light tracking-widest",
						testMode && "bg-red-500",
						isLoading && "pointer-events-none",
					)}
					disabled={isLoading}
					onClick={onSubmit}
					children={
						checkoutStatus === "view" ? (
							l10n.getString("cart-checkout-title")
						) : checkoutStatus === "checkout" ? (
							buttonLabel
						) : (
							<div className="flex items-center justify-center">
								<svg
									className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
								>
									<circle
										className="opacity-25"
										cx="12"
										cy="12"
										r="10"
										stroke="currentColor"
										strokeWidth="4"
									></circle>
									<path
										className="opacity-75"
										fill="currentColor"
										d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
									></path>
								</svg>
								<span>
									{l10n.getString(
										"cart-summary-button--loading",
									)}
								</span>
							</div>
						)
					}
				/>
				{hasBespoke && (
					<p className="block text-center text-sm leading-tight">
						<Localized
							id="cart-summary-bespoke"
							elems={{
								icon: <SwatchIcon className="inline h-4 w-4" />,
							}}
							children={<small />}
						/>
					</p>
				)}
			</div>
			{checkoutStatus === "checkout" && requirePayment && (
				<Tab
					open
					icon={GiftIcon}
					title="cart-voucher-title"
					subtitle="cart-voucher-subtitle"
				>
					<CartVoucherRedeem />
				</Tab>
			)}
			<Tab
				icon={ChatBubbleOvalLeftEllipsisIcon}
				title="cart-summary-help-title"
				subtitle="cart-summary-help-subtitle"
				className="space-y-2"
			>
				<p>
					<Localized id="cart-summary-help-text" />
				</p>
				<div>
					<h4 className="font-medium">
						<Localized id="cart-summary-help-customer-service-title" />
					</h4>
					<p>
						<Localized id="cart-summary-help-customer-service-text" />
					</p>
				</div>
				<div>
					<h4 className="mr-1 inline-block font-medium">
						<Localized id="cart-summary-help-tel-title" />
					</h4>
					<a
						href={`tel:${l10n
							.getString("contact-tel-intl-number")
							.replace(/ /g, "")}`}
					>
						<Localized id="contact-tel-intl-number" />
					</a>
				</div>
				<div>
					<a
						className="block underline"
						href={`mailto:${l10n.getString("contact-email-sales")}`}
					>
						<Localized id="cart-summary-help-email-text" />
					</a>
				</div>
			</Tab>
			<Tab
				icon={ShareIcon}
				title="cart-share-title"
				subtitle="cart-share-subtitle"
			>
				<CartShareModal />
			</Tab>
		</div>
	);
};

export default OrderSummary;
