import { VoucherRef, isVoucherCodeValid } from "@launerlondon/shared";
import axios, { AxiosError } from "axios";
import { object } from "dot-object";
import toast from "react-hot-toast";
import { ActionFunction, json, redirect } from "react-router-dom";
import {
	ApiError,
	CacheKey,
	apiRequest,
	cache,
	cachedApiRequest,
	localCache,
} from "./api";
import routes from "./routes";

export const appAction: ActionFunction = async ({ request }) => {
	const form = await request.formData();
	const listViewMode = form.get("listViewMode")?.toString();
	listViewMode && localCache.set("listViewMode", listViewMode);
	return json({ ok: true });
};

export const authAction: ActionFunction = async ({ request }) => {
	const form = await request.formData();
	const email = form.get("email")?.toString();
	const token = form.get("token")?.toString();
	if (request.method === "POST") {
		return toast
			.promise(
				apiRequest("create", "/auth", { email }),
				{
					loading: "validating your request",
					error: (e: ApiError) => e.message,
					success: () => {
						return "Please enter the pin code";
					},
				},
				{ id: "authAction" },
			)
			.then(() => ({ email }));
	}
	if (request.method === "PUT") {
		return toast
			.promise(
				apiRequest<{ expiresAt: number }>("update", "/auth", {
					email,
					token,
				}),
				{
					loading: "validating your request",
					error: (e: ApiError) => e.message,
					success: "Welcome",
				},
				{ id: "authAction" },
			)
			.then(({ expiresAt }) => ({ loggedIn: true, expiresAt }))
			.catch(() => ({ loggedIn: false, email }));
	}
	if (request.method === "DELETE") {
		return toast
			.promise(
				apiRequest("delete", "/auth"),
				{
					loading: "logging you out…",
					error: (e: ApiError) => e.message,
					success: "You are now logged out",
				},
				{ id: "authAction" },
			)
			.then(() => {
				cache.clear();
				return redirect(routes.home);
			});
	}
	return null;
};

export const bookmarksAction: ActionFunction = async ({ request }) => {
	if (request.method === "POST" || request.method === "DELETE") {
		const form = await request.formData();
		const hash = form.get("hash")?.toString() || null;
		const data = {
			sku: form.get("sku")?.toString(),
			hash,
			options: hash
				? JSON.parse(form.get("options")?.toString() || "[]")
				: null,
		};
		return toast
			.promise(
				apiRequest("update", "/user/bookmarks", data),
				{
					loading: "submitting your request…",
					error: (e: ApiError) => e.message,
					success: "Bookmark updated",
				},
				{ id: "bookmarksAction" },
			)
			.then(async () => {
				const cacheKey: CacheKey = "list:/user/bookmarks";
				const bookmarks = await cache.get(cacheKey);
				const bks =
					request.method === "POST"
						? Promise.resolve([data, ...bookmarks])
						: Promise.resolve(
								bookmarks.filter(
									(s: { sku: string; hash?: string }) => {
										return !(
											s.sku === data.sku &&
											s.hash == data.hash
										);
									},
								),
						  );
				cache.set(cacheKey, bks);
				return null;
			});
	}
	return null;
};

export const profileAction: ActionFunction = async ({ request }) => {
	if (request.method !== "PUT") return null;
	const data = Object.fromEntries((await request.formData()) as any);
	const profile = object(data);
	return toast
		.promise(
			apiRequest("update", "/user/profile", profile),
			{
				loading: "submitting data…",
				error: (err: ApiError) => err.message,
				success: "profile saved",
			},
			{ id: "profileAction" },
		)
		.then((value) => {
			cache.set("read:/user/profile", value);
			return null;
		});
};

export const newsletterAction: ActionFunction = async ({ request }) => {
	if (request.method !== "POST") return null;
	const data = await request.formData();
	const email = data.get("email")?.toString();
	const language = data.get("lang")?.toString();
	const countryCode = data.get("country")?.toString();
	const optIn = data.get("optIn")?.toString() === "true";
	const id = { id: "newsletter" };

	if (!email) return toast.error("Missing email field", id);
	if (!language) return toast.error("Missing lang field", id);
	if (!countryCode) return toast.error("Missing country field", id);

	return toast
		.promise(
			axios.post<{ customerId: string }>(
				"/api/mcec?action=createCustomer",
				{
					email,
					language,
					countryCode,
					optIn,
				},
			),
			{
				loading: "Subscribing to our newsletter…",
				error: (err: AxiosError<{ error: string }>) =>
					err.response?.data?.error || err.message,
				success: "Thank you for subscribing",
			},
			id,
		)
		.then((r) => ({ customerId: r.data.customerId }))
		.catch(() => ({}));
};

export const productAction: ActionFunction = async ({ request }) => {
	if (request.method !== "POST") return null;
	const form = await request.formData();
	const options = JSON.parse(form.get("options")?.toString() || "[]");
	return options;
};

export const checkoutAction: ActionFunction = async ({ request }) => {
	const form = await request.formData();
	const action = form.get("action")?.toString();
	const id = action;
	const vouchers: VoucherRef[] = localCache.get("vouchers") || [];
	if (action === "add_voucher") {
		const code = form.get("voucher")?.toString();

		try {
			isVoucherCodeValid(code);
		} catch (e: any) {
			toast.error(e.message, { id });
			return null;
		}

		const voucher = await toast
			.promise(
				apiRequest("read", "/vouchers", { code }),
				{
					error: "Invalid voucher code, please try again",
					loading: "Retrieving voucher information",
					success: "Voucher add successfully",
				},
				{ id },
			)
			.catch(() => null);

		if (voucher && vouchers.some((v) => v.id === voucher.id)) {
			toast.error("Voucher already added", { id });
			return { error: true };
		}

		if (voucher) {
			vouchers.push(voucher);
			localCache.set("vouchers", [...vouchers]);
		}
		return { ok: true };
	}
	if (action === "remove_voucher") {
		const voucherId = form.get("id");
		localCache.set(
			"vouchers",
			vouchers.filter((v) => v.id !== voucherId),
		);
	}
	return null;
};

export const accountOrderViewAction: ActionFunction = async ({ request }) => {
	const data = Object.fromEntries(
		(await request.formData()) as any,
	) as Partial<{
		action: string;
		voucherId: string;
		from: string;
		name: string;
		email: string;
		message: string;
	}>;
	if (data.action === "send_voucher") {
		if (!data.voucherId) {
			throw new Response(null, {
				status: 503,
				statusText: "Missing voucherId",
			});
		}
		return toast
			.promise(
				apiRequest("update", "/vouchers", {
					id: data.voucherId,
					from: data.from,
					name: data.name,
					email: data.email,
					message: data.message,
				}),
				{
					loading: "sending-message",
					error: (e) => e.message,
					success: "message-sent",
				},
				{ id: "voucher" },
			)
			.catch(() => null);
	}
	throw new Response(null, { status: 405, statusText: "Action Not Allowed" });
};

export const voucherBalanceAction: ActionFunction = async ({ request }) => {
	if (request.method !== "POST") {
		throw new Response(null, {
			status: 405,
			statusText: "Method Not Allowed",
		});
	}
	const form = await request.formData();
	const code = form.get("code")?.toString();
	try {
		isVoucherCodeValid(code);
	} catch (e: any) {
		toast.error(e.message, { id: "voucher" });
		return null;
	}
	const voucher = await toast
		.promise<VoucherRef>(
			cachedApiRequest("read", "/vouchers", { code }),
			{
				error: "Invalid Gift Card code, please try again",
				loading: "Retrieving Gift Card information",
				success: "Gift Card balance retrieved successfully",
			},
			{ id: "voucher" },
		)
		.catch(() => null);
	return { voucher };
};
