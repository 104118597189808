import { Listbox } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useLocalization } from "@launerlondon/l10n";
import { Price } from "@launerlondon/shop-components";
import { RootState } from "@launerlondon/shop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useActionData, useParams } from "react-router-dom";
import { CartItemSnapOption } from "@launerlondon/products";

export type Option = { label: string; value: unknown };

type Props = {
	id: string;
	label: string;
	labelSuffix?: JSX.Element;
	defaultValue?: unknown;
	options: Array<Option | false>;
	onChange(v: unknown): number;
};

const SelectInput: React.FC<Props> = (props) => {
	const params = useParams();
	const data = useActionData() as CartItemSnapOption[] | undefined;
	const currency = useSelector((s: RootState) => s.locale.currency);
	const { l10n } = useLocalization();
	const opts = props.options
		.filter((i): i is Option => Boolean(i))
		.map((o) => ({ ...o, label: l10n.getString(o.label) }));
	const dValue = props.defaultValue || opts[0]?.value;
	const [value, setValue] = useState<unknown>(dValue);
	const [price, setPrice] = useState(0);

	useEffect(() => {
		setValue(dValue);
		setPrice(0);
	}, [params.sku, dValue]);

	useEffect(() => {
		if (!props.defaultValue) return;
		const v = opts.find((o) => o.value === props.defaultValue)?.value;
		if (v) setValue(v);
	}, [props.defaultValue]);

	// trigger change on language or currency update
	useEffect(() => setPrice(props.onChange(value)), [currency, l10n]);

	useEffect(() => {
		if (!data || props.defaultValue) return;
		data.forEach((opt) => {
			if (opt.type === props.id) {
				setValue(opt.value);
				props.onChange(opt.value);
			}
		});
	}, [props.defaultValue, data, currency, l10n]);

	const onSelectChange = useCallback(
		(_value: string) => {
			const v = opts.find((o) => o.label === _value)?.value;
			setValue(v);
			setPrice(props.onChange(v));
		},
		[opts],
	);

	return (
		<div className="grid grid-cols-4 items-center">
			<span>
				{l10n.getString(props.label)}
				{props.labelSuffix}
			</span>
			<div className="relative col-span-3 bg-gray-50 ">
				<Listbox onChange={onSelectChange} defaultValue={dValue}>
					<Listbox.Button className="flex w-full items-center gap-2 px-2 py-3">
						<span>
							{opts.find((o) => o.value === value)?.label}
						</span>
						<div className="ml-auto flex h-3 gap-2">
							<Price className="text-xs" value={price} />
							<ChevronRightIcon className="mr-2 h-3 w-3 text-gray-400" />
						</div>
					</Listbox.Button>
					<Listbox.Options
						className={cx(
							"absolute left-0 top-full z-50 mt-2 max-h-60 w-full overflow-auto",
							"rounded-sm bg-white shadow-lg ring-1 ring-gray-100",
							"focus:outline-none",
						)}
					>
						{opts.map((o, i) => (
							<Listbox.Option key={i} value={o.label}>
								{(p) => (
									<span
										className={cx(
											"block w-full cursor-pointer p-3 text-sm",
											"hover:bg-gray-50",
											p.active && "bg-gray-50",
											p.selected && "text-accent",
											p.disabled && "text-gray-200",
										)}
									>
										{o.label}
									</span>
								)}
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Listbox>
			</div>
		</div>
	);
};

export default SelectInput;
