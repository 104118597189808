import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import cx from "classnames";
import { ReactNode } from "react";

type Props = {
	labels: Array<string | undefined | false>;
	children: ReactNode[];
};

const Tabs: React.FC<Props> = (props) => {
	const labels = props.labels.filter(Boolean);
	const children = props.children?.filter(Boolean);

	return (
		<div className="mt-4 leading-relaxed">
			{labels
				.filter((l): l is string => Boolean(l))
				.map((label, idx) => {
					return (
						<Disclosure key={label}>
							{({ open }) => (
								<>
									<Disclosure.Button
										className={cx(
											"flex w-full items-center justify-between",
											"border-t py-4 text-sm",
											"uppercase tracking-widest text-gray-800",
										)}
									>
										{label}
										<ChevronUpIcon
											className={cx(
												"h-4 w-4",
												open && "rotate-180 transform",
											)}
										/>
									</Disclosure.Button>
									<Disclosure.Panel
										className={cx(
											"mb-2",
											!open && "sr-only",
										)}
										static
									>
										{children[idx]}
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					);
				})}
		</div>
	);
};

export default Tabs;
