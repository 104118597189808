import axios from "axios";

type HttpRequest = {
	rawBody: any;
	method: string;
	url: string;
	headers: Record<string, string | string[] | undefined>;
};

async function getRequestDataTOTP(data: Record<string, unknown>) {
	const stringify = await import("fast-json-stable-stringify").then(
		(d) => d.default,
	);
	const { TOTP, Secret } = await import("otpauth");
	const salt = "foobar";
	const secret = Secret.fromUTF8(stringify({ ...data, salt })).base32;
	return new TOTP({
		issuer: "Launer",
		algorithm: "SHA256",
		digits: 9,
		period: 10,
		secret,
	});
}

export const apiRequest = axios.create();

apiRequest.interceptors.request.use(async (config) => {
	const data = config.data || { url: config.url };
	config.headers = config.headers || {};
	config.headers["X-App-Token"] = (await getRequestDataTOTP(data)).generate();
	return config;
});

export async function verifyRequest(req: HttpRequest) {
	const token = req.headers["x-app-token"]?.toString() || "";
	const data =
		req.method === "POST"
			? JSON.parse(req.rawBody.toString())
			: { url: req.url };

	return (await getRequestDataTOTP(data)).validate({ token }) !== null;
}
