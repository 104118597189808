import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useLocalization } from "@launerlondon/l10n";
import { Share } from "@launerlondon/shop-components";
import type { RootState } from "@launerlondon/shop-types";
import axios from "axios";
import QRCode from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getCartUrl } from "../lib/cart";

type Props = {};

const CartShareModal: React.FC<Props> = () => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [rawCartUrl, setRawCartUrl] = useState<string>();
	const [cartUrl, setCartUrl] = useState<string>("");
	const [copied, setCopied] = useState(false);
	const items = useSelector((s: RootState) => s.cart);
	const { l10n } = useLocalization();

	useEffect(() => {
		getCartUrl(items).then(async (link) => {
			if (rawCartUrl === link) return;
			setRawCartUrl(link);
			const r = await axios.post("/api/shortlink", { link });
			setCartUrl(r.data.shortLink);
		});
	}, [items, rawCartUrl]);

	useEffect(() => setCopied(false), [rawCartUrl]);

	return (
		<div className="flex flex-col items-center justify-center text-center">
			<p className="text-sm">{l10n.getString("cart-share-text")}</p>

			<QRCode
				renderAs="svg"
				value={cartUrl}
				level="L"
				size={180}
				className="my-8 block"
			/>
			<div className="flex w-full items-center rounded-sm bg-gray-100 p-2">
				<input
					className="flex-1 text-ellipsis border-0 bg-transparent p-1 text-sm focus:ring-0"
					ref={inputRef}
					defaultValue={cartUrl}
					readOnly
					dir="ltr"
					onClick={(e) => e.currentTarget.select()}
				/>
				<ClipboardDocumentIcon
					className="h-4 w-4 cursor-pointer"
					onClick={() => {
						navigator.clipboard.writeText(
							inputRef.current?.value || "",
						);
						setCopied(true);
					}}
				/>
			</div>
			<small className="text-accent-500 mb-10 block min-h-[20px]">
				{copied && l10n.getString("cart-share-copied")}
			</small>
			<Share url={cartUrl} className="-mt-10 mb-4" />
		</div>
	);
};

export default CartShareModal;
