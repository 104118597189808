import {
	getSwatchOptionLocaleId,
	getSwatchPatternLocaleId,
	useLocalization,
} from "@launerlondon/l10n";
import cx from "classnames";
import Modal from "./Modal";

type Swatch = { id: string; name: string; thumb: string; group: string };
type Group = { name: string; swatches: Swatch[] };

type Props = {
	label: string;
	value?: Swatch;
	items: string[];
	onChange(value: Swatch): void;
	swatches: Swatch[];
	onCancel(): void;
};

type SwatchGroupProps = {
	group: Group;
	available: string[];
	selectedItem: Swatch | undefined;
	onChange(value: Swatch): void;
};

type SwatchItemProps = {
	swatch: Swatch;
	selected: boolean;
	onChange(value: Swatch): void;
};

const SwatchItem: React.FC<SwatchItemProps> = (props) => {
	const { l10n } = useLocalization();
	const { id, name, group } = props.swatch;
	const selectedSwatch = { ...props.swatch, group };
	const localeName = l10n.getString(getSwatchPatternLocaleId(name), {}, name);
	const thumb = props.swatch.thumb.replace(".webp", ".jpeg");
	return (
		<li
			key={id}
			onClick={() => props.onChange(selectedSwatch)}
			title={`${localeName} (${id})`}
			style={{ backgroundImage: `url(${thumb})` }}
			className={cx(
				"h-16 w-16 cursor-pointer rounded bg-contain",
				props.selected && "ring-2 ring-rose-500 ring-offset-2",
			)}
		/>
	);
};

const SwatchGroup: React.FC<SwatchGroupProps> = (props) => {
	const { l10n } = useLocalization();
	const { group, available } = props;
	const filtered = group.swatches.filter((i) =>
		(available || []).includes(i.id),
	);
	if (filtered.length === 0) {
		return null;
	}
	const localeName = l10n.getString(
		getSwatchPatternLocaleId(group.name),
		{},
		group.name,
	);
	return (
		<section key={group.name} className="mb-4">
			<h2 className="ln-subtitle-sans my-4 text-sm text-gray-800">
				{localeName}
			</h2>
			<ul className="grid grid-cols-4 gap-3 md:grid-cols-8 md:gap-4">
				{filtered.map((i) => (
					<SwatchItem
						key={i.id}
						swatch={i}
						selected={props.selectedItem?.id === i.id}
						onChange={props.onChange}
					/>
				))}
			</ul>
		</section>
	);
};

const SwatchPicker: React.FC<Props> = (props) => {
	const { l10n } = useLocalization();

	const swatchGroups = props.swatches.reduce<Group[]>((groups, c) => {
		groups.find((g) => g.name === c.group)?.swatches.push(c) ||
			groups.push({ name: c.group, swatches: [c] });
		return groups;
	}, []);

	return (
		<Modal
			className="m-10 md:max-w-2xl"
			title={l10n.getString("product-popup-swatches-title")}
			subtitle={l10n.getString(
				getSwatchOptionLocaleId(props.label),
				{},
				props.label,
			)}
			onCancel={props.onCancel}
		>
			<div className="-mt-8 divide-y">
				{swatchGroups.map((g) => (
					<SwatchGroup
						key={g.name}
						group={g}
						available={props.items}
						selectedItem={props.value}
						onChange={props.onChange}
					/>
				))}
			</div>
		</Modal>
	);
};

export default SwatchPicker;
