type PostRaw = {
	id: string;
	date: string;
	slug?: string;
	title: string;
	text?: string;
	categories: string[];
	source?: string;
	imageFit?: "cover" | "contain";
	images: string[];
};

export type Post = Omit<PostRaw, "slug" | "date" | "images"> & {
	slug: string;
	date: Date;
	images: Array<{
		thumb: string;
		medium: string;
		large: string;
	}>;
};

const list = import("./data.json")
	.then((d) => d.default as PostRaw[])
	.then<Post[]>((posts) =>
		posts.map<Post>(({ slug, date, images, ...post }) => {
			return {
				...post,
				slug:
					slug ||
					post.title
						.toLowerCase()
						.replace(/\W+/g, (m) => (m.match(/ +/) ? "-" : "")),
				date: new Date(date),
				images: images.map((img) => ({
					thumb: img.replace("1280x1280", "320x320"),
					medium: img.replace("1280x1280", "1280x1280"),
					large: img,
				})),
			};
		}),
	);

export async function getPosts(categories?: string | string[]) {
	const posts = await list;
	const cats = categories
		? Array.isArray(categories)
			? categories
			: [categories]
		: undefined;
	return cats
		? posts.filter((p) => cats.some((c) => p.categories.includes(c)))
		: posts;
}

export async function getPost(id: string) {
	return (await list).find((p) => p.id === id);
}
