import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useLocalization } from "@launerlondon/l10n";
import { Modal } from "@launerlondon/shop-components";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";

type Props = React.HTMLAttributes<HTMLDivElement> & {
	title: string;
	image?: string;
};

const InfoModal: React.FC<Props> = (props) => {
	const { l10n } = useLocalization();
	const [open, setOpen] = useState(false);
	return (
		<>
			<button
				className="ml-1 cursor-help align-middle"
				onClick={() => setOpen(true)}
			>
				<InformationCircleIcon className="h-4 w-4" />
			</button>
			<AnimatePresence>
				{open && (
					<Modal
						title={l10n.getString(props.title)}
						onCancel={() => setOpen(false)}
						className={props.className}
					>
						{props.image && (
							<div
								className="m-auto w-full rounded bg-gray-50 bg-cover pb-[70%]"
								style={{
									backgroundImage: `url(${props.image})`,
								}}
							/>
						)}
						<div className="mt-4 space-y-4 text-xs">
							{props.children}
						</div>
					</Modal>
				)}
			</AnimatePresence>
		</>
	);
};

export default InfoModal;
