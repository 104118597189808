import { MessageForm } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-press.jpg";
import logoUrl from "../assets/press-maverick-logo.png";

const Press: React.FC = () => {
	useMetaTags({ title: "Press office contacts", image: bannerUrl });
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container pb-20">
				<header className="ln-intro-grid">
					<h2 className="font-display">Press office contacts</h2>
					<div>
						<p>
							Please contact the press office for all press
							enquiries:
						</p>
					</div>
				</header>
				<div className="ln-section-grid gap-10">
					<div>
						<h3 className="ln-subtitle">Maverick Publicity</h3>
					</div>
					<div>
						<div className="flex flex-col-reverse gap-10 md:flex-row md:items-center">
							<p className="flex-1">
								Maverick Publicity
								<br />
								Showroom 7<br />
								Albion Buildings
								<br />
								No.1 Back Hill
								<br />
								Clerkenwell
								<br />
								London
								<br />
								EC1R 5EN
								<br />
								UK tel:
								<a
									href="tel:+442074272699"
									rel="noopener noreferrer"
								>
									0207 427 2699
								</a>
								<br />
								International tel:
								<a
									href="tel:+442074272699"
									rel="noopener noreferrer"
								>
									0044 207 427 2699
								</a>
								<br />
								Email:
								<a
									href="mailto:romaine@maverickpublicity.com"
									rel="noopener noreferrer"
								>
									romaine@maverickpublicity.com
								</a>
								<br />
							</p>
							<div className="mt-10 max-w-[150px] text-center md:mt-0 md:max-w-[300px]">
								<img src={logoUrl} />
							</div>
						</div>
						<p className="mb-4">
							Please send us a message using the form below.
						</p>
						<MessageForm
							action="press"
							fields={[
								{ title: "Name", required: true },
								{
									title: "Email",
									type: "email",
									required: true,
								},
								{ title: "Subject" },
								{
									title: "Message",
									type: "textarea",
									required: true,
								},
							]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Press;
