import { Switch, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Localized } from "@launerlondon/l10n";
import { useCookieConsent } from "@launerlondon/shop-hooks";
import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { isDNT } from "../lib/utils";

type Props = {
	show: boolean;
	onCancel?: () => void;
};

const container = document.querySelector<HTMLDivElement>(".extra");

const CookieModal: React.FC<Props> = (props) => {
	const [consent, setConsent] = useCookieConsent();

	const [hidden, setHidden] = useState(!props.show);
	const [cookies, setCookies] = useState<typeof consent>({
		essential: true,
		analytics: !isDNT,
		marketing: !isDNT,
	});

	useEffect(() => {
		if (!consent) return;
		setCookies({ ...consent, essential: true });
	}, [consent]);

	useEffect(() => {
		if (props.show) setHidden(false);
	}, [props.show]);

	useEffect(() => {
		props.onCancel?.call(null);
		function onKeyDown(e: KeyboardEvent) {
			if (e.key === "Escape") setHidden(true);
		}
		addEventListener("keyup", onKeyDown);
		return () => removeEventListener("keyup", onKeyDown);
	}, [hidden]);

	const onCookieChange = useCallback(
		(key: keyof typeof consent, value: boolean) => {
			if (key === "essential") return;
			setCookies((cs) => ({ ...cs, [key]: value }));
		},
		[],
	);

	const saveCookies = useCallback(() => {
		setConsent(cookies);
		setHidden(true);
	}, [cookies]);

	const modal = (
		<Transition show={!hidden} appear={true}>
			<Transition.Child
				className={cx(
					"fixed bottom-0 left-0 right-0 top-0",
					"z-50 bg-gray-600/50",
					"transition-opacity duration-300 ease-linear",
				)}
				enterFrom="opacity-0"
				leaveTo="opacity-0"
			/>
			<Transition.Child
				className={cx(
					"fixed bottom-0 left-0 right-0 z-50",
					"md:left-auto md:right-4 md:top-auto",
					"max-h-screen max-w-lg",
					"mx-auto overflow-auto p-8",
					"text-center font-sans text-xs leading-5",
					"rounded-t bg-white drop-shadow",
					"transition-transform duration-300 ease-in-out",
					"text-[#343030]",
				)}
				enterFrom="translate-y-full"
				leaveTo="translate-y-full"
			>
				<button
					className="absolute right-3 top-3"
					onClick={() => setHidden(true)}
				>
					<XMarkIcon className="h-6 w-6" />
				</button>
				<h2 className="ln-subtitle-sans mb-4">
					<Localized id="cookie-modal-title" />
				</h2>
				<p>
					<Localized id="cookie-modal-description" />
				</p>
				<div>
					{Object.entries(cookies).map(([key, value]) => (
						<Switch.Group
							as="div"
							key={key}
							className="m-auto max-w-sm py-5"
						>
							<div className="flex items-center justify-center gap-4">
								<Switch
									checked={value}
									onChange={(v) =>
										onCookieChange(key as any, v)
									}
									className={cx(
										value
											? "bg-neutral-600"
											: "bg-neutral-300",
										"relative inline-flex flex-shrink-0",
										"h-5 w-9",
										"rounded-full border-2 border-transparent",
										key === "essential" &&
											"cursor-default opacity-50",
										"transition-colors duration-200 ease-in-out",
										"focus:outline-none focus-visible:ring-2",
										"focus-visible:ring-white focus-visible:ring-opacity-75",
									)}
								>
									<span
										aria-hidden="true"
										className={cx(
											"inline-block h-4 w-4",
											"transform rounded-full bg-white shadow-lg ring-0",
											"pointer-events-none",
											"transition duration-200 ease-in-out",
											value
												? "translate-x-4"
												: "translate-x-0",
										)}
									/>
								</Switch>
								<Switch.Label className="-mt-1 font-medium">
									<Localized
										id={`cookie-modal-${key}--title`}
									/>
								</Switch.Label>
							</div>
							<Switch.Description className="mx-auto mt-2 max-w-[320px] leading-5">
								<Localized
									id={`cookie-modal-${key}--description`}
								/>
							</Switch.Description>
						</Switch.Group>
					))}
				</div>
				<button
					className="button button-ghost w-max max-w-none text-xs hover:border-black hover:bg-black hover:text-white"
					onClick={saveCookies}
				>
					<Localized id="cookie-modal-button" />
				</button>
				<a
					className="block text-gray-400 underline"
					href="https://launer.com/terms-conditions-privacy"
					target="_blank"
				>
					<Localized id="cookie-modal-policy" />
				</a>
			</Transition.Child>
		</Transition>
	);

	return container && createPortal(modal, container);
};

export default CookieModal;
