import { crc32 } from "crc";

export function getDynamicLocaleKey(prefix: string, value?: string): string {
	if (value === undefined) {
		return prefix;
	}
	const fmtValue = value.toLowerCase().replace(/\.$/, "").replace(/-/g, " ");
	const hash = crc32(fmtValue).toString(16).padStart(8, "0");
	return `${prefix}-${hash}`;
}

export function getMenuLocaleId(title?: string): string {
	return `menu-${title?.toLowerCase().replace(/ +/g, "-") || ""}`;
}

export function getGoldCornersValueLocaleId(value?: number): string {
	const slug = value === 1 ? "one" : value === 2 ? "two" : "no";
	return `product-selector-gold_corners-option--${slug}`;
}
export function getWalletSizeValueLocaleId(value?: string): string {
	return `product-selector-wallet_size-option--${value}`;
}
export function getShieldingValueLocaleId(value?: true): string {
	return `product-selector-shielding-option--${value ? "yes" : "no"}`;
}
export function getStrapFittingValueLocaleId(value?: string): string {
	return `product-selector-strap_fitting-option--${value}`;
}
export function getStrapStyleValueLocaleId(value?: string): string {
	return `product-selector-strap_style-option--${value}`;
}
export function getEmblemValueLocaleId(value?: string): string {
	return `product-selector-emblem-option--${value}`;
}

export function getSwatchOptionLocaleId(title?: string): string {
	const slug = title
		?.toLowerCase()
		.replace(/ ?colour ?/, "")
		.replace(/ +/g, "_");
	return `product-selector-swatch-${slug}`;
}

export function getSwatchPatternLocaleId(title?: string): string {
	const slug = title?.toLowerCase().replace(/ +/g, "_");
	return `swatch-${slug}`;
}

export function getSwatchGroupLabel(group: string) {
	return {
		C: "Calf leather",
		P: "Patent leather",
		S: "Suede",
		ELY: "Special Leather",
		EO: "Ostrich",
		CR: "Croc Effect",
		MC: "Multi-colour",
		MT: "Metallic",
		ST: "Satin",
		SG: "Scotch Grain",
		T: "Tartan",
	}[group] as string;
}

export function getColorKeys(colors: string) {
	return colors.split(" / ").map((color) => ({
		color: getDynamicLocaleKey("product-color", color),
		fallback: color,
	}));
}
