import { XMarkIcon } from "@heroicons/react/24/outline";
import cx from "classnames";
import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

type Props = React.HTMLAttributes<HTMLDivElement> & {
	title?: string;
	subtitle?: string;
	onCancel?: () => void;
};

const container = document.querySelector<HTMLDivElement>(".modal");

const Modal: React.FC<Props> = ({
	title,
	subtitle,
	onCancel,
	children,
	className,
}) => {
	const background = useRef(null);

	const bgAnimation: Variants = {
		visible: { opacity: 1 },
		hidden: { opacity: 0 },
	};
	const modalAnimation: Variants = {
		visible: { y: 0 },
		hidden: { y: 20 },
	};

	useEffect(() => {
		const html = document.documentElement;
		html.style.overflowY = "hidden";
		const onModuleClick = ({ target }: MouseEvent): void => {
			return Object.is(target, background.current)
				? onCancel?.call(null)
				: undefined;
		};
		const onModuleKeyPress = ({ key }: KeyboardEvent): void => {
			return key === "Escape" ? onCancel?.call(null) : undefined;
		};
		addEventListener("click", onModuleClick);
		addEventListener("keyup", onModuleKeyPress);
		return () => {
			html.style.overflowY = "auto";
			removeEventListener("click", onModuleClick);
			removeEventListener("keyup", onModuleKeyPress);
		};
	}, [onCancel]);

	const modal = (
		<motion.div
			key="bg"
			className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-black/50 font-sans"
			ref={background}
			variants={bgAnimation}
			animate="visible"
			initial="hidden"
			exit="hidden"
		>
			<motion.div
				key="modal"
				className={cx(
					"relative flex w-full max-w-md flex-col items-center overflow-auto rounded bg-white p-4 text-center",
					className,
				)}
				variants={modalAnimation}
				transition={{ type: "tween" }}
				animate="visible"
				initial="hidden"
				exit="hidden"
			>
				<XMarkIcon
					className="absolute right-2 top-2 h-4 w-4 cursor-pointer"
					onClick={onCancel}
				/>
				{title && (
					<h2 className="ln-subtitle mb-2 mt-4 text-center">
						{title}
					</h2>
				)}
				{subtitle && (
					<h3 className="ln-subtitle-sans m-1 mb-10 text-center">
						{subtitle}
					</h3>
				)}
				{children}
			</motion.div>
		</motion.div>
	);

	return container && createPortal(modal, container);
};

export default Modal;
