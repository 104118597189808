import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { findDeep } from "./utils";

export type MenuItem = {
	title: string;
	url?: string;
	children?: MenuItem[];
};

const list = import("../../assets/menu.json").then<MenuItem[]>(
	(d) => d.default,
);

export async function getMenuItems() {
	return list;
}

export function useMenu() {
	const [items, setItems] = useState<MenuItem[]>([]);
	useEffect(() => void getMenuItems().then(setItems), []);
	return items;
}

export function useMenuParentCategories() {
	const { pathname } = useLocation();

	const [items, setItems] = useState<MenuItem[]>([]);
	useEffect(() => {
		if (!pathname) return;
		getMenuParentCategories(pathname).then(setItems);
	}, [pathname]);
	return items;
}

export async function getMenuParentCategories(url: string) {
	let parents: MenuItem[] = [];
	const items = await getMenuItems();
	const item = findDeep(items, "url", url, "children", parents);
	/*
	 * Manually select parents for specific paths
	 */
	if (item && url === "/cc/the-hatherton-collection") {
		parents = items.filter((p) => p.title === "Collections");
	}
	return item ? [...parents, item] : [];
}
