import { useLocalization } from "@launerlondon/l10n";
import { CartItemSnapOption } from "@launerlondon/products";
import { Price } from "@launerlondon/shop-components";
import { RootState } from "@launerlondon/shop-types";
import {
	KeyboardEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { useActionData, useParams } from "react-router-dom";

type Props = {
	label: string;
	labelSuffix?: JSX.Element;
	placeholder?: string;
	limit: number;
	onChange(v?: string): number;
};

const LetteringInput: React.FC<Props> = ({
	label,
	labelSuffix,
	placeholder,
	limit,
	onChange,
}) => {
	const params = useParams();
	const data = useActionData() as CartItemSnapOption[] | undefined;
	const currency = useSelector((s: RootState) => s.locale.currency);
	const { l10n } = useLocalization();
	const [value, setValue] = useState<string>();
	const [price, setPrice] = useState(0);
	const refInput = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setValue(undefined);
		setPrice(0);
		if (refInput.current) refInput.current.value = "";
	}, [params.sku]);

	// trigger change on currency update
	useEffect(() => setPrice(onChange(value)), [currency]);

	useEffect(() => {
		if (!data) return;
		data.forEach((opts) => {
			if (opts.type === "lettering") {
				setValue(opts.value);
				onChange(opts.value);
			}
		});
	}, [data, currency]);

	const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
		(e) => {
			// only allow latin characters
			if (!/[0-9a-zA-z. ]/.test(e.key)) e.preventDefault();
		},
		[],
	);

	const onInput = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		// remove non-latin characters
		const v = e.currentTarget.value.replace(/(?![0-9a-zA-Z. ])./g, "");
		e.currentTarget.value = v;
		setValue(v || undefined);
		setPrice(onChange(v || undefined));
	}, []);

	return (
		<div className="grid grid-cols-4 items-center">
			<span>
				{l10n.getString(label)}
				{labelSuffix}
			</span>
			<label className="col-span-3 flex cursor-text items-center gap-2 bg-gray-50">
				<input
					ref={refInput}
					className="w-full min-w-0 border-0 bg-transparent px-2 py-3 focus:ring-0"
					maxLength={limit}
					defaultValue={value || ""}
					placeholder={l10n.getString(placeholder || "")}
					inputMode="text"
					onKeyDown={onKeyDown}
					onInput={onInput}
				/>
				<div className="ml-auto flex items-center gap-2">
					<Price className="text-xs" value={price} />
					<span className="mr-4 w-10 text-right text-xs text-gray-400">
						{(value?.length || 0).toString().padStart(2, "0")}/
						{limit}
					</span>
				</div>
			</label>
		</div>
	);
};

export default LetteringInput;
