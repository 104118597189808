import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

const CONTENT_CLASS = twJoin("space-y-8 text-left lg:px-8");

export default function CollectionChristmasGuide() {
	useMetaTags({
		title: "Luxury Christmas Gift Ideas | Gift Guide | Launer London ",
		description:
			"Explore luxury Christmas gift ideas with Launer. Find a timelessly elegent gift for him or her. Complimentary worldwide shipping on all bags.",
		image: "/images/collections/christmas-guide-banner.webp",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				image="/images/collections/christmas-guide-banner.webp"
				className="bg-[#C45D5D] text-sm text-white lg:text-right"
			>
				<h1 className="ln-title flex flex-col">
					<span className="text-4xl">GIVE FESTIVE JOY</span>
				</h1>
			</Row.WithImage>
			<Row.WithImage
				image={<h2>Launer’s Christmas Gift Ideas</h2>}
				containerClassName="py-14"
				imageClassName="ln-subtitle text-left aspect-auto bg-transparent container max-w-xl max-lg:container lg:px-8"
				contentClassName="text-left max-w-full place-self-start p-0 text-lg"
			>
				<p>
					Make this festive season magical with our handpicked
					selection of memorable Christmas gift ideas for the loved
					ones in your life. You can now discover our collection of
					beautiful handmade leather items that will make them feel
					extra special and destined to become treasured keepsakes.
					Exquisite designs and noble craftsmanship define the Launer
					legacy, evident when you visit our Christmas shop – the
					ultimate destination for fine leather objects that make the
					ideal{" "}
					<Link to="/c/christmas-gifts" className="underline">
						luxury Christmas present
					</Link>
					.
				</p>
				<p>
					Browse our distinguished gift guide for stunning present
					inspiration with our recommendations of peerless
					nominations. Every piece is delivered in an embossed
					presentation box with protective dust cover, all ready to
					place by the tree.​
				</p>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/christmas-guide-christmas-gifts.webp"
				imageLink="/p/3121-02/natalia-royal-stewart-tartan"
				className="bg-[#F4F2F0] text-[#343030]"
				contentClassName={CONTENT_CLASS}
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Christmas Gifts</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						Discover the most desirable and the most coveted
						handmade leather goods steeped in British heritage and
						tradition. For him, for her; at Launer we excel in the
						extraordinary that are crafted with passion and many
						decades of expertise.
					</p>
					<p>
						They will delight when presented with a{" "}
						<Link to="/c/handbags">handbag</Link> or{" "}
						<Link to="/c/weekend-bags">weekend bag</Link>,
						representing investment pieces that in time will become
						treasured mementos. Our festive offering provides
						flawless bags, impeccable wallets, refined purses,{" "}
						<Link to="/cc/the-hatherton-collection">
							luxury luggage
						</Link>{" "}
						and peerless accessories that elevate.
					</p>
				</div>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/christmas-guide-gifts-for-her.webp"
				imageLink="/p/3097-20/judi-burgundy-croc-effect"
				className="bg-[#F4F2F0] text-[#343030]"
				contentClassName={CONTENT_CLASS}
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts for Her</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						If you are seeking Christmas gift ideas for her, she
						will love a signature{" "}
						<Link to="/c/top-handle-bags">top handle bag</Link>{" "}
						radiating timeless appeal that comes with a Royal seal
						of approval;  from the retro-chic of{" "}
						<Link to="/s/traviata">The Traviata</Link> and Judi to
						the cross body luxe of the Elizabeth, elegant{" "}
						<Link to="/c/day-bags">day bags</Link> such as the Anna
						or Olympia are stunning options in vibrant colours. Mini
						bags make chic statements and perfect for events and
						special occasions, take a look at the Iris, Viola and
						Natalia for in-demand items. Consider the{" "}
						<Link to="/c/clutch-bags">clutch bag</Link>, much
						favoured by Queen Camilla, it is the essence of elegance
						and stunning in smooth black leather or glossy black
						patent, the Tosca, Bella and also Grace with its
						embellished crystal rope emblem is divine. She will love
						these styles all made by hand by a single skilled
						artisan in our atelier in Walsall, making them the
						perfect luxury{" "}
						<Link to="/c/gifts-for-her">gift for her</Link>.
					</p>
					<p>
						A handmade <Link to="/c/purses">purse</Link>, one that
						can accommodate cards, cash and notes and look. At
						Launer their beauty is evident as is their
						functionality. We recommend the Tulip, Ivy or Azalea as
						they easily fit into our handbags. Personal items can be
						protected with style and elegance with spectacle cases,
						smartphone cases, laptop folios or tablet folios, all
						demonstrate a thoughtful choice.
					</p>
				</div>
			</Row.WithImage>
			<Products skus={["3050-34", "3050-23", "2910-05", "103-03"]} />
			<Row.WithImage
				reverse
				image="/images/collections/christmas-guide-travel-gifts.webp"
				imageLink="/p/3141-03/hatherton-weekend-bag-caviar-black"
				className="bg-[#F4F2F0] text-[#343030]"
				contentClassName={CONTENT_CLASS}
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Travel Lovers</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						If you are looking for Christmas present ideas for the{" "}
						<Link to="/c/travel-gifts">travel lover</Link> in your
						life it has to be The Hatherton Collection, the most
						luxurious <Link to="/c/luggage">luggage</Link> for the
						voyager who is devoted to luxury. Indulge them with His
						and Hers <Link to="/c/cabin-bags">cabin bags</Link> -The
						Grand Tour, Weekend or Overnight bag – superior for
						travel and stylish for staycations. The Overnight bag is
						a stay to day bag with its compact form.
					</p>
					<p>
						Turning now to those essential trip items – the everyday{" "}
						<Link to="/c/wash-bags">wash bag</Link> is now an
						elevated affair; crafted to perfection to ensure
						toiletries can be stored within its roomy interior. She
						will swoon for any of our{" "}
						<Link to="/c/luggage-accessories">
							luggage accessories
						</Link>
						: Passport covers, embossed luggage tags, engraved
						padlock and cover. He will regard a Hatherton wallet a
						prized present designed to fit cards and notes and fits
						easily into a jacket or trouser pocket with a sleek
						appearance.
					</p>
				</div>
			</Row.WithImage>
			<Products skus={["3141-01", "3140-03", "3145-02", "3136-04"]} />
			<Row.WithImage
				image="/images/collections/christmas-guide-gifts-for-him.webp"
				imageLink="/p/805-34/eight-credit-card-wallet-black-sage-gold-plated-corners"
				className="bg-[#F4F2F0] text-[#343030]"
				contentClassName={CONTENT_CLASS}
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts For Him</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						Impress him with one of our distinguished, signature{" "}
						<Link to="/c/wallets">leather wallets</Link>. Available
						in an array of styles and sizes to suit all occasions –
						perfect for work or for leisure. Designed to be the
						essence of noble style and often referred to as the
						‘finest men’s wallets’, our collection captures a true
						sense of understated luxury. Each piece is handcrafted
						in England and features our signature turned-edge
						leather work, making them the ideal{" "}
						<Link to="/c/gifts-for-him">gift for him</Link>. Their
						requirements will be met expertly with bi-fold styles,
						coin purses, classic{" "}
						<Link to="/c/pocket-wallets">pocket wallets</Link> and{" "}
						<Link to="/c/breast-pocket-wallets">breast pocket</Link>{" "}
						options. Take a look at{" "}
						<Link to="/c/men-card-holders">cardholders</Link> which
						are all slimline yet can accommodate multiple cards,
						they are every bit as stylish and exude a timeless
						sensibility.
					</p>
					<p>
						For more Christmas gift ideas for him, delve into our
						cabinet of{" "}
						<Link to="/c/accessories">small leather goods</Link>{" "}
						–from a leather tray for personal items to an immaculate
						folio case from Hatherton or how about a passport cover?
						Key fobs and key cases are a great present idea and
						especially with imbued with the Launer hallmarks.
					</p>
				</div>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/christmas-guide-hatherton.webp"
				imageLink="/p/3136-03/hatherton-wash-bag-oxford-blue"
				className="bg-[#F4F2F0]/50 text-[#343030]"
				contentClassName={CONTENT_CLASS}
				imageClassName="bg-white lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">The Hatherton Collection</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						The pinnacle of luxury travel has arrived with the{" "}
						<Link to="/cc/the-hatherton-collection">
							Hatherton Collection
						</Link>
						, the most refined luggage range for sophisticated
						adventurers. The Grand Tour bag has been engineered with
						immaculate precision for those trips abroad and weekends
						away, he may also like the smaller Weekend size and for
						those overnight occasions, it has to be the Overnight
						bag – all crafted in our new scotch grain leather.
					</p>
					<p>
						For those with on-the-go office needs, he will welcome
						our Hatherton laptop case, sleek yet robust to store and
						protect the trusted laptop.
					</p>
				</div>
			</Row.WithImage>
			<Link
				to="/p/3051-07/lucia-gold"
				className={twJoin(
					"block",
					"max-lg:hidden",
					"aspect-square max-h-[60vh] w-full",
					"bg-gray-200 bg-cover bg-center lg:bg-fixed",
					"bg-[url('/images/collections/christmas-guide-divider.webp')]",
				)}
			/>
			<Row.WithImage
				image="/images/collections/christmas-guide-gifts-under-250.webp"
				imageLink="/p/244-02/smart-phone-case-ebony-black"
				className="bg-white text-[#343030]"
				contentClassName={CONTENT_CLASS}
				imageClassName="bg-white lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gifts under £250</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						The joy of Launer’s British heritage is accessible to
						all pockets with a host of deluxe Christmas gift ideas
						for <Link to="/c/gifts-under-250">under £250</Link>. An
						engraved key fob is ideal for him or for her or a luxury
						leather tray lined in sumptuous suede that is perfect
						for those precious personal items. A protective case for
						a smartphone is an inspired choice or explore our range
						of card holders, practicality in the most stylish form.
						Safe storage of house and car keys is a must and so our
						six key hook case is the answer, an elegant, discreet
						carrier.
					</p>
				</div>
			</Row.WithImage>
			<Row.WithImage
				reverse
				image="/images/collections/christmas-guide-luxury-stocking-fillers.webp"
				imageLink="/p/3119-06/piccolo-platinum-jubilee-tartan"
				className="bg-[#F4F2F0] text-[#343030]"
				contentClassName={CONTENT_CLASS}
				imageClassName="bg-white lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Luxury Stocking Fillers</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						An essential part of the Christmas tradition has to be
						the giving of a{" "}
						<Link to="/c/luxury-stocking-fillers">
							stocking filler
						</Link>{" "}
						, a little something to say you care. We have the most
						desirable and inspired stocking filler ideas that evoke
						timeless luxury- as they say the best things come in
						small packages, Spoil her with a micro handbag, Piccolo,
						that is simply irresistible. Appreciations will be in
						order with an engraved key fob, spectacle case, cosmetic
						mirror or coloured attachable handbag strap, Another
						great idea has to be a mini purse, take a look at Poppy
						or Daisy
					</p>
					<p>
						An embossed luggage tag is a must-have or a slim
						cardholder and for more inspiration take a look at an
						engraved padlock and leather cover.
					</p>
					<p>
						No matter how small, they all bear the Launer lauded
						tropes and enjoy the same excellent craftsmanship so
						they are true objects of desire.
					</p>
				</div>
			</Row.WithImage>
			<Products skus={["3119-02", "685-15", "670-02", "874-10"]} />
			<Row.WithImage
				reverse
				image="/images/collections/christmas-guide-tech-gifts.webp"
				imageLink="/p/3146-02/hatherton-tablet-folio-case-oxford-blue"
				className="bg-[#F4F2F0] text-[#343030]"
				contentClassName={CONTENT_CLASS}
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Tech Lovers</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						The tech lover in your life will delight when you gift a
						product with them in mind. The laptop folio case is
						something to behold, expertly crafted with retractable
						handles or the tablet folio case, both from The
						Hatherton Collection so refined but also robust in
						stunning, enduring scotch grain. Protect your phone in
						style with the smartphone case or the stylish tablet
						cover.
					</p>
					<p>
						There really is no better way to show you truly care by
						ensuring they protect their digital assets in a timeless
						manner, making the luxury items in our{" "}
						<Link to="/c/tech-gifts">tech gift</Link> offering the
						most thoughtful present ideas.
					</p>
				</div>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/christmas-guide-personalised-gifts.webp"
				className="bg-[#F4F2F0]/50 text-[#343030]"
				contentClassName={CONTENT_CLASS}
				imageClassName="bg-white lg:m-10"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">
						Personalisation <span className="block" />
						and Customisations
					</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						Launer has a renowned{" "}
						<Link to="/customise-at-launer">
							customisation service
						</Link>{" "}
						that is both inspiring and comprehensive. This enables
						all of our items to be customised in any colour desired
						from our Colour Portfolio – there are over 65 on offer
						as well as two leather finishes and 9 interior suede
						variations. There is no additional charge for the
						service when selecting alternative colours for our
						luxury leather goods.
					</p>
					<p>
						Personalisation is an incredible way to show how special
						your giftee is – add their initials, name or even a
						short love note or phrase which is applied by hand in a
						choice of foils. You can also add other personal touches
						such as gold plated tip corners to wallets,
						faraday®shielding, and our house emblem in a range of
						finishes for the perfect personalised gift idea.
					</p>
				</div>
			</Row.WithImage>
			<Row.WithImage
				image="/images/collections/christmas-guide-gift-card.webp"
				imageLink="/p/0001-01/gift-card"
				className="bg-white text-[#343030]"
				contentClassName={CONTENT_CLASS}
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Gift Cards</h2>
				</header>
				<div className="my-10 space-y-4 [&_a]:underline">
					<p>
						An essential present idea has to be our{" "}
						<Link to="/p/0001-01/gift-card">digital gift card</Link>
						. It shows how much you care as it enables the recipient
						to choose from the extensive collection Launer has to
						offer. You select the amount and they can then browse at
						their leisure. They might like a Hatherton weekend bag
						or a passport cover; a signature handbag such as The
						Bellini or The Encore or our renowned wallets. The
						choice for gift giving some British luxury is varied,
						your gift card will open the door to immaculate leather
						goods.
					</p>
				</div>
			</Row.WithImage>
		</div>
	);
}
