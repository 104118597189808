import { voucherFormat } from "@launerlondon/shared";
import { useEffect, useRef } from "react";
import { Form, useActionData, useNavigation } from "react-router-dom";

const CartVoucherRedeem: React.FC = () => {
	const inputRef = useRef<HTMLInputElement>(null);
	const busy = useNavigation().state === "submitting";
	const data = useActionData() as { error?: true; ok?: true } | undefined;

	useEffect(() => {
		if (!inputRef.current) return;
		if (data?.ok) inputRef.current.value = "";
		if (data?.error) inputRef.current.select();
	}, [data]);

	return (
		<Form className="flex" method="post">
			<label>
				<span className="text-sm font-medium">Voucher code</span>
				<input type="hidden" name="action" value="add_voucher" />
				<input
					ref={inputRef}
					type="text"
					name="voucher"
					required
					placeholder={voucherFormat.replace(/[A0]/g, "0")}
					className="block h-12 w-full font-mono text-sm font-medium"
				/>
			</label>
			<button
				className="h-12 self-end bg-black p-2 text-sm text-white disabled:opacity-50"
				disabled={busy}
			>
				Redeem
			</button>
		</Form>
	);
};

export default CartVoucherRedeem;
