import { Popover } from "@headlessui/react";
import {
	EnvelopeIcon,
	EyeIcon,
	EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { VoucherSnap, getVoucherExpiryDate } from "@launerlondon/shared";
import { AccountOrderViewLoaderData } from "@launerlondon/shop-router";
import { Fragment, Suspense, useState } from "react";
import { Await, Form, useLoaderData } from "react-router-dom";

type Props = { data: VoucherSnap };

const OrderVoucherDetails: React.FC<Props> = ({ data }) => {
	const { code, id } = data;
	const expiry = getVoucherExpiryDate(data);
	const [hidden, setHidden] = useState(true);
	const { profile } = useLoaderData() as AccountOrderViewLoaderData;
	return (
		<div className="flex gap-10">
			{code ? (
				<div className="mono relative -ml-2 flex items-center gap-4 rounded bg-gray-100 p-2 pb-3 pt-7">
					<div className="absolute inset-x-2 top-2 flex justify-between text-xs">
						<span>Voucher code</span>
						<span className="text-[10px]">
							exp.{expiry.toLocaleDateString()}
						</span>
					</div>
					<input
						type="text"
						readOnly
						className="w-full max-w-[110px] border-none bg-transparent p-0 font-mono text-xs font-bold focus:ring-0"
						onClick={(e) => e.currentTarget.select()}
						value={hidden ? code.replace(/\w/g, "*") : code}
					/>
					<button
						title={
							hidden
								? "View voucher code"
								: "Conceal voucher code"
						}
						onClick={() => setHidden((v) => !v)}
					>
						{hidden ? (
							<EyeIcon className="h-4 w-4" />
						) : (
							<EyeSlashIcon className="h-4 w-4" />
						)}
					</button>
					<Popover as={Fragment}>
						<Popover.Button title="Share Gift card">
							<EnvelopeIcon className="h-4 w-4" />
						</Popover.Button>
						<Popover.Panel className="fixed inset-0 z-40 flex items-center justify-center">
							{({ close }) => (
								<>
									<Popover.Overlay className="absolute inset-0 bg-black/20" />
									<div className="relative m-4 max-h-[90vh] w-full max-w-md overflow-auto rounded bg-white p-4 shadow-xl">
										<Form
											className="grid grid-cols-1 gap-6"
											method="post"
											onSubmit={() =>
												setTimeout(close, 1000)
											}
										>
											<h3 className="ln-subtitle text-center">
												Send Gift Card to someone
												special
											</h3>
											<input
												type="hidden"
												name="action"
												defaultValue="send_voucher"
											/>
											<input
												type="hidden"
												name="voucherId"
												defaultValue={id}
											/>
											<label className="ln-field">
												<span>Sender Name</span>
												<Suspense>
													<Await resolve={profile}>
														{(
															profile: Awaited<
																AccountOrderViewLoaderData["profile"]
															>,
														) => (
															<input
																name="from"
																defaultValue={
																	profile.name
																}
															/>
														)}
													</Await>
												</Suspense>
											</label>
											<label className="ln-field">
												<span>Recipient Name</span>
												<input name="name" />
											</label>
											<label className="ln-field">
												<span>Recipient Email*</span>
												<input
													type="email"
													name="email"
													required
												/>
											</label>
											<label className="ln-field">
												<span>Message</span>
												<textarea name="message" />
											</label>
											<button className="button">
												Send Gift Message
											</button>
										</Form>
									</div>
								</>
							)}
						</Popover.Panel>
					</Popover>
				</div>
			) : (
				<span>Processing…</span>
			)}
		</div>
	);
};

export default OrderVoucherDetails;
