import {
	Amount,
	defaultRoundFactor as RF,
	getItemPrice,
	getTaxRate,
	hasIncludedVatPricing,
	roundValue,
} from "@launerlondon/shared";
import type { ProductPrice } from "../types";

type Args = {
	productPrices: ProductPrice[];
	displayRate: ProductPrice;
	shippingRate: ProductPrice;
	billingRate: ProductPrice;
	extraRates: ProductPrice[];
	shippingCountry: string;
	digitalGood: boolean;
};

const lc = (k: string) => k.toLowerCase() as keyof Amount;

export default function ({
	productPrices,
	displayRate,
	billingRate,
	shippingRate,
	extraRates,
	shippingCountry,
	digitalGood,
}: Args) {
	const priceMap = productPrices.reduce<Amount>(
		// review currency keys mismatch in lower/uppercase
		(p, c) => ((p[lc(c.currency)] = c.value), p),
		{ gbp: 0 },
	);

	const taxRate =
		!digitalGood && hasIncludedVatPricing(shippingCountry)
			? 1 +
			  getTaxRate({ country: shippingCountry, subtotal: priceMap }) / 100
			: 1;

	const originalPrice = priceMap.gbp || 0;
	const shippingPrice = priceMap[lc(shippingRate.currency)] || 0;

	const price = [
		displayRate,
		shippingRate,
		billingRate,
		...extraRates,
		{ currency: "gbp", value: 1 },
	].reduce(
		(p, c) => {
			const targetRate = c.value;
			const productPrice =
				shippingPrice && !digitalGood
					? roundValue(shippingPrice * taxRate, RF) /
					  shippingRate.value
					: roundValue(originalPrice * taxRate, RF);

			p[lc(c.currency)] = productPrice * targetRate;
			return p;
		},
		{ base: priceMap.base } as Amount,
	);

	return getItemPrice({ price }, RF);
}
