import type { Field } from ".";

type TextArea = Field<React.InputHTMLAttributes<HTMLTextAreaElement>>;
const TextArea: TextArea = ({ onChange, value, ...props }) => {
	return (
		<label className="ln-field">
			<span>{props.title}</span>
			<textarea
				{...props}
				defaultValue={value}
				onBlur={(e) => {
					if (e.target.checkValidity() && e.target.value !== value) {
						onChange?.call(undefined, e.target.value);
					}
				}}
			/>
		</label>
	);
};

export default TextArea;
