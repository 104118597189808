import { Transition } from "@headlessui/react";
import threeSixty from "@mladenilic/threesixty.js";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";

type Props = {
	className?: string;
	image: string;
	count: number;
	tip?: string;
	loop?: boolean;
};

const ThreeSixty: React.FC<Props> = ({ loop, ...props }) => {
	const divRef = useRef<HTMLDivElement>(null);
	const [instance, setInstance] = useState<any>();
	const [images, setImages] = useState<string[]>();
	const [imageRefs, setImageRefs] = useState<HTMLImageElement[]>([]);
	const [showHint, setShowHint] = useState(true);
	const isVisible = useIsVisible(divRef);

	useEffect(() => {
		const arr: string[] = Array(props.count).fill(props.image);
		const promises = arr.map(
			(v, i) =>
				new Promise<string>((resolve) => {
					const n = (i + 1).toString().padStart(2, "0");
					const src = v.replace("{i}", n);
					const img = new Image();
					img.onload = img.onerror = () => {
						resolve(src);
						img.parentElement?.removeChild(img);
					};
					img.style.display = "none";
					img.src = src;
					img.alt = `360-spin ${n}/${arr.length}`;
					document.body.append(img);
					setImageRefs((refs) => [...refs, img]);
				}),
		);
		Promise.all(promises).then((a) => setImages(a));
	}, [props.image]);

	useEffect(() => {
		if (!divRef.current || !images) return;
		const inst = new threeSixty(divRef.current, {
			image: images,
			width: "100%",
			height: "100%",
			keys: false,
			speed: 100,
			inverted: true,
		});
		loop ? inst.play() : inst.play(false, 1);
		setInstance(inst);
		return () => {
			imageRefs.forEach((ref) => ref.parentElement?.removeChild(ref));
			instance?.destroy();
		};
	}, [divRef, images]);

	useEffect(() => {
		isVisible
			? loop
				? instance?.play()
				: instance?.play(false, 1)
			: instance?.stop();
	}, [isVisible]);

	return (
		<div className="relative h-full">
			<Transition
				className={cx(
					"absolute inset-0",
					"flex items-center justify-center",
					"transition-opacity",
					"pointer-events-none",
				)}
				show={showHint}
				appear
				enterFrom="opacity-0"
				leaveTo="opacity-0"
			>
				<div className="rounded-full bg-white/90 p-2 font-sans text-sm normal-case tracking-normal">
					{props.tip || "Swipe to rotate / Double-click to zoom"}
				</div>
			</Transition>
			<div
				ref={divRef}
				className={cx(
					"h-full w-full cursor-grab select-none bg-contain bg-center bg-no-repeat",
					props.className,
				)}
				onPointerDown={() => setShowHint(false)}
			/>
		</div>
	);
};

export default ThreeSixty;
