export enum MarketingStatus {
	Allowed = -1,
	NoChoice = 0,
	RefusedProductList = 1,
	RefusedAddToCart = 2,
}

export type Meta = {
	ma: MarketingStatus;
	customerId?: string;
	cartId?: string;
	pendingItems?: {
		lineId: string;
		productId: string;
		productPrice: number;
	}[];
};
