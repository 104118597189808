import { Instagram, Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export default function CollectionRangeRoverHouse() {
	useMetaTags({
		title: "Range Rover House",
		description:
			"Launer was a guest at an incredible event in California hosted by Range Rover in celebration of The Pebble Beach Concours d' Elegance™, the flagship of Monterey Car Week.",
		image: "/images/collections/range-rover-house-banner.jpg",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				reverse
				image="/images/collections/range-rover-house-banner.jpg"
				className="relative bg-black text-white"
				containerClassName="xl:grid-cols-1"
				contentClassName="relative grid place-items-center gap-6 lg:py-24 lg:px-0"
				imageClassName="lg:absolute lg:h-full lg:right-0"
			>
				<img
					className="w-28"
					src="/images/collections/range-rover-house-royal.png"
				/>
				<h1 className="gris-rows-2 grid">
					<span className="ln-title text-4xl">A CELEBRATION</span>
					<span className="ln-subtitle">
						OF BRITISH HERITAGE LEATHER GOODS
					</span>
				</h1>
				<h2 className="font-sans text-sm uppercase tracking-widest">
					RANGE ROVER HOUSE PEBBLE BEACH, CALIFORNIA
				</h2>
			</Row.WithImage>
			<Row.Single
				className="border-b"
				contentClassName="max-w-3xl lg:py-20"
			>
				<p className="text-lg">
					Launer was a guest at an incredible event in California
					hosted by Range Rover in celebration of The Pebble Beach
					Concours d' Elegance™, the flagship of Monterey Car Week.
				</p>
				<p>
					Three iconic bags, The Traviata, Eleanor and Madelaine, all
					beloved styles of Her Majesty Queen Elizabeth II were in
					attendance at the exclusive Range Rover House.  Invited
					guests were treated to a host of carefully curated
					experiences and inside access to the world of Range Rover
					including the US debut of The Vehicles of Her Majesty Queen
					Elizabeth II.
				</p>
			</Row.Single>
			<Products
				title="EXHIBITED PRODUCTS"
				className="container"
				skus={["3050-01", "3138-01", "3137-01"]}
			/>
			<Row.WithImage
				className="bg-[#E0DADA] text-[#343030]"
				reverse
				image="/images/collections/range-rover-house-traviata.jpg"
				imageClassName="lg:m-14"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">Traviata</h2>
				<p>
					The Traviata is a legend, acclaimed for its structured
					silhouette, exquisite handcrafted detail and signature
					twisted rope emblem. This handbag icon was first introduced
					in 1989, achieving International status as the bag of choice
					for Her Majesty Queen Elizabeth II and is the style most
					associated with Queen Elizabeth and her remarkable style. It
					forms part of the distinguished Legacy Collection. This
					impeccable design transcends time remaining stylish and
					contemporary, the hallmark of a true investment piece. It
					has been featured in films, illustrations, on cakes and in
					other guises resulting in its recognition as a style icon.
				</p>
				<Link
					to="/s/traviata"
					className="inline-block text-sm uppercase tracking-widest"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#F8F8F8] text-[#343030]"
				image="/images/collections/range-rover-house-eleanor.jpg"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">Eleanor</h2>
				<p>
					An exceptional double handle day bag with a poignant
					association, it is the exact model Her Majesty Queen
					Elizabeth II carried at the wedding of the Prince and
					Princess of Wales in 2011, in stunning bone white, a
					departure from Queen Elizabeth’s usual black.  Eleanor, not
					previously available, is a suitable homage to Queen
					Elizabeth II and features a gold-plated framed interior in a
					serene shade of Bone White and our signature twisted rope
					emblem. It forms part of The Elizabeth Collection.
				</p>
				<Link
					to="/p/3138-01"
					className="inline-block text-sm uppercase tracking-widest"
				>
					Explore
				</Link>
			</Row.WithImage>
			<Row.WithImage
				reverse
				className="bg-[#E0DADA] text-[#343030]"
				image="/images/collections/range-rover-house-madelaine.jpg"
				imageClassName="lg:m-14"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">Madelaine</h2>
				<p>
					The restrained elegance of Madelaine in polished black
					patent ensures a style that endures with a memorable
					association. This model was a beloved handbag of Her Majesty
					Queen Elizabeth II who often carried it to functions and
					official engagements and has, for the first time become
					available as a commemorative piece. With its sleek
					appearance and distinctive front flap, Madelaine is a
					treasured memento. Madelaine had a star role in the Platinum
					Jubilee Celebrations, it was illustrated above Buckingham
					Palace in the form of drones beamed around the world.
				</p>
				<Link
					to="/p/3137-01"
					className="inline-block text-sm uppercase tracking-widest"
				>
					Explore
				</Link>
			</Row.WithImage>
			<div className={twJoin("grid gap-1", "grid-cols-2 md:grid-cols-6")}>
				<div
					className={twJoin(
						"md:col-span-2",
						"h-screen w-full",
						"max-h-[30vh] md:max-h-[60vh]",
						"bg-neutral-200 bg-cover",
						"bg-[url('/images/collections/range-rover-house-mosaic-1.jpg')]",
					)}
				/>
				<div
					className={twJoin(
						"md:col-span-4",
						"h-screen w-full",
						"max-h-[30vh] md:max-h-[60vh]",
						"bg-neutral-200 bg-cover",
						"bg-[url('/images/collections/range-rover-house-mosaic-2.jpg')]",
					)}
				/>
				<div
					className={twJoin(
						"md:col-span-4",
						"h-screen w-full",
						"max-h-[30vh] md:max-h-[60vh]",
						"bg-neutral-200 bg-cover",
						"bg-[url('/images/collections/range-rover-house-mosaic-3.jpg')]",
					)}
				/>
				<div
					className={twJoin(
						"md:col-span-2",
						"h-screen w-full",
						"max-h-[30vh] md:max-h-[60vh]",
						"bg-neutral-200 bg-cover",
						"bg-[url('/images/collections/range-rover-house-mosaic-4.jpg')]",
					)}
				/>
			</div>
			<Products
				title="Other Styles Queen Elizabeth Carried"
				className="container"
				skus={["3098-01", "3099-02", "3046-01", "3050-09"]}
			/>
			<Products
				title="LEGACY BAGS"
				className="container"
				skus={["3097-18", "2914-14", "3127-03", "3011-04"]}
			/>
			<Row.WithImage
				reverse
				className="bg-[#343030] text-[#EBE7E7]"
				image="/images/collections/range-rover-house-legacy.jpg"
				contentClassName="space-y-14"
			>
				<h2 className="ln-subtitle">EXPLORE THE LEGACY COLLECTION</h2>
				<p>
					At Launer we believe in slow fashion. Our Legacy Collection
					represents our best selling designs that were created more
					than three decades ago and remain as relevant and popular
					today. An impeccable collection of bags that radiate and
					reinvent with colour to become investment purchases.
				</p>
				<Link
					to="/cc/legacy-collection"
					className="inline-block text-sm uppercase tracking-widest"
				>
					FIND OUT MORE
				</Link>
			</Row.WithImage>
			<Row.WithImage
				className="bg-[#908E82] text-[#EBE7E7]"
				contentClassName="space-y-12"
				imageClassName="lg:m-14"
				image="/images/collections/range-rover-house-craftsmanship.jpg"
			>
				<h2 className="ln-subtitle">A Tradition of Handcrafting</h2>
				<div className="space-y-6">
					<p>
						To own a Launer item is to own a leather product that
						has been part of a remarkable manufacturing journey with
						passion and dedication at the heart of the process.
					</p>
					<p>
						Every style is handmade by a single skilled artisan from
						start to finish, a process that requires traditional
						leather craft expertise.
					</p>
				</div>
				<Link
					to="/craftsmanship"
					className="inline-block text-sm uppercase tracking-widest"
				>
					FIND OUT MORE
				</Link>
			</Row.WithImage>
			<Instagram className="mb-5 mt-10" />
		</div>
	);
}
