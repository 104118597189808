import stylesheet, { colors } from "../styles";

const Spot: React.FC = (props) => <div css={styles.spot} {...props} />;

const ProductItemPlaceholder: React.FC = () => (
	<div className="container mt-20 flex flex-col justify-between md:flex-row">
		<div css={styles.column}>
			<Spot css={{ maxWidth: 400 }} />
			<div css={styles.gallery}>
				<Spot css={{ height: 450 }} />
				<div css={styles.flexRow}>
					<Spot css={{ width: 60, height: 60 }} />
					<Spot css={{ width: 60, height: 60 }} />
					<Spot css={{ width: 60, height: 60 }} />
					<Spot css={{ width: 60, height: 60 }} />
					<Spot css={{ width: 60, height: 60 }} />
					<Spot css={{ width: 60, height: 60 }} />
				</div>
			</div>
		</div>
		<div css={styles.column}>
			<Spot css={{ maxWidth: 300 }} />
			<Spot />
			<Spot css={{ maxWidth: 100, height: 40, marginTop: 40 }} />
			<Spot css={{ maxWidth: 200, marginTop: 40 }} />
			<Spot css={{ height: 50, marginTop: 25 }} />
			<Spot css={{ height: 50 }} />
			<Spot css={{ height: 50 }} />
			<Spot css={{ height: 50 }} />
			<Spot css={{ maxWidth: 150, height: 50, marginTop: 40 }} />
			<div css={[styles.flexRow, { marginTop: 40 }]}>
				<Spot css={{ width: "30%" }} />
				<Spot css={{ width: "30%" }} />
				<Spot css={{ width: "30%" }} />
			</div>
			<Spot />
			<Spot css={{ width: "90%" }} />
			<Spot />
			<Spot css={{ width: "30%" }} />
		</div>
	</div>
);

const styles = stylesheet({
	flexRow: {
		display: "flex",
		justifyContent: "space-between",
	},
	column: {
		flex: "0 1 calc(50% - 1em)",
	},
	spot: {
		height: 30,
		marginBottom: 10,
		borderRadius: 10,
		backgroundColor: colors.lightGray,
	},
	gallery: {
		position: "sticky",
		top: 130,
	},
});

export default ProductItemPlaceholder;
