import useHook, { MetaTagsConfig } from "react-metatags-hook";

type Props = {
	/** Adds '– Launer London' after title (default:true) */
	suffix?: false;
	title?: string;
	description?: string;
	image?: string;
	path?: string;
};

function cleanup<T extends Object>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}

function fmtPageMeta(p: Props): MetaTagsConfig {
	const base = `https://${import.meta.env.VITE_HOSTNAME}`;
	const { description, image } = p;
	const imageUrl = image
		? image.startsWith("http") || image.startsWith("/@fs")
			? image
			: new URL(image, base).href
		: undefined;
	const title = p.suffix === false ? p.title : `${p.title} | Launer London`;
	const url = new URL(p.path || location.pathname, base).href;
	const head: MetaTagsConfig = {
		title,
		description,
		metas: [
			{ name: "url", content: url },
			{ itemprop: "name", content: title },
			{ itemprop: "description", content: description },
			{ itemprop: "image", content: imageUrl },
		].filter((m) => m.content),
		links: [{ rel: "canonical", href: url }],
		openGraph: cleanup({
			type: "article",
			url,
			title,
			description,
			image: imageUrl,
		}),
		twitter: cleanup({ title, description, image: imageUrl }),
	};

	return head;
}

export function useMetaTags(config: Props, dependsOn?: any[]) {
	return useHook(fmtPageMeta(config), dependsOn);
}
