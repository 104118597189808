import { useLocalization } from "@fluent/react";
import React, { Children, cloneElement, isValidElement, useMemo } from "react";
import { getColorKeys } from "../utils";

type Props = React.PropsWithChildren<{
	colors?: string;
}>;

const LocalizedColors: React.FC<Props> = ({ colors, ...props }) => {
	const { l10n } = useLocalization();
	const lColors = useMemo(() => {
		if (colors === undefined) {
			return "";
		}
		return getColorKeys(colors)
			.map(({ color, fallback }) => l10n.getString(color, {}, fallback))
			.join(" / ");
	}, [colors, l10n]);
	const child = Children.toArray(props.children)[0];
	if (isValidElement(child)) {
		return cloneElement(child, {}, lColors);
	}
	return <>{lColors}</>;
};

export default LocalizedColors;
