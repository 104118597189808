import { Switch } from "@headlessui/react";
import { useProductListParams } from "@launerlondon/shop-hooks";
import { AppLoaderData, routes } from "@launerlondon/shop-router";
import { useFetcher, useNavigate, useRouteLoaderData } from "react-router-dom";
import { twJoin, twMerge } from "tailwind-merge";

type Props = {
	className?: string;
};

const ListViewControl: React.FC<Props> = (props) => {
	const { listViewMode } = useRouteLoaderData(routes.home) as AppLoaderData;
	const params = useProductListParams();
	const navigate = useNavigate();
	const fetcher = useFetcher();
	return (
		<div className={twMerge("flex items-center gap-4", props.className)}>
			{params.search === undefined && (
				<Switch.Group
					as="div"
					className="m-auto max-w-sm rounded-full bg-gray-50 p-2"
				>
					<div className="flex items-center justify-center gap-4">
						<Switch.Label className="whitespace-nowrap text-xs">
							Group by style
						</Switch.Label>
						<Switch
							checked={params.byStyle}
							onChange={(v) =>
								navigate(
									v
										? `/c/${params.slug}-by-style`
										: `/c/${params.slug}`,
								)
							}
							className={twJoin(
								params.byStyle
									? "bg-neutral-600"
									: "bg-neutral-300",
								"relative inline-flex flex-shrink-0",
								"h-4 w-8",
								"rounded-full border-2 border-transparent",
								"transition-colors duration-200 ease-in-out",
								"focus:outline-none focus-visible:ring-2",
								"focus-visible:ring-white focus-visible:ring-opacity-75",
							)}
						>
							<span
								aria-hidden="true"
								className={twJoin(
									"inline-block h-3 w-3",
									"transform rounded-full bg-white shadow-lg ring-0",
									"pointer-events-none",
									"transition duration-200 ease-in-out",
									params.byStyle
										? "translate-x-4"
										: "translate-x-0",
								)}
							/>
						</Switch>
					</div>
				</Switch.Group>
			)}
			{[
				{
					title: "Minimal view",
					value: "loose",
					path: "M6 10v6H0v-6h6Zm10 0v6h-6v-6h6ZM6 0v6H0V0h6Zm10 0v6h-6V0h6Z",
				},
				{
					title: "Expanded view",
					value: "tight",
					path: "M4 12v4H0v-4h4Zm6 0v4H6v-4h4Zm6 0v4h-4v-4h4ZM4 6v4H0V6h4Zm6 0v4H6V6h4Zm6 0v4h-4V6h4ZM4 0v4H0V0h4Zm6 0v4H6V0h4Zm6 0v4h-4V0h4Z",
				},
			].map((b) => (
				<button
					key={b.title}
					title={b.title}
					onClick={() => {
						fetcher.submit(
							{ listViewMode: b.value },
							{ action: routes.home, method: "post" },
						);
					}}
					className={twJoin(
						"h-4 w-4 active:fill-gray-500",
						b.value === listViewMode
							? "fill-gray-800"
							: "fill-gray-300",
					)}
				>
					<svg viewBox="0 0 16 16">
						<path d={b.path} />
					</svg>
				</button>
			))}
		</div>
	);
};

export default ListViewControl;
