import { UserIcon } from "@heroicons/react/24/outline";
import { isLoggedIn, routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ModalSignIn from "./ModalSignIn";

type Props = {
	className?: string;
};

const AccountNavIcon: React.FC<Props> = (props) => {
	const loggedIn = isLoggedIn();
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	return (
		<>
			<Link
				className={cx("relative", props.className)}
				to={routes.accountOrderList}
				rel="nofollow"
				onClick={(e) => {
					if (loggedIn) return;
					e.preventDefault();
					setShowModal(true);
				}}
			>
				<UserIcon className="h-5 w-5" />
			</Link>
			<ModalSignIn
				show={showModal}
				onCancel={() => setShowModal(false)}
				onSuccess={() => {
					navigate(routes.accountOrderList);
					setShowModal(false);
				}}
			/>
		</>
	);
};

export default AccountNavIcon;
