import { RelatedPages, Video } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import figure1Url from "../assets/craftsmanship-figure-01.jpg";
import figure2Url from "../assets/craftsmanship-figure-02.jpg";
import figure3Url from "../assets/craftsmanship-figure-03.jpg";
import figure4Url from "../assets/craftsmanship-figure-04.jpg";
import related2Url from "../assets/related-heritage.jpg";
import related3Url from "../assets/related-responsibility.jpg";
import related1Url from "../assets/related-royalwarrant.jpg";

const Craftsmanship: React.FC = () => {
	useMetaTags({
		title: "Craftsmanship",
		description:
			"Launer is recognised and appreciated the world over for its outstanding leather goods, representing understated, refined luxury at its very best.",
		image: figure4Url,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: true,
						children: (
							<Video
								videoId="231833322"
								className="pointer-events-none h-full"
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2>Craftsmanship</h2>
					<p>
						Launer is recognised and appreciated the world over for
						its outstanding leather goods, representing understated,
						refined luxury at its very best. A symbol of prestige
						with an unprecedented degree of loving workmanship has
						gone into each and every bag, purse and wallet.
					</p>
				</header>
				<figure className="my-10 grid gap-8">
					<figcaption className="ln-section-grid md:col-span-4">
						<div>
							<p>
								To own a Launer item is to own a leather product
								that has been part of a remarkable manufacturing
								journey with passion and dedication at the heart
								of the process from start to finish.
							</p>
							<p>
								Every style is handmade, a process that requires
								skilled investment; one handbag alone can take
								up to eight hours on average to make.
							</p>
						</div>
					</figcaption>
					<img className="" src={figure1Url} />
					<img className="" src={figure2Url} />
					<img className="" src={figure3Url} />
					<img className="" src={figure4Url} />
				</figure>
				<div className="ln-section-grid my-20">
					<div>
						<h3 className="ln-subtitle">
							Beginning with the leather
						</h3>
						<p>
							Using only the very finest skins of calf, suede and
							exotics –lizard, ostrich and crocodile. The leathers
							are carefully cut and split, only exceptional pieces
							are used and any imperfections discarded. Linings
							are from the softest, refined goat suede. Colours
							are extensive and reflect the luxury of the brand
							with hues truly flattering to different skin tones.
							Each part is then cut and separately assembled
							before it is crafted into a finished item. It is the
							sole duty of one expert craftsperson to compose the
							different components into an item. Each and every
							product has turned edges, a superior and
							unprecedented finish that is labour intensive, this
							is a part of the Launer distinctive handwriting and
							a traditionally British method.
						</p>
						<p>
							The distinctive ‘twisted rope’ emblem, synonymous
							with the brand is constructed in Italy in brass and
							finished by gold plating.
						</p>
						<figure>
							<Video
								videoId="231833322"
								className="h-[192px] rounded bg-gray-50 md:h-[432px]"
							/>
							<figcaption className="ln-figcaption">
								<p>
									It is the sole duty of one expert
									craftsperson to compose the different
									components into an item.
								</p>
							</figcaption>
						</figure>
					</div>
				</div>
				<div className="ln-section-grid">
					<div></div>
					<div>
						<h3 className="ln-subtitle">Quality control</h3>
						<p>
							To own a Launer design is to have an item of
							outstanding quality and superior workmanship. Each
							and every piece that leaves the factory, has been
							meticulously inspected against a rigorous criteria
							at each stage of the manufacturing process.
						</p>
					</div>
				</div>
			</div>
			<RelatedPages
				pages={[
					{
						title: "Royal Warrant",
						link: "/royal-warrant",
						image: related1Url,
					},
					{
						title: "Heritage",
						link: "/heritage-story",
						image: related2Url,
					},
					{
						title: "Sustainability",
						link: "/sustainability",
						image: related3Url,
					},
				]}
			/>
		</div>
	);
};

export default Craftsmanship;
