import { CubeIcon, PlayIcon, SwatchIcon } from "@heroicons/react/24/outline";
import type { CartItemSnapOption, ProductImage } from "@launerlondon/products";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import Swipe from "react-easy-swipe";
import supportsWebP from "supports-webp";
import { twMerge } from "tailwind-merge";
import { ThreeSixty, Video } from ".";
import ProductPreview from "./ProductPreview";

type Props = {
	images: ProductImage[];
	imageClassName?: string;
	options?: CartItemSnapOption[];
	className?: string;
	sku?: string;
	defaultImage?: ProductImage;
};

const Gallery: React.FC<Props> = (props) => {
	const options = props.options || [];
	const [images, setImages] = useState<ProductImage[]>([]);
	const [selected, setSelected] = useState<ProductImage | undefined>();
	const [zoom, setZoom] = useState(false);
	const selectedIndex = images.findIndex((i) => Object.is(i, selected)) + 1;

	// load preview only if webp is supported
	useEffect(() => {
		supportsWebP.then((yes) => {
			setImages(
				yes
					? props.images
					: props.images.filter((i) => !i.thumb.endsWith(".preview")),
			);
			setSelected(props.images[0]);
		});
	}, [props.images]);

	useEffect(() => {
		if (!props.defaultImage) return;
		// wait for layout to prevent 360 malfunction on production
		requestAnimationFrame(() => setSelected(props.defaultImage));
	}, [images, props.defaultImage]);

	useEffect(() => {
		if (options.length === 0) return;
		const needsPreview = options.some(
			(o) =>
				(o.type === "swatch" ||
					o.type === "strap_fitting" ||
					o.type === "gold_corners") &&
				o.meta.previewId &&
				o.default !== true,
		);
		const preview = images.find((i) => i.thumb.endsWith("preview"));
		if (needsPreview && preview !== undefined) setSelected(preview);
	}, [images, options]);

	if (!selected) return null;

	const isPreview = selected.thumb.endsWith("preview");
	const isVideo = selected.thumb.endsWith("video");
	const is360 = selected.thumb.endsWith("360");
	const thumbs = images.filter((i) => !i.thumb.endsWith("preview"));

	const preview =
		props.sku && isPreview ? (
			<motion.div
				layout
				transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
				className={cx(
					"relative block h-full w-full cursor-zoom-in",
					zoom && "cursor-zoom-out",
				)}
				children={<ProductPreview sku={props.sku} options={options} />}
			/>
		) : isVideo ? (
			<motion.div
				layout
				transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
				className="pointer-events-none block h-full w-full"
				children={
					<Video
						className="block h-full w-full"
						videoId={selected.thumb.replace(".video", "")}
					/>
				}
			/>
		) : is360 ? (
			<motion.div
				layout
				transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
				className="h-full w-full"
				children={
					<ThreeSixty
						className="rounded bg-white"
						image={`https://assets.launer.com/images/360/${
							selected.thumb.split(".")[0]
						}/{i}_640x640.jpeg`}
						count={36}
					/>
				}
			/>
		) : (
			<motion.img
				layout
				transition={{ type: "tween", duration: 0.3, ease: "easeOut" }}
				className={twMerge(
					"mix-blend-multiply",
					"block max-h-full cursor-zoom-in",
					zoom && "h-auto w-full md:h-full md:w-auto",
					props.imageClassName,
				)}
				src={(zoom ? selected.large : selected.medium)
					?.replace(".webp", ".jpeg")
					.replace("_00_", "_01_")}
			/>
		);

	return (
		<div
			className={cx(
				"relative box-border md:mb-2 md:mt-2",
				!zoom && "md:sticky md:top-32",
				props.className,
			)}
		>
			<Swipe
				className="relative overflow-hidden rounded-lg bg-gray-50 pb-[85%]"
				tolerance={100}
				onSwipeRight={() => {
					const next = images[selectedIndex - 2];
					!is360 && !zoom && next && setSelected(next);
				}}
				onSwipeLeft={() => {
					const next = images[selectedIndex];
					!is360 && !zoom && next && setSelected(next);
				}}
			>
				<AnimatePresence>
					<motion.div
						key={selectedIndex}
						className={cx(
							zoom
								? "fixed z-40 cursor-zoom-out"
								: "absolute z-10",
							"inset-0",
							"flex flex-col items-center justify-center",
							"bg-gray-50",
						)}
						initial={{ opacity: 0, x: -50 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0 }}
						transition={{
							type: "tween",
							duration: 0.3,
							ease: "easeOut",
						}}
						onClick={() =>
							isVideo || is360 ? null : setZoom((z) => !z)
						}
						onDoubleClick={() => is360 && setZoom((z) => !z)}
						children={preview}
					/>
				</AnimatePresence>
			</Swipe>
			<div className="mt-4 flex justify-center gap-3 lg:hidden">
				{thumbs.map((img, idx) => (
					<span
						key={idx}
						className={cx(
							"h-2 w-2 cursor-pointer rounded-full",
							img === selected ? "bg-gray-400" : "bg-gray-200",
						)}
						onClick={() => setSelected(img)}
					/>
				))}
			</div>
			<div className="mt-2 flex hidden gap-2 overflow-auto pb-2 lg:flex">
				{thumbs.map((img, index) => (
					<span
						key={index}
						className={cx(
							"flex items-center justify-center",
							"h-14 w-full max-w-[60px] rounded-sm border",
							"bg-gray-50 bg-cover bg-center",
							"cursor-pointer",
							selectedIndex === index + 1 && "border-gray-500",
						)}
						style={{
							backgroundImage:
								img.thumb.endsWith("jpg") ||
								img.thumb.endsWith("jpeg") ||
								img.thumb.endsWith("png") ||
								img.thumb.endsWith("webp")
									? `url(${img.thumb
											.replace(".webp", ".jpeg")
											.replace("_00_", "_01_")})`
									: "",
						}}
						onClick={() => setSelected(img)}
					>
						{img.thumb.endsWith("video") && (
							<PlayIcon className="h-8 w-8" strokeWidth={1} />
						)}
						{img.thumb.endsWith("360") && (
							<CubeIcon className="h-8 w-8" strokeWidth={1} />
						)}
						{img.thumb.endsWith("preview") && (
							<SwatchIcon className="h-8 w-8" strokeWidth={1} />
						)}
					</span>
				))}
			</div>
		</div>
	);
};

export default Gallery;
