import { ProductsProps } from "@launerlondon/shop-collections";
import { useProducts } from "@launerlondon/shop-hooks";
import { twJoin, twMerge } from "tailwind-merge";
import ProductListItem from "./ProductListItem";

type Props = ProductsProps & { className?: string };

export default function Products(props: Props) {
	const products = useProducts(props.skus);
	return (
		<div className={twMerge("mx-auto text-center", props.className)}>
			{props.title && (
				<h3 className="ln-subtitle pt-14">{props.title}</h3>
			)}
			<div
				className={twJoin(
					"grid grid-cols-2 md:flex",
					"py-4 md:py-10",
					"gap-x-4 gap-y-10 ",
				)}
			>
				{products.map((p, idx) => (
					<ProductListItem
						key={p?.sku || idx}
						product={p}
						url={props.links?.[idx]}
						className="md:w-0 md:flex-1"
						imageQuality={
							props.skus.length <= 3 ? "medium" : undefined
						}
						imageContainerClassName="bg-transparent"
					/>
				))}
			</div>
		</div>
	);
}
