import { HeartIcon } from "@heroicons/react/24/outline";
import { AppLoaderData, routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { Suspense } from "react";
import { Await, Link, useRouteLoaderData } from "react-router-dom";

const BookmarksNavIcon: React.FC = () => {
	const { bookmarks, loggedIn } = useRouteLoaderData(
		routes.home,
	) as AppLoaderData;
	if (!loggedIn) return null;
	return (
		<Link className="relative" to={routes.accountBookmarks}>
			<HeartIcon className="h-5 w-5" />
			<Suspense>
				<Await resolve={bookmarks}>
					{(items) => (
						<div
							className={cx(
								items.length === 0 && "hidden",
								"absolute bottom-0 right-0 h-2 w-2",
								"rounded-full bg-red-500",
							)}
						/>
					)}
				</Await>
			</Suspense>
		</Link>
	);
};

export default BookmarksNavIcon;
