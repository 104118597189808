import { ChevronRightIcon } from "@heroicons/react/24/outline";
import type { RootState } from "@launerlondon/shop-types";
import cx from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detectLocale } from "../redux/actions";
import LocaleSelectorModal from "./LocaleSelectorModal";

type Props = { className?: string };

const LocaleSelector: React.FC<Props> = ({ className }) => {
	const [showModal, setShowModal] = useState(false);
	const locale = useSelector((s: RootState) => s.locale);
	const dispatch = useDispatch();
	const sign = useMemo(() => {
		return (
			new Intl.NumberFormat(undefined, {
				style: "currency",
				currency: locale.currency,
			})
				.formatToParts?.()
				.find((p) => p.type === "currency")?.value || locale.currency
		);
	}, [locale.currency]);

	useEffect(() => void dispatch<any>(detectLocale), [detectLocale]);

	return (
		<>
			<div
				onClick={() => setShowModal(true)}
				className={cx(
					"relative",
					"flex items-center justify-center gap-1",
					"h-[20px] min-w-[20px] p-2",
					"text-center text-xs",
					"border-inset rounded-full border-[1.5px] border-current",
					"cursor-pointer leading-none",
					className,
				)}
			>
				<span className="h-2.5">{sign}</span>
				<ChevronRightIcon className="h-2.5 w-2" strokeWidth={4} />
				<span className="h-2.5">{locale.country}</span>
			</div>
			<LocaleSelectorModal
				visible={showModal}
				onCancel={() => setShowModal(false)}
			/>
		</>
	);
};

export default LocaleSelector;
