import { countries, postcodes, states } from "@launerlondon/shared";
import { ProfileLoaderData, routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { Suspense, useState } from "react";
import { Await, Form, useAsyncValue, useLoaderData } from "react-router-dom";

type FieldProps = {
	className?: string;
	name: string;
	defaultValue?: string;
	type?: string;
	title?: string;
	disabled?: boolean;
	required?: boolean;
};

const Field: React.FC<FieldProps> = (props) => {
	return (
		<label className={cx("ln-field", props.className)}>
			<span>{props.title}</span>
			<input
				className="disabled:cursor-not-allowed disabled:opacity-50"
				disabled={props.disabled}
				required={props.required}
				name={props.name}
				type={props.type || "text"}
				defaultValue={props.defaultValue}
			/>
		</label>
	);
};

const ProfileForm: React.FC = () => {
	const profile = useAsyncValue() as Awaited<ProfileLoaderData["profile"]>;
	const [country, setCountry] = useState(profile.address?.country);
	const countryStates = states.find((e) => e.country === country)?.states;
	const showPostcode = country && !postcodes.disabled.includes(country);
	return (
		<Form className="max-w-2xl" method="put" action={routes.accountProfile}>
			<div className="grid gap-4 lg:grid-cols-2">
				<Field
					name="name"
					title="Full name"
					defaultValue={profile.name}
				/>
				<Field
					name="emails.0"
					type="email"
					title="Email address"
					disabled
					defaultValue={profile.emails?.[0]}
				/>
				<Field
					name="phone"
					type="tel"
					title="Phone number"
					defaultValue={profile.phone}
				/>
				<Field
					name="address.line1"
					title="Address"
					defaultValue={profile.address?.line1}
				/>
				<Field
					name="address.city"
					title="City"
					defaultValue={profile.address?.city}
					className={cx(!showPostcode && "col-span-2")}
				/>
				{showPostcode && (
					<Field
						name="address.postal_code"
						title="Postcode"
						defaultValue={profile.address?.postal_code}
					/>
				)}
				<label
					className={cx("ln-field", !countryStates && "col-span-2")}
				>
					<span>Country</span>
					<select
						name="address.country"
						value={country}
						onChange={(e) => setCountry(e.currentTarget.value)}
					>
						{countries.map((c) => (
							<option key={c.value} value={c.value}>
								{c.title}
							</option>
						))}
					</select>
				</label>
				{countryStates && (
					<label className="ln-field">
						<span>State</span>
						<select
							name="address.state"
							defaultValue={profile.address?.state}
							// force re-render on defaultValue change
							key={profile.address?.state}
						>
							{countryStates.map((c) => (
								<option key={c.value} value={c.value}>
									{c.title}
								</option>
							))}
						</select>
					</label>
				)}
			</div>

			<button className="button mx-0 w-full max-w-[100px]">
				Save details
			</button>
		</Form>
	);
};

const AccountProfile: React.FC = () => {
	const data = useLoaderData() as ProfileLoaderData;

	return (
		<Suspense fallback="loading">
			<Await resolve={data.profile}>
				<ProfileForm />
			</Await>
		</Suspense>
	);
};

export default AccountProfile;
