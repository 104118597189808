import { LinkIcon } from "@heroicons/react/24/outline";
import { Localized } from "@launerlondon/l10n";
import {
	MetaProps,
	BannerProps,
	FeatureProps,
	HighlightProps,
	ImagesProps,
	IntroProps,
	PagePart,
	LinksProps,
} from "@launerlondon/shop-collections";
import {
	Products,
	RelatedPages,
	SmartLink,
	Video,
} from "@launerlondon/shop-components";
import { useCollection, useMetaTags } from "@launerlondon/shop-hooks";
import cx from "classnames";
import { NavLink, Navigate, useParams } from "react-router-dom";
import { ParallaxBanner } from "react-scroll-parallax";

const Banner: React.FC<BannerProps> = (props) => {
	const message = props.message && (
		<div className="absolute inset-x-0 z-10 bg-[#343030]/60 p-2 text-center text-xs font-light uppercase leading-relaxed tracking-widest text-white">
			{props.message}
		</div>
	);

	if (props.parallax) {
		return (
			<div>
				{message}
				<ParallaxBanner
					layers={[
						{
							speed: 10,
							expanded: true,
							children: (
								<div
									className="ln-banner-image"
									style={{
										backgroundImage: `url(${props.image})`,
										backgroundPosition:
											props.position || "center",
									}}
								/>
							),
						},
					]}
					className="ln-banner lg:h-[800px]"
				/>
			</div>
		);
	}
	return (
		<div className="ln-banner h-auto max-h-fit">
			{message}
			{props.imageMobile ? (
				<>
					<div
						className="bg-cover bg-no-repeat pb-[120%] md:hidden"
						style={{
							backgroundImage: `url(${props.imageMobile})`,
							backgroundPosition: props.position || "center",
						}}
					/>
					<div
						className="bg-cover bg-no-repeat pb-[55%] max-md:hidden"
						style={{
							backgroundImage: `url(${props.image})`,
							backgroundPosition: props.position || "center",
						}}
					/>
				</>
			) : (
				props.image && (
					<div
						className="bg-cover bg-no-repeat pb-[55%]"
						style={{
							backgroundImage: `url(${props.image})`,
							backgroundPosition: props.position || "center",
						}}
					/>
				)
			)}
			{props.videoId && (
				<Video videoId={props.videoId} className="aspect-video" />
			)}
		</div>
	);
};
const Intro: React.FC<IntroProps> = ({ title, text, textLink }) => {
	return (
		<header className="container py-20 text-center">
			<h2 className="font-display text-lg uppercase tracking-widest">
				{title}
			</h2>
			{text && (
				<div className="m-auto mt-10 max-w-2xl leading-relaxed">
					<Localized
						id={text}
						elems={{
							a: (
								<SmartLink
									className="underline"
									to={textLink || ""}
								/>
							),
						}}
						children={<>{text}</>}
					/>
				</div>
			)}
		</header>
	);
};
const Feature: React.FC<FeatureProps> = (props) => {
	const isVideo = props.image.includes("vimeo.com");
	return (
		<div
			className={cx(
				"bg-[#F1F1F1] text-center md:flex",
				props.reverse && "flex-row-reverse",
			)}
			style={{ backgroundColor: props.bgColor, color: props.textColor }}
		>
			<div
				className={cx(
					"relative flex flex-col justify-center gap-5",
					props.twoThirds ? "basis-2/3" : "flex-1",
				)}
			>
				<div className="p-10">
					<div className="mx-auto font-light">
						<h3 className="font-display text-xl uppercase tracking-widest">
							{props.title}
						</h3>
						<h4
							className={cx(
								"mt-2",
								props.text ? "tracking-wide" : "tracking-wider",
							)}
						>
							{props.subtitle}
						</h4>
						<span className="mx-auto my-4 block w-[80%] border-b mix-blend-multiply" />
					</div>
					{props.text && (
						<p
							className="mx-auto max-w-lg divide-y leading-relaxed"
							dangerouslySetInnerHTML={{
								__html: props.text.replaceAll(
									"<br/>",
									"<br/><br/>",
								),
							}}
						/>
					)}
					{props.link && (
						<div className="mt-4 flex items-center gap-4">
							<span className="flex-1" />
							<NavLink
								className="block text-sm font-light uppercase tracking-widest"
								to={props.link}
							>
								{props.shopLinkText || "Shop now"}
							</NavLink>
							<span className="flex-1" />
						</div>
					)}
				</div>
				{props.caption && (
					<small
						className={cx(
							"bottom-2 mx-auto flex w-auto items-center gap-2 p-2 text-xs",
							"max-md:-mt-6",
							"md:absolute",
							props.reverse ? "left-2" : "right-2",
						)}
					>
						{props.caption}.
						{props.captionLink && (
							<a
								href={props.captionLink}
								target="_blank"
								rel="noopener noreferer"
							>
								<LinkIcon className="h-4 w-4" />
							</a>
						)}
					</small>
				)}
			</div>
			{isVideo ? (
				<Video
					videoId={props.image.split("vimeo.com/").pop() || ""}
					className={cx(
						"bg-[#F1F1F1] pb-[100%] md:pb-[50%]",
						props.twoThirds ? "basis-1/3" : "flex-1",
					)}
				/>
			) : (
				<div
					className={cx(
						"flex-1 bg-cover bg-center bg-no-repeat pb-[100%] md:pb-[50%]",
						props.twoThirds ? "basis-1/3" : "flex-1",
					)}
					style={{ backgroundImage: `url(${props.image})` }}
				/>
			)}
		</div>
	);
};
const Highlight: React.FC<HighlightProps> = (props) => {
	return (
		<div
			className="bg-cover bg-fixed"
			style={{ backgroundImage: `url(${props.image})` }}
		>
			<div className="mx-4 mx-auto max-w-2xl py-[15%] text-center text-white">
				<h3 className="text-2xl font-light uppercase text-white">
					{props.title}
				</h3>
				<p className="my-10 leading-relaxed">{props.text}</p>
			</div>
		</div>
	);
};
const Images: React.FC<ImagesProps> = ({ srcs }) => {
	return (
		<div className="grid lg:grid-flow-col">
			{srcs.map((src) => (
				<div
					key={src}
					className="bg-gray-50 bg-cover pb-[90%]"
					style={{ backgroundImage: `url(${src})` }}
				/>
			))}
		</div>
	);
};
const Links: React.FC<LinksProps> = ({ items }) => {
	return (
		<div className="container pb-20 text-xs">
			<nav className="container flex flex-wrap justify-center gap-8 uppercase tracking-widest md:gap-12 lg:gap-20">
				{items.map((item) => (
					<NavLink
						key={item.url}
						to={item.url}
						preventScrollReset
						className={({ isActive }) =>
							cx(
								isActive &&
									"text-accent border-b-accent border-b",
							)
						}
					>
						{item.title}
					</NavLink>
				))}
			</nav>
		</div>
	);
};

const map: Record<string, React.FC<any> | undefined> = {
	banner: Banner,
	intro: Intro,
	feature: Feature,
	products: Products,
	highlight: Highlight,
	images: Images,
	related: RelatedPages,
	links: Links,
};

type Params = { slug: string };
const CollectionScreen: React.FC = () => {
	const { slug } = useParams() as Params;
	const collection = useCollection(slug);

	const meta = collection?.parts.find(
		(p): p is PagePart & MetaProps => p.type === "meta",
	);

	const intro = collection?.parts.find(
		(p): p is PagePart & IntroProps => p.type === "intro",
	);
	const banner = collection?.parts.find(
		(p): p is PagePart & BannerProps => p.type === "banner",
	);

	useMetaTags(
		{
			title: meta?.title || intro?.title,
			description: meta?.description || intro?.text,
			image: banner?.image,
		},
		[intro],
	);

	if (collection === undefined) return null;
	if (collection === null) return <Navigate to="/not-found" replace />;

	return (
		<div>
			{collection.parts.map((part, idx) => {
				const Part = map[part.type];
				return Part ? <Part key={idx} {...part} /> : null;
			})}
		</div>
	);
};

export default CollectionScreen;
