import { Localized, useLocalization } from "@launerlondon/l10n";
import { isCartDigital } from "@launerlondon/products";
import { Total } from "@launerlondon/shared";
import ModalSignIn from "@launerlondon/shop-components/src/ModalSignIn";
import { CheckoutLoaderData } from "@launerlondon/shop-router";
import { CheckoutData, RootState } from "@launerlondon/shop-types";
import { configureScope } from "@sentry/react";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { CartPaymentMethod } from "../components";
import { isDevelopment } from "../lib/utils";
import { createCustomer, setCartId, setCountry } from "../redux/actions";
import stylesheet, { colors, fonts, media } from "../styles";
import { Address, FieldChangeEvent, Input, TextArea } from "./forms";

type Props = ConnectedProps<typeof connector> & {
	//value: CheckoutData;
	total?: Total;
	onChange(data: CheckoutData): void;
	className?: string;
};
type Methods = { validate(): boolean; getOffsetTop(): number | undefined };

const connector = connect(
	(state: RootState) => ({
		customerId: state.meta.customerId,
		digital: isCartDigital(state.cart),
	}),
	{ setCartId, createCustomer },
	null,
	{ forwardRef: true },
);

const CartCheckout = forwardRef<Methods, Props>((props, ref) => {
	const { onChange, customerId, setCartId, createCustomer, digital } = props;
	const elements = useElements();
	const { l10n } = useLocalization();
	const formRef = useRef<HTMLFormElement>(null);
	const cartPaymentRef = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const { profile } = useLoaderData() as CheckoutLoaderData;
	const country = useSelector((s: RootState) => s.locale.country);
	const [data, setData] = useState<CheckoutData>({});
	const [showSignInModal, setShowSignInModal] = useState(false);
	const { revalidate } = useRevalidator();

	const link = useCallback(
		<T extends keyof CheckoutData>(
			key: T,
		): FieldChangeEvent<CheckoutData[T]> => {
			return (value) => {
				setData((prev) => {
					if (prev[key] === value) {
						return prev;
					}
					const next = { ...prev, [key]: value };
					if (value === undefined) delete next[key];
					return next;
				});
			};
		},
		[],
	);

	// needs to cache as every link() generates a new function
	const update = useMemo(
		() => ({
			name: link("name"),
			email: link("email"),
			tel: link("tel"),
			shippingAddress: link("shippingAddress"),
			payment: link("payment"),
			notes: link("notes"),
			referrer: link("referrer"),
		}),
		[link],
	);

	// if data country is ever unset, assign it to locale country
	useEffect(() => {
		if (!data.shippingAddress?.country && country) {
			setData((v) => {
				return {
					...v,
					shippingAddress: { ...v.shippingAddress, country },
				};
			});
		}
	}, [data.shippingAddress?.country, country]);

	useEffect(() => {
		if (!profile) {
			setShowSignInModal(true);
			return;
		}
		profile.then((p) => {
			setData((v) => {
				v.name = p.name;
				v.email = p.emails[0];
				v.tel = p.phone;
				v.shippingAddress = { ...p.address };
				return { ...v };
			});
			if (p.address?.country) {
				dispatch(setCountry(p.address.country));
			}
		});
	}, [profile]);

	useEffect(() => onChange({ ...data }), [onChange, data]);

	useImperativeHandle(ref, () => ({
		getOffsetTop: () => formRef.current?.offsetTop,
		validate: () => {
			const inputs = Array.from(
				formRef.current?.querySelectorAll<
					HTMLInputElement | HTMLSelectElement
				>("input,select") || [],
			);
			const error = inputs.find((i) => !i.checkValidity());
			if (error) {
				error.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
				setTimeout(() => {
					error.focus();
					error.reportValidity();
				}, 300);
				return false;
			}
			if (data.payment?.type === "card" && !data.payment?.card?.id) {
				cartPaymentRef.current?.scrollIntoView({
					behavior: "smooth",
					block: "center",
				});
				setTimeout(
					() => elements?.getElement(CardElement)?.focus(),
					300,
				);
				return false;
			}
			return Boolean(formRef.current?.checkValidity());
		},
	}));

	if (!elements) return null;

	return (
		<form ref={formRef} className={props.className}>
			<h3
				className="uppercase tracking-wide text-gray-800"
				css={styles.sectionTitle}
			>
				<Localized
					id="checkout-shipping-title"
					vars={{ digital: digital.toString() }}
				/>
			</h3>
			<div css={[styles.section, styles.isGrid, styles.shippingDetails]}>
				<Input
					title={l10n.getString("checkout-field-name")}
					required
					value={data.name}
					onChange={update.name}
				/>
				<Input
					title={l10n.getString("checkout-field-email")}
					type="email"
					value={data.email}
					placeholder={
						isDevelopment ? "dev+null@bakerwilcox.com" : undefined
					}
					required
					onChange={(email) => {
						update.email(email);
						configureScope((scope) => scope.setUser({ email }));
						if (!customerId) {
							setCartId(undefined);
							createCustomer(email, false);
						}
					}}
				/>
				<Input
					title={l10n.getString("checkout-field-tel")}
					type="tel"
					value={data.tel}
					required
					onChange={update.tel}
				/>
				<Address
					value={data.shippingAddress}
					onChange={update.shippingAddress}
				/>
			</div>
			<div ref={cartPaymentRef}>
				<CartPaymentMethod
					total={props.total}
					payment={data.payment}
					onChange={update.payment}
				/>
			</div>
			<div css={styles.section}>
				<Localized
					id="checkout-extra-title"
					children={
						<h3
							className="uppercase tracking-wide text-gray-800"
							css={styles.sectionTitle}
						/>
					}
				/>
				<TextArea
					title={l10n.getString("checkout-field-notes")}
					value={data.notes}
					onChange={update.notes}
				/>
				<Input
					title={l10n.getString("checkout-field-referrer")}
					value={data.referrer}
					minLength={3}
					onChange={update.referrer}
				/>
			</div>
			{showSignInModal && (
				<ModalSignIn
					checkoutMode
					show={showSignInModal}
					onCancel={() => setShowSignInModal(false)}
					onSuccess={() => {
						revalidate();
						setShowSignInModal(false);
					}}
				/>
			)}
		</form>
	);
});

const styles = stylesheet({
	isGrid: {
		[media.tablet]: {
			display: "grid",
			gridTemplateColumns: "repeat(4, 1fr)",
			gridColumnGap: "1em",
			gridAutoFlow: "dense",
		},
	},
	section: {
		borderBottom: `1px solid ${colors.lightGray}`,
		paddingBottom: "2em",
		marginBottom: "1em",
	},
	sectionTitle: {
		...fonts.sans,
		fontWeight: 400,
		gridColumnStart: 1,
		gridColumnEnd: 5,
		fontSize: "0.875rem",
		margin: "0 0 .5em 0",
	},
	shippingDetails: {
		[media.tablet]: {
			"label:nth-of-type(1)": { gridColumn: "1 / span 2" },
			"label:nth-of-type(2)": { gridColumn: "1 / span 2" },
			"label:nth-of-type(3)": { gridColumn: "1 / span 2" },
		},
	},
	paymentTypeToggle: {
		fontWeight: 300,
		borderBottom: "1px dotted",
		cursor: "pointer",
	},
	billingAddress: {
		[media.tablet]: {
			label: { gridColumn: "auto / span 2" },
			"label:nth-of-type(4):not(:last-child)": {
				gridColumn: "auto / span 1",
			},
			"label:nth-of-type(5)": { gridColumn: "auto / span 1" },
		},
	},
	p: {
		...fonts.sans,
		fontSize: "0.875em",
		margin: 0,
		a: { color: "inherit" },
	},
});

const ConnectedCartCheckout = connector(CartCheckout);
ConnectedCartCheckout.displayName = "cartCheckout";
type ConnectedCartCheckout = Methods;
export default ConnectedCartCheckout;
