import React from "react";
import innerText from "react-innertext";
import { Localized as FluentLocalized } from "@fluent/react";
import { getDynamicLocaleKey } from "../utils";

import type { LocalizedProps } from "@fluent/react";

type Props = Omit<LocalizedProps, "id"> & {
	id?: string;
	prefix?: string;
	html?: true;
};

const Localized: React.FC<Props> = ({
	id,
	prefix = "",
	html,
	children,
	...props
}) => {
	const text = innerText(children);
	const key = id ? id : getDynamicLocaleKey(prefix, text);
	if (html) {
		return (
			<FluentLocalized
				id={key}
				elems={{ p: <p />, br: <br />, b: <b />, li: <li /> }}
				{...props}
				children={
					<>
						<div dangerouslySetInnerHTML={{ __html: text }} />
					</>
				}
			/>
		);
	}
	return <FluentLocalized id={key} {...props} children={children} />;
};

export default Localized;
