import cx from "classnames";
import logoURL from "../assets/logo.svg";

type Props = {
	className?: string;
};

const Logo: React.FC<Props> = (props) => {
	return (
		<div
			className={cx("bg-contain bg-center bg-no-repeat", props.className)}
			style={{ backgroundImage: `url(${logoURL})` }}
		/>
	);
};

export default Logo;
