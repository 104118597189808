import { useLocalization } from "@launerlondon/l10n";
import cx from "classnames";

type Props = { className?: string };

const NavWidgetTel: React.FC<Props> = (props) => {
	const { l10n } = useLocalization();
	const tel = l10n.getString("contact-tel-intl-number");
	return (
		<a
			className={cx(
				"hidden lg:block",
				"rounded-full bg-white/50 p-2 text-xs tracking-wider",
				"hover:text-accent",
				props.className,
			)}
			href={`tel:${tel.replace(/\s/g, "")}`}
		>
			{tel}
		</a>
	);
};

export default NavWidgetTel;
