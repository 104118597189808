import { Localized } from "@launerlondon/l10n";
import { routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { NavLink, Outlet, useSubmit } from "react-router-dom";

const linkClass = cx(
	"hidden w-full min-w-[200px] lg:block",
	"m-auto p-4 pl-0",
	"text-left text-xs uppercase",
);

const Link: React.FC<{
	to: string;
	className?: string;
	children: React.ReactNode;
}> = (props) => {
	return (
		<NavLink
			className={({ isActive }) =>
				cx(linkClass, isActive && "text-accent-500")
			}
			to={props.to}
		>
			{props.children}
		</NavLink>
	);
};

const AccountNav: React.FC = () => {
	const submit = useSubmit();

	return (
		<nav
			className={cx(
				"mb-8 w-full divide-y",
				"lg:sticky lg:top-20 lg:block lg:w-auto",
			)}
		>
			<Link to={routes.accountProfile}>
				<Localized id="menu-account-profile" />
			</Link>
			<Link to={routes.accountOrderList}>
				<Localized id="menu-account-orders" />
			</Link>
			<Link to={routes.accountBookmarks}>
				<Localized id="menu-account-wishlist" />
			</Link>
			<button
				className={linkClass}
				onClick={() =>
					submit(null, {
						method: "delete",
						action: routes.signIn,
					})
				}
			>
				<Localized id="menu-account-logout" />
			</button>
		</nav>
	);
};

const Account: React.FC = () => {
	return (
		<div className="container my-10 font-sans text-sm">
			<div
				className={cx(
					"flex flex-col items-start",
					"lg:flex-row lg:divide-x",
				)}
			>
				<AccountNav />
				<div className="w-full flex-1 lg:px-10">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default Account;
