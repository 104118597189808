import { ChevronRightIcon } from "@heroicons/react/24/outline";
import {
	getColorKeys,
	getDynamicLocaleKey,
	getMenuLocaleId,
	useLocalization,
} from "@launerlondon/l10n";
import { fmtProductName, getProduct, Product } from "@launerlondon/products";
import { getPost } from "@launerlondon/shop-posts";
import { routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { useEffect, useState } from "react";
import { generatePath, Link, useMatch } from "react-router-dom";
import { getMenuParentCategories, MenuItem } from "../lib/menu";
import { useProductListParams } from "@launerlondon/shop-hooks";

function useProductName(product: Product | undefined) {
	const { l10n } = useLocalization();
	const [name, setName] = useState<string>();
	useEffect(() => {
		if (!product) {
			setName(undefined);
			return;
		}
		const [model, color] = fmtProductName(product.name);
		const tModel = l10n.getString(
			getDynamicLocaleKey("product-model", model),
			null,
			model,
		);
		if (!color) {
			setName(tModel);
			return;
		}
		const tColor = getColorKeys(color)
			.map(({ color, fallback }) => l10n.getString(color, {}, fallback))
			.join(" / ");
		setName([tModel, tColor].join(" – "));
	}, [product, l10n]);
	return name;
}

const BreadcrumbItem: React.FC<MenuItem> = ({ title, url }) => {
	const hasLink = url && url !== "#";
	const className = cx(
		"block py-3 md:py-3",
		hasLink ? "hover:text-gray-800" : "cursor-default",
	);
	return (
		<div
			className={cx(
				"group flex shrink-0 items-center gap-3",
				"overflow-hidden whitespace-nowrap capitalize",
			)}
		>
			<ChevronRightIcon className="h-4 w-4 shrink-0 group-first:hidden" />
			{hasLink ? (
				<Link to={url} className={className} children={title} />
			) : (
				<span
					className={cx(className, !url && "text-gray-600")}
					children={title}
				/>
			)}
		</div>
	);
};

const Breadcrumb: React.FC = () => {
	const [product, setProduct] = useState<Product>();
	const { l10n } = useLocalization();
	const productName = useProductName(product);

	const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

	const newsViewRoute = useMatch(routes.newsView);
	const productListRoute = useProductListParams();
	const productViewRoute = useMatch(routes.productView);
	const pageViewRoute = useMatch(routes.pageView);
	const accountRoute = useMatch(`${routes.account}/*`);

	useEffect(() => {
		setProduct(undefined);
		if (pageViewRoute) {
			const { pathname } = pageViewRoute;
			if (pathname === routes.signIn) {
				setMenuItems([{ title: l10n.getString("menu-account-login") }]);
				return;
			}
			getMenuParentCategories(pathname).then(setMenuItems);
			return;
		}

		if (productListRoute.slug) {
			getMenuParentCategories(`/c/${productListRoute.slug}`).then(
				setMenuItems,
			);
			return;
		}

		if (newsViewRoute?.params.id) {
			getPost(newsViewRoute.params.id).then((p) => {
				if (!p) return;
				p &&
					setMenuItems([
						{
							title: `news-press`,
							url: generatePath(routes.newsList),
						},
						{ title: p.title },
					]);
			});
		}

		if (productViewRoute?.params.sku) {
			getProduct("GBP", "GBP", productViewRoute.params.sku).then((p) => {
				p &&
					getMenuParentCategories(`/c/${p.category}`).then(
						setMenuItems,
					);
				setProduct(p);
			});
			return;
		}

		const page = accountRoute?.params["*"]?.split("/")[0];
		if (page) {
			setMenuItems([
				{
					title: l10n.getString("menu-account"),
					url: routes.account,
				},
				{
					title: l10n.getString(`menu-account-${page}`),
					url:
						page === "orders" ? routes.accountOrderList : undefined,
				},
			]);
			return;
		}
		setMenuItems([]);
	}, [
		productListRoute.slug,
		pageViewRoute?.params.slug,
		newsViewRoute?.params.id,
		productViewRoute?.params.sku,
		accountRoute?.params["*"],
		l10n,
	]);

	const links = [
		{ title: l10n.getString("breadcrumb-here"), url: "#" },
		...menuItems.map<MenuItem>((item) => ({
			title: l10n.getString(
				getMenuLocaleId(item.title),
				null,
				item.title,
			),
			url: item.url,
		})),
		{ title: productName },
	].filter((l): l is MenuItem => Boolean(l.title));

	if (menuItems.length === 0) {
		return null;
	}

	return (
		<aside className="border-b bg-gray-50 font-sans text-xs">
			<div className="container flex items-center gap-1 overflow-auto tracking-wide text-gray-400">
				{links.map((l, idx) => (
					<BreadcrumbItem key={idx} {...l} />
				))}
			</div>
		</aside>
	);
};

export default Breadcrumb;
