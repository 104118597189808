import { HeartIcon as HeartIconOutline } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import {
	CartItemSnapOption,
	Product,
	ProductSnap,
} from "@launerlondon/products";
import { AppLoaderData, routes } from "@launerlondon/shop-router";
import cx from "classnames";
import objectHash from "object-hash";
import { useCallback, useEffect, useState } from "react";
import { useFetcher, useRouteLoaderData } from "react-router-dom";
import ModalSignIn from "./ModalSignIn";

type Props = {
	product: ProductSnap | Product;
	options?: CartItemSnapOption[];
	className?: string;
	label?: string;
};

function hashOptions(options?: CartItemSnapOption[]) {
	if (!options || !options.length) return null;
	// cleanup object to remove undefined items
	const opts = JSON.parse(JSON.stringify(options)) as typeof options;
	// sort array keys to preserve hash consistency
	opts.sort((a, b) => b.type.localeCompare(a.type));
	return objectHash(opts);
}

const BookmarkButton: React.FC<Props> = (props) => {
	const data = useRouteLoaderData(routes.home) as AppLoaderData;
	const fetcher = useFetcher();
	const [showModal, setShowModal] = useState(false);
	const [bookmark, setBookmark] =
		useState<Awaited<AppLoaderData["bookmarks"]>[number]>();

	useEffect(() => {
		data.bookmarks.then((bookmarks) => {
			const b = bookmarks.find((b) => {
				const matchSku = b.sku === props.product.sku;
				const hash = hashOptions(props.options);
				if (hash && b.hash) return matchSku && hash === b.hash;
				return matchSku;
			});
			setBookmark(b);
		});
	}, [data.bookmarks, props.product.sku, props.options]);

	const bookmarkProduct = useCallback(() => {
		setShowModal(false);
		const options = props.options ? JSON.stringify(props.options) : "";
		fetcher.submit(
			{
				sku: props.product.sku,
				hash: bookmark
					? bookmark.hash || ""
					: hashOptions(props.options),
				options,
			},
			{
				action: routes.accountBookmarks,
				method: bookmark ? "delete" : "post",
			},
		);
	}, [props.options, bookmark]);

	const HeartIcon = bookmark ? HeartIconSolid : HeartIconOutline;

	return (
		<>
			<button
				type="button"
				className={cx(
					"flex cursor-pointer items-center gap-2 font-sans text-sm hover:text-gray-500",
					props.className,
				)}
				onClick={async () =>
					data.loggedIn ? bookmarkProduct() : setShowModal(true)
				}
			>
				<HeartIcon
					title={
						bookmark
							? "Remove from wishlist"
							: "Add product to wishlist"
					}
					className="h-5 w-5"
				/>
				{props.label && <span>{props.label}</span>}
			</button>
			<ModalSignIn
				show={showModal}
				onCancel={() => setShowModal(false)}
				onSuccess={bookmarkProduct}
			/>
		</>
	);
};

export default BookmarkButton;
