import { Products, RelatedPages } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-manufacturing.jpg";
import related3Url from "../assets/related-craftsmanship.jpg";
import related2Url from "../assets/related-heritage.jpg";
import related1Url from "../assets/related-royalwarrant.jpg";
import image1Url from "../assets/royal-warrant-logo.png";
import image3Url from "../assets/royal-warrant-mandella.jpg";
import image2Url from "../assets/royal-warrant-queen.jpg";

const RoyalWarrant: React.FC = () => {
	useMetaTags({
		title: "Royal Warrant",
		description:
			"The Warrant is a sign of excellence, quality and patronage which Launer has enjoyed for more than fifty years.",
		image: image2Url,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2>The Royal Warrant</h2>
					<p>
						There has always been a fascination with the illustrious
						history of the Royal Warrant. From even the earliest
						days, the Monarch and his or her court would require
						goods and services, as would any other household, be
						that making robes or repairing roofs. Competition for
						Royal favour was immense and naturally the finest craft
						and tradesmen wished to supply their services. Royal
						Charters were the first to be granted by the trade
						guilds, subsequently known as livery companies. Henry II
						granted the earliest recorded Royal Charter to the
						Weavers Company in 1155.
					</p>
				</header>
				<div className="ln-section-grid gap-10">
					<figure>
						<img src={image1Url} className="block" />
					</figure>
					<div>
						<p>
							By the 15<sup>th</sup> century, royal tradesmen were
							recognised with a Royal Warrant of Appointment.
							England’s first printer, Willian Caxton was an early
							recipient, awarded King’s Printer in 1476.
						</p>
						<p>
							Royal life and tastes evolved through the centuries:
							Henry VIII appointed Thomas Hewytt to “Serve the
							Court with Swannes and Cranes” and “all kinds of
							Wildfoule”. The Field of the Cloth of Gold in 1520
							was largely put together by royal tradesmen. Charles
							II in 1684 roster of royal tradesmen included a
							Sword Cutter, an Operator for the Teeth, and a
							Golf-club maker. In 1789 among the list supplying
							the Royal Household were a pin maker, a mole taker,
							a card maker and a rat catcher.
						</p>
						<p>
							Queen Victoria supported the Royal Warrant as we
							know it today by propelling its prestige to a new
							level. Throughout her 64 year old reign, the Queen
							and her family bestowed more than 2000 Royal
							Warrants – eight times more than her uncle, George
							1V. Household names such as Fortnum and Mason,
							Schweppes and Twining’s, which to this day still
							hold the prized Warrant.
						</p>
						<p>
							Women have long featured in the list of those
							granted Warrants: including a Modeller of Wax
							Flowers, a Chronometer maker and a Silversmith.
						</p>
						<p className="ln-pullout">
							For those fortunate enough to hold one, Royal
							Warrants continue to be the ultimate seal of
							approval and prestige, that recognises those that
							are regular suppliers of goods and services to
							specific members of the Royal Family.
						</p>
						<p>
							In the United Kingdom, King Charles III can bestow a
							Warrant to companies or tradespeople; Previously it
							was The Queen, Philip, Duke of Edinburgh and the
							Prince of Wales. The Warrant allows the supplier to
							display the Royal Warrant crest and promote its
							association, this alone provides huge kudos.
						</p>
						<p>
							To qualify a brand, company or service must supply
							and charge the member of the Royal Family concerned,
							or their household, with products and services in
							significant quantity over a period not less than
							five years.
						</p>
						<figure>
							<img src={image2Url} />
							<figcaption className="ln-figcaption col-span-2">
								<p>
									In 1992 Her Majesty the Queen visited the
									Launer factory in honour of a milestone
									birthday and in support of her long
									association with the company.
								</p>
							</figcaption>
						</figure>
					</div>
				</div>
				<figure className="ln-section-grid mt-10 gap-10 border-t pt-10">
					<div>
						<img src={image3Url} />
					</div>
					<figcaption>
						<h3 className="ln-subtitle">
							Launer’s Royal Warrant Appointment
						</h3>
						<p>
							The Warrant is a sign of excellence, quality and
							patronage which Launer has enjoyed for more than
							fifty years. It is highly treasured and was awarded
							by Her Majesty The Queen in 1968, whom Launer
							supplied with handbags and small leather goods since
							the early 60’s.
						</p>
						<p>
							Her Late Majesty The Queen was quite possibly the
							most photographed woman in the world and always
							carried a Launer bag with its recognisable signature
							structured shape and iconic twisted rope emblem.
						</p>
						<p>
							In 1992 Her Majesty The Queen visited the Launer
							factory in honour of a milestone birthday and in
							support of her long association with the company.
						</p>
						<p>
							We remain proud and honoured to have supplied The
							Queen for more than half a century.
						</p>
					</figcaption>
				</figure>
				<Products
					title="Styles Queen Elizabeth Carried"
					className="lg:mt-20"
					skus={["3098-01", "3099-02", "3046-01", "3050-09"]}
				/>
			</div>

			<RelatedPages
				pages={[
					{
						title: "Royal Warrant",
						link: "/royal-warrant",
						image: related1Url,
					},
					{
						title: "Heritage",
						link: "/heritage-story",
						image: related2Url,
					},
					{
						title: "Craftsmanship",
						link: "/craftsmanship",
						image: related3Url,
					},
				]}
			/>
		</div>
	);
};

export default RoyalWarrant;
