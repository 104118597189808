import { Localized } from "@launerlondon/l10n";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { routes } from "@launerlondon/shop-router";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	useMetaTags({ title: "Page not found" });
	const error = pathname === "/oops";
	const type = error ? "error" : "404";

	return (
		<div className="container my-40 flex flex-col gap-10 text-center">
			<div>
				<h1 className="text-accent font-display text-[100px] uppercase leading-none">
					<Localized id={`page-not-found--${type}-title`} />
				</h1>
				<h2 className="font-display text-lg uppercase">
					<Localized id={`page-not-found--${type}-subtitle`} />
				</h2>
			</div>
			<div className="text-sm">
				<p>
					<Localized id={`page-not-found--${type}-message`} />
				</p>
				<Localized
					id={`page-not-found--${type}-action`}
					elems={{
						back: error ? (
							<a
								href="#"
								className="text-accent"
								onClick={() => navigate(-1)}
							/>
						) : (
							<NavLink className="text-accent" to={routes.home} />
						),
						email: (
							<a
								className="text-accent"
								href="mailto:dev@launer.com?subject=Something wrong with the site"
								target="_blank"
								rel="noopener noreferrer"
							/>
						),
					}}
					children={<p />}
				/>
			</div>
		</div>
	);
};

export default NotFound;
