import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Localized, useLocalization } from "@launerlondon/l10n";
import {
	fmtProductName,
	isCartDigital,
	isVoucher,
} from "@launerlondon/products";
import {
	Currency,
	Event,
	Item,
	RetailOrder,
	defaultRoundFactor,
	fmtOrderRef,
	getItemOptionTotal,
	getItemPrice,
	getOrderTotal,
	getTaxRate,
	getTrackingUrl,
	shouldShowTax,
} from "@launerlondon/shared";
import { VoucherSnap } from "@launerlondon/shared";
import cx from "classnames";
import { Video } from ".";
import OrderVoucherDetails from "./OrderVoucherDetails";
import Price from "./Price";
import ProductImage from "./ProductImage";

function tsDate(timestamp: any) {
	return new Date((timestamp._seconds || 0) * 1000);
}

const SectionTitle: React.FC<{
	children: React.ReactNode;
	className?: string;
}> = (props) => {
	return (
		<h3
			className={cx(
				"-mx-3 mb-4 rounded bg-gray-100 px-3 py-2 text-xs font-semibold",
				props.className,
			)}
		>
			{props.children}
		</h3>
	);
};

type OrderItemProps = { item: Item; currency: Currency };
const OrderItem: React.FC<OrderItemProps> = ({ item, currency }) => {
	const total = getItemPrice(item, defaultRoundFactor)[currency];
	const isBespoke = Boolean(
		item.options?.some((o) => {
			return (
				!o.default &&
				!/lettering|size|corners|shielding|emblem|foiling/i.test(o.name)
			);
		}),
	);
	const giftCard = isVoucher(item);
	const [name, model] = fmtProductName(item.name);
	const lines = [
		{
			title: name,
			desc: isBespoke ? "Customised" : model,
			total: total,
		},
		...(item.options || []).map((o) => ({
			title: o.name,
			desc: o.value,
			total: getItemOptionTotal(o, item.price, defaultRoundFactor)[
				currency
			],
		})),
	];

	return (
		<li
			className={cx(
				"flex flex-col items-center gap-4 py-4",
				"lg:flex-row lg:items-start lg:justify-between",
			)}
		>
			<ProductImage sku={item.sku} className="mr-4 w-28" />
			<div className="w-full space-y-2">
				{lines.map((l, idx) => (
					<div
						key={idx}
						className={cx(
							"flex flex-row gap-4",
							"justify-between",
							"text-xs",
						)}
					>
						<div
							className={cx(
								"flex-1",
								"overflow-hidden text-ellipsis whitespace-nowrap",
								"lg:max-w-[200px]",
							)}
						>
							<span
								className={cx(
									"block font-semibold",
									idx > 0 && "lg:font-normal",
								)}
							>
								{l.title}
							</span>
							<span className="block lg:hidden">{l.desc}</span>
						</div>
						<div className="hidden flex-1 lg:block">{l.desc}</div>
						<div className="text-right lg:flex-1">
							<Price value={l.total} currency={currency} />
						</div>
					</div>
				))}
				{giftCard && item.extra && (
					<OrderVoucherDetails data={item.extra as VoucherSnap} />
				)}
			</div>
		</li>
	);
};

const OrderTotals: React.FC<{ order: RetailOrder & { id: string } }> = ({
	order,
}) => {
	const { country, state } = order.customer.address;
	const total = getOrderTotal(order, defaultRoundFactor);
	const taxRate = getTaxRate({ country, state, subtotal: total.subtotal });
	const showTax = shouldShowTax(order);
	const currency = order.payment.currency;
	return (
		<table className="ml-auto w-full max-w-xs text-sm">
			<tbody>
				<tr>
					<th className="text-left font-normal">Subtotal</th>
					<td className="text-right">
						<Price
							value={total.subtotal[currency]}
							currency={currency}
						/>
					</td>
				</tr>
				{showTax && (
					<tr>
						<th className="text-left font-normal">
							Tax and duties ({taxRate}%)
						</th>
						<td className="text-right">
							<Price
								value={total.tax[currency]}
								currency={currency}
							/>
						</td>
					</tr>
				)}
				<tr>
					<th className="text-left font-normal">Shipping</th>
					<td className="text-right">
						<Price
							value={total.shipping[currency]}
							currency={currency}
							showZero
						/>
					</td>
				</tr>
				{order.vouchers?.length > 0 && (
					<tr>
						<th className="text-left font-normal">Discount</th>
						<td className="text-right">
							<Price
								value={(total.discount[currency] || 0) * -1}
								currency={currency}
								showZero
							/>
						</td>
					</tr>
				)}
				<tr>
					<th className="text-left font-semibold">Total paid</th>
					<td className="text-right font-semibold">
						<Price
							value={total.paid[currency]}
							currency={currency}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

const OrderDetails: React.FC<{ order: RetailOrder }> = ({ order }) => {
	const orderDate = tsDate(order.events[0].date);
	return (
		<div>
			<SectionTitle>Order details</SectionTitle>
			<dl className="">
				<dt>Time of order</dt>
				<dd className="mb-2">{orderDate.toLocaleTimeString()}</dd>
				<dt>Payment method</dt>
				<dd className="mb-2">{order.payment.type}</dd>
				<dt>Payment currency</dt>
				<dd>{order.payment.currency.toUpperCase()}</dd>
			</dl>
		</div>
	);
};

const OrderShippingDetails: React.FC<{ order: RetailOrder }> = ({ order }) => {
	const { l10n } = useLocalization();
	const digital = isCartDigital(order.items);
	return (
		<div>
			<SectionTitle>
				{digital ? "Customer" : "Shipping"} details
			</SectionTitle>
			<div className="">
				<h4>{order.customer.name}</h4>
				{[
					order.customer.address.line1,
					order.customer.address.city,
					order.customer.address.postal_code,
					order.customer.address.state,
					l10n.getString(`country-${order.customer.address.country}`),
					order.customer.phone,
				]
					.filter(Boolean)
					.map((l, i) => (
						<p key={i}>{l}</p>
					))}
			</div>
		</div>
	);
};

const OrderStatusItem: React.FC<{ event: Event; digital: boolean }> = (
	props,
) => {
	const { event } = props;
	const digital = props.digital.toString();
	const icon = ["failed", "cancelled"].includes(event.type) ? (
		<XCircleIcon className="h-8 w-8 text-red-400" />
	) : (
		<CheckCircleIcon className="h-8 w-8 text-green-400" />
	);
	let company, trackingNumber;
	if (event.type === "shipped") {
		company = event.extra?.company as string;
		trackingNumber = event.extra?.trackingNumber as string;
	}
	return (
		<li className="flex max-w-[300px] items-start gap-2 text-xs text-gray-400">
			{icon}
			<div className="flex-1">
				<div>
					<Localized
						id={`email-status-${event.type}`}
						vars={{ digital }}
						children={<h3 className="text-sm text-gray-500" />}
					/>
					<Localized
						id={`email-title-${event.type}`}
						vars={{ digital }}
						children={<p />}
					/>
				</div>
				{trackingNumber && (
					<p>
						via {company} - Tracking number:{" "}
						<a
							className="underline"
							href={getTrackingUrl(company, trackingNumber)}
							target="_blank"
							rel="noreferer noopener"
						>
							{trackingNumber}
						</a>
					</p>
				)}
				<time
					className="text-[10px] text-gray-300"
					dateTime={tsDate(event.date).toJSON()}
				>
					{tsDate(event.date).toLocaleString()}
				</time>
			</div>
		</li>
	);
};

const OrderStatusIteSkeleton: React.FC<{ className?: string }> = ({
	className,
}) => {
	return (
		<li
			className={cx(
				"flex max-w-[300px] items-start gap-2 text-xs text-gray-400",
				className,
			)}
		>
			<div className="h-8 w-8 rounded-full bg-gray-100" />
			<div className="flex-1">
				<div className="mb-1 h-4 w-10 rounded-lg bg-gray-100" />
				<div className="mb-1 h-3 w-40 rounded-lg bg-gray-100" />
				<div className="h-2 w-20 rounded-lg bg-gray-100" />
			</div>
		</li>
	);
};

const OrderStatus: React.FC<{ order: RetailOrder }> = ({ order }) => {
	const isClosed = ["failed", "cancelled", "shipped"].includes(order.status);
	const showVideo = order.status === "started";
	const digital = isCartDigital(order.items);
	return (
		<div className="mb-4">
			<SectionTitle>Order status</SectionTitle>
			<div className={cx(showVideo && "grid gap-2 lg:grid-cols-3")}>
				<ul className="space-y-5 px-2">
					{order.events.map((e, i) => (
						<OrderStatusItem key={i} digital={digital} event={e} />
					))}
					{!isClosed && (
						<>
							{!showVideo && <OrderStatusIteSkeleton />}
							<OrderStatusIteSkeleton className="opacity-50" />
						</>
					)}
				</ul>
				{showVideo && (
					<div className="col-span-2">
						<div>
							<Video
								className="pointer-events-none rounded pb-[56%]"
								videoId="340909917"
							/>
							<small>
								Every single Launer product is hand crafted by
								skilled artisans.
							</small>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

type Props = {
	order: RetailOrder & { id: string };
	className?: string;
};
const Order: React.FC<Props> = ({ order, ...props }) => {
	const currency = order.payment.currency;
	const orderDate = tsDate(order.events[0].date);
	return (
		<article
			key={order.id}
			className={cx(
				"rounded border border-gray-200 px-6",
				props.className,
			)}
		>
			<SectionTitle className="-mx-6 flex justify-between font-normal">
				<div>
					<b>Order date:</b> {orderDate.toLocaleDateString()}
				</div>
				<div>
					<b>Order no:</b> {fmtOrderRef(order.ref)}
				</div>
			</SectionTitle>
			<ul className="divide-y">
				{order.items.map((item, idx) => (
					<OrderItem key={idx} item={item} currency={currency} />
				))}
			</ul>
			<div className="border-t py-8">
				<OrderTotals order={order} />
			</div>
			<div className="mb-4 grid gap-12 lg:grid-cols-2">
				<OrderDetails order={order} />
				<OrderShippingDetails order={order} />
			</div>
			<OrderStatus order={order} />
		</article>
	);
};

export default Order;
