import cx from "classnames";
import { forwardRef } from "react";
import type { Field } from ".";

type Input = React.ComponentProps<
	Field<React.InputHTMLAttributes<HTMLInputElement>, string>
>;

const Input = forwardRef<HTMLInputElement, Input>(
	({ onChange, className, value, type, ...props }, ref) => {
		return (
			<label className={cx("ln-field", className)}>
				<span>{props.title}</span>
				<input
					type={type || "text"}
					ref={ref}
					defaultValue={value}
					{...props}
					onBlur={(e) => {
						if (
							e.target.checkValidity() &&
							e.target.value !== value
						) {
							onChange?.call(undefined, e.target.value);
						}
					}}
				/>
			</label>
		);
	},
);

export default Input;
