import type { Field } from ".";
import stylesheet, { icon } from "../../styles";
import defaults from "../../styles/input";

type Checkbox = Field<React.InputHTMLAttributes<HTMLInputElement>, boolean>;

const Checkbox: Checkbox = ({ onChange, css, ...props }) => {
	const type = props.type === "radio" ? "radio" : "checkbox";
	return (
		<label css={[defaults.container, styles.container, css]}>
			<input
				{...props}
				type={type}
				css={styles.input}
				onChange={(e) => onChange?.call(undefined, e.target.checked)}
			/>
			<i css={styles.icon} />
			<span css={[defaults.label, styles.label]}>{props.title}</span>
		</label>
	);
};

const styles = stylesheet({
	container: {
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	},
	input: { display: "none" },
	icon: {
		marginTop: "-0.25em",
		marginRight: "0.3em",
		"&:before": { ...icon("square") },
		"input:checked ~ &:before": { ...icon("check") },
	},
	label: { padding: 0 },
});

export default Checkbox;
