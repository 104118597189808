interface ErrorJsonResponse {
	code: string;
	message: string;
	data: { status: number };
}

const cache = new Map<string, Promise<Response>>();
const cache2 = new Map<string, unknown>();

export type ApiGet = typeof apiGet;

export async function apiGet<T>(path: string): Promise<T> {
	const href = `/api/${path}.json`;

	// CHECK FIRST TO AVOID UNECESSARY WORK
	if (cache2.get(href)) {
		return cache2.get(href) as T;
	}

	let response = cache.get(href);

	if (response === undefined) {
		response = fetch(href);
		cache.set(href, response);
	}

	const responseCopy = (await response).clone();
	const jsonResponse = await responseCopy.json();

	if (responseCopy.ok) {
		// ASSIGN PURE RESPONSE SO OBJECT IS THE SAME AND STATE IS KEPT
		if (!cache2.get(href)) cache2.set(href, jsonResponse);
		return cache2.get(href) as T;
		//return jsonResponse as T;
	}

	throw new Error((jsonResponse as ErrorJsonResponse).message);
}
