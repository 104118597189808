import { useProduct } from "@launerlondon/shop-hooks";

const ProductImage: React.FC<{ sku: string; className?: string }> = (props) => {
	const product = useProduct(props.sku);
	if (!product) return null;
	return (
		<img className={props.className} src={product.images.product?.medium} />
	);
};

export default ProductImage;
