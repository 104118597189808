import type { RootState } from "@launerlondon/shop-types";
import { createReduxEnhancer as createSentryReduxEnhancer } from "@sentry/react";
import type { Action } from "redux";
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import type { ThunkAction } from "redux-thunk";
import thunk from "redux-thunk";
import { isDevelopment, version } from "../lib/utils";
import type { ApiGet } from "./api";
import { apiGet } from "./api";
import RootReducer from "./reducers";

export type RootThunkAction<
	ApiAction extends Action,
	ReturnType = void,
> = ThunkAction<ReturnType, RootState, ApiGet, ApiAction>;

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose | undefined;
	}
}

const persistedReducer = persistReducer<RootState>(
	{
		key: "launer",
		storage,
		whitelist: ["meta", "locale", "cart"],
		version: parseInt(version.replace(/\D/g, "")),
		stateReconciler: autoMergeLevel2,
	},
	RootReducer,
);

const composeEnhancers =
	(isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const sentryReduxEnhancer = createSentryReduxEnhancer({
	stateTransformer: (s) => ({
		// passing object will make sentry return 403 Payload too large
		// passing as string
		meta: JSON.stringify(s.meta),
		locale: JSON.stringify(s.locale),
		cart: JSON.stringify(s.cart),
	}),
});
const store = createStore(
	persistedReducer,
	undefined, //preloadedState
	composeEnhancers(
		applyMiddleware(thunk.withExtraArgument(apiGet)),
		sentryReduxEnhancer,
	),
);
const persistor = persistStore(store);

export default store;
export { persistor };
