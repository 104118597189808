import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { setLang } from "../redux/actions";

const LangRedirect: React.FC<{ lang: "en" | "zh-CN" }> = ({ lang }) => {
	const path = `/${useParams()["*"] || ""}`;
	const dispatch = useDispatch();
	useEffect(() => void dispatch(setLang(lang)), [dispatch]);
	return <Navigate to={path} />;
};

export default LangRedirect;
