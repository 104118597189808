export const version = String(import.meta.env.VITE_PKG_VERSION);
export const isLive = import.meta.env.MODE === "production";
export const isDevelopment = import.meta.env.MODE !== "production";
export const isEmulator = import.meta.env.DEV;
export const isDNT = Boolean(navigator.doNotTrack);

export const hasOne = <T extends unknown>(
	arr: Array<T>,
): arr is [T, ...T[]] => {
	return arr.length > 0;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function findDeep<T extends { [key: string]: any }>(
	array: T[],
	key: string,
	value: string,
	deep = "children",
	parentStore: T[] = [],
): T | undefined {
	let node: T | undefined;
	for (const item of array) {
		if (item[key] === value) {
			node = item;
			break;
		}
		const children = item[deep];
		if (children instanceof Array) {
			node = findDeep<T>(children, key, value, deep, parentStore);
			if (node !== undefined) {
				parentStore.unshift(item);
				break;
			}
		}
	}
	return node;
}

export function sanitizeSearchQuery(query = ""): string {
	return decodeURIComponent(query)
		.replace(/-/g, " ")
		.replace(/\+/g, " ")
		.replace(/[^\w ]/g, "")
		.replace(/ {2,}/g, " ")
		.trim()
		.toLowerCase();
}

export const flags = {
	checkoutTest: /checkoutTest=1/.test(location.search),
	videoList: /videoList=1/.test(location.search),
	promoXmas21:
		/promo=xmas21/.test(location.search) ||
		(new Date() > new Date("2021-10-27") &&
			new Date() < new Date("2022-01-01")),
};
