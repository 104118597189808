import type { CardPaymentData } from "@launerlondon/shop-types";
import { CardElement, useStripe } from "@stripe/react-stripe-js";
import type {
	StripeCardElement,
	StripeCardElementChangeEvent,
} from "@stripe/stripe-js";
import { useCallback, useState } from "react";
import stylesheet, { fonts } from "../styles";

type CardProps = {
	payment: CardPaymentData;
	onChange(payment: CardPaymentData): void;
};

const CartPaymentMethodCard: React.FC<CardProps> = ({ payment, onChange }) => {
	const [error, setError] = useState<string>();
	const [element, setElement] = useState<StripeCardElement>();
	const stripe = useStripe();

	const onCardChange = useCallback(
		async (event: StripeCardElementChangeEvent) => {
			let card;
			if (event.complete) {
				if (stripe && element) {
					const pm = await stripe.createPaymentMethod({
						type: "card",
						card: element,
					});
					if (pm.error) {
						setError(pm.error.message);
					} else {
						card = pm.paymentMethod;
						setError(undefined);
					}
				}
			}

			onChange({ ...payment, card });
		},
		[stripe, element, onChange],
	);
	return (
		<div css={styles.container}>
			<CardElement
				onChange={onCardChange}
				onReady={(element) => setElement(element)}
			/>
			<div css={styles.error}>{error}</div>
		</div>
	);
};

const styles = stylesheet({
	container: {
		outline: "1px solid #DEDEDE",
		padding: 10,
	},
	error: {
		...fonts.sans,
		fontSize: 10,
		color: "tomato",
	},
});

export default CartPaymentMethodCard;
