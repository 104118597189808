import {
	ChevronUpIcon,
	SwatchIcon,
	TrashIcon,
} from "@heroicons/react/24/outline";
import {
	getDynamicLocaleKey,
	Localized,
	LocalizedColors,
	useLocalization,
} from "@launerlondon/l10n";
import type {
	CartItem as CartItemModel,
	CartItemOption as CartItemOptionModel,
} from "@launerlondon/products";
import { fmtProductName } from "@launerlondon/products";
import {
	Amount,
	Currency,
	defaultRoundFactor,
	getItemOptionTotal,
	getItemTotal,
} from "@launerlondon/shared";
import { Price } from "@launerlondon/shop-components";
import { routes } from "@launerlondon/shop-router";
import { RootState } from "@launerlondon/shop-types";
import { useCallback, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { generatePath, Link, useSubmit } from "react-router-dom";
import { trackRemoveFromCart } from "../lib/analytics";
import { removeCartItem } from "../redux/actions";
import cx from "classnames";
import ProductPreview from "@launerlondon/shop-components/src/ProductPreview";

type Props = ConnectedProps<typeof connector> & { item: CartItemModel };

const connector = connect(
	(state: RootState) => ({
		currency: state.locale.currency.toLowerCase() as Currency,
	}),
	{ removeCartItem },
);

const CartItemOption: React.FC<{
	option: CartItemOptionModel;
	currency: Currency;
	price: Amount;
}> = ({ option, price, currency }) => {
	const { l10n } = useLocalization();
	const total = getItemOptionTotal(option, price, defaultRoundFactor)[
		currency
	];

	return (
		<li className="grid grid-cols-[40%_auto_auto] gap-6 p-1">
			<span>
				{l10n.getString(option.labelLocaleId, {}, option.label)}
			</span>
			<span className={cx("flex gap-2", !total && "col-span-2")}>
				{l10n.getString(option.valueLocaleId, {}, option.value)}
				{!option.default && (
					<SwatchIcon
						className="h-4 w-4"
						title={l10n.getString("cart-items-bespoke")}
					/>
				)}
			</span>
			{Boolean(total) && <Price className="text-right" value={total} />}
		</li>
	);
};

const CartItemRemove: React.FC<{ onConfirm(): void }> = (props) => {
	const [confirmRemoval, setConfirmRemoval] = useState(false);
	return (
		<div className="h-4">
			{!confirmRemoval ? (
				<button
					className="hover:text-accent-500"
					onClick={() =>
						setTimeout(() => setConfirmRemoval(true), 300)
					}
				>
					<TrashIcon className="h-4 w-4" />
				</button>
			) : (
				<div className="flex gap-2 text-xs">
					<Localized id="cart-items-remove-confirm--title" />
					<div className="flex gap-1">
						<button
							className="remove-action hover:text-accent-500"
							onClick={props.onConfirm}
						>
							<Localized id="cart-items-remove-confirm--action-yes" />
						</button>
						|
						<button
							className="remove-action hover:text-accent-500"
							onClick={() => setConfirmRemoval(false)}
						>
							<Localized id="cart-items-remove-confirm--action-no" />
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

const CartItemThumb: React.FC<{ item: CartItemModel; className?: string }> = (
	props,
) => {
	const submit = useSubmit();
	const options = useSelector(
		(s: RootState) => s.cart.find((i) => i.id === props.item.id)?.options,
	);
	const { sku } = props.item;
	const productLink = generatePath(routes.productView, {
		sku,
		"*": "",
	});

	const onLinkClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
		(e) => {
			if (options) {
				e.preventDefault();
				submit(
					{ options: JSON.stringify(options) },
					{ action: productLink, method: "post" },
				);
			}
		},
		[options],
	);

	return (
		<Link
			onClick={onLinkClick}
			className={cx(
				"relative w-full bg-gradient-to-b from-gray-50 to-white",
				"overflow-hidden rounded",
				props.className,
			)}
			to={productLink}
		>
			<ProductPreview sku={sku} options={options} />
		</Link>
	);
};

const CartItem: React.FC<Props> = ({ item, currency, removeCartItem }) => {
	const { l10n } = useLocalization();
	const [showDetails, setShowDetails] = useState(false);

	const [model, color] = fmtProductName(item.name);
	const options = Array.from(item.options);
	const isBespoke = options.some((o) => !o.default);
	const total = getItemTotal(item, defaultRoundFactor)[currency] || 0;
	const localeModel = l10n.getString(
		getDynamicLocaleKey("product-model", model),
		null,
		model,
	);

	// if charged options, add product itself to as first detailed item
	if (options.some((o) => o.multiplier)) {
		options.splice(0, 0, {
			default: true,
			label: localeModel,
			labelLocaleId: "",
			valueLocaleId: "",
			value: "",
			multiplier: 1,
		});
	}

	const removeItem = useCallback(() => {
		removeCartItem(item.id);
		trackRemoveFromCart(item);
	}, [item]);

	return (
		<div className="p-2">
			<div
				className={cx(
					"flex gap-4 py-4 font-light",
					options.length > 2 && showDetails
						? "items-start"
						: "items-center",
				)}
			>
				<CartItemThumb
					item={item}
					className="hidden max-w-[180px] !from-white lg:block"
				/>
				<div className="flex-1">
					<div className="flex items-start gap-4">
						<CartItemThumb
							item={item}
							className="max-w-[100px] !to-white lg:hidden"
						/>
						<div className="grid flex-1 grid-cols-2">
							<div className="col-span-2 flex items-center justify-between leading-tight">
								<div className="flex gap-2 text-sm">
									<h3 className="font-light uppercase tracking-widest text-gray-800">
										{localeModel}
									</h3>
									{isBespoke && (
										<SwatchIcon
											className="h-4 w-4"
											title={l10n.getString(
												"cart-items-bespoke",
											)}
										/>
									)}
								</div>
								<Price className="text-sm" value={total} />
							</div>
							<div className="col-span-2 mb-2 flex flex-col items-start tracking-wide">
								<h4 className="text-sm font-light">
									{color ? (
										<LocalizedColors colors={color} />
									) : (
										"Customised materials"
									)}
								</h4>
							</div>

							<div className="col-span-2 flex w-full items-center justify-between text-sm">
								<button
									className="flex gap-2"
									onClick={() => setShowDetails((v) => !v)}
								>
									<span>Details</span>
									<ChevronUpIcon
										className={cx(
											"h-4 w-4",
											showDetails &&
												"rotate-180 transform",
										)}
									/>
								</button>
								<CartItemRemove onConfirm={removeItem} />
							</div>
						</div>
					</div>
					{showDetails && (
						<ul className="mt-2 divide-y divide-dotted rounded-sm bg-gray-50 p-2 text-sm leading-tight tracking-wide">
							{options.map((o, i) => (
								<CartItemOption
									key={i}
									option={o}
									currency={currency}
									price={item.price}
								/>
							))}
						</ul>
					)}

					<div className="_lg:flex hidden justify-between border-t">
						<CartItemRemove onConfirm={removeItem} />
						<Price value={total} showZero />
					</div>
				</div>
			</div>
		</div>
	);
};

export default connector(CartItem);
