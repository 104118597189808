export type BannerProps = {
	image?: string;
	imageMobile?: string;
	videoId?: string;
	parallax?: boolean;
	position?: string;
	message?: string;
};
export type MetaProps = { title?: string; description?: string };
export type IntroProps = { title: string; text?: string; textLink?: string };
export type FeatureProps = {
	title: string;
	subtitle?: string;
	text?: string;
	image: string;
	link?: string;
	bgColor?: string;
	textColor?: string;
	reverse?: true;
	shopLinkText?: string;
	caption?: string;
	captionLink?: string;
	twoThirds?: boolean;
};
export type ProductsProps = {
	title?: string;
	skus: string[];
	links?: string[];
};
export type HighlightProps = {
	title: string;
	text: string;
	image: string;
};
export type ImagesProps = {
	srcs: string[];
};
export type LinksProps = {
	items: Array<{ title: string; url: string }>;
};

export type PagePart =
	| ({ type: "meta" } & MetaProps)
	| ({ type: "banner" } & BannerProps)
	| ({ type: "intro" } & IntroProps)
	| ({ type: "feature" } & FeatureProps)
	| ({ type: "products" } & ProductsProps)
	| ({ type: "highlight" } & HighlightProps)
	| ({ type: "images" } & ImagesProps)
	| ({ type: "links" } & LinksProps);

export type Collection = {
	hidden?: boolean;
	slug: string;
	parts: PagePart[];
};

const list = import("./data.json").then((d) =>
	(d.default as Collection[]).filter((c) => !c.hidden),
);

export async function getCollection(slug: string) {
	return (await list).find((c) => c.slug === slug);
}
