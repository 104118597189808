import { Popover, Transition } from "@headlessui/react";
import cx from "classnames";
import { Fragment } from "react";
import logoUrl from "../assets/klarna-logo.png";
import pay3Url from "../assets/klarna-pay-3.png";
import payLaterUrl from "../assets/klarna-pay-later.png";
import payNowUrl from "../assets/klarna-pay-now.png";

type Props = { className?: string };

const BgImage: React.FC<{ src: string; className?: string }> = (props) => {
	return (
		<div
			className={cx(
				"mx-auto aspect-video bg-contain bg-center bg-no-repeat",
				props.className,
			)}
			style={{
				backgroundImage: `url(${props.src})`,
			}}
		/>
	);
};

const KlarnaCTA: React.FC<Props> = (props) => {
	return (
		<Popover>
			<Popover.Button className={cx("text-sm", props.className)}>
				<b>Klarna</b> now available.{" "}
				<span className="underline">Learn More</span>
			</Popover.Button>
			<Transition as={Fragment}>
				<Popover.Panel className="fixed inset-0 z-50 flex items-center justify-center">
					{({ close }) => (
						<>
							<Transition.Child
								enterFrom="opacity-0"
								leaveTo="opacity-0"
								enter="ease-out duration-200"
								leave="ease-in duration-200"
							>
								<Popover.Overlay className="absolute inset-0 z-50 bg-black/50" />
							</Transition.Child>

							<Transition.Child
								enterFrom="opacity-0 translate-y-20"
								leaveTo="opacity-0 translate-y-20"
								enter="ease-out duration-200"
								leave="ease-in duration-200"
							>
								<div className="relative z-50 mx-4 max-h-[90vh] max-w-3xl space-y-8 overflow-auto rounded bg-neutral-100 p-6 text-center">
									<BgImage src={logoUrl} className="w-28" />
									<h3 className="uppercase tracking-widest text-black">
										Flexible Payment Options
									</h3>
									<ul className="grid gap-5 text-sm md:grid-cols-3">
										{[
											[
												pay3Url,
												"Pay in 3",
												"Interest free. Each payment is made every 30 days.",
											],
											[
												payLaterUrl,
												"Pay later",
												"Receive your order now. Pay in 30 days, interest free.",
											],
											[
												payNowUrl,
												"Pay Now",
												"Pay in full with your credit card.",
											],
										].map(([img, title, text]) => (
											<li className="rounded bg-white p-4">
												<BgImage
													src={img!}
													className="mb-4 h-20 w-20"
												/>
												<h4 className="font-medium">
													{title}
												</h4>
												<p>{text}</p>
											</li>
										))}
									</ul>

									<p className="mx-auto max-w-md text-sm text-stone-500">
										The availability of these payment
										options may differ depending on your
										location and the purchase amount.
										Subject to approval. Additional terms
										may apply,{" "}
										<a
											className="underline"
											href="https://www.klarna.com/"
											target="_blank"
											rel="noopener noreferer"
										>
											please visit Klarna for further
											information.
										</a>
									</p>

									<h3 className="uppercase tracking-widest text-black">
										How it works
									</h3>
									<div className="mx-auto max-w-md rounded bg-white p-4 text-left">
										<ul className="px-6 py-4">
											{[
												['At Checkout select "Klarna"'],
												[
													"Choose your payment plan.",
													"Different playment plans may be shown depending on the purchase amount and credit score.",
												],
												[
													"Complete your checkout.",
													"The amount will be charged based on the payment plan you choose.",
												],
											].map(([title, text]) => (
												<li className="group relative -mt-2 pb-8 pl-4">
													<div className="absolute -left-2 top-2 h-3 w-3 bg-black" />
													<div className="absolute -left-1 bottom-0 top-5 w-1 bg-gray-200 group-last:hidden" />
													<h4 className="font-medium">
														{title}
													</h4>
													<p>{text}</p>
												</li>
											))}
										</ul>
									</div>
									<button
										className="button w-auto"
										onClick={() => close()}
									>
										Close
									</button>
								</div>
							</Transition.Child>
						</>
					)}
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};

export default KlarnaCTA;
