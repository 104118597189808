import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

export default function CollectionPatentWonder() {
	useMetaTags({
		title: "Patent Wonder",
		description:
			"Admired for its glossy and glamorous appearance, patent at Launer is a polished affair. It emits a lustre that elevates a stylish bag into a sublime statement in enticing colours.",
		image: "/images/collections/patent-wonder-banner.webp",
	});
	return (
		<div className="text-center max-xl:text-sm">
			<div
				className={twJoin(
					"aspect-square max-h-[60vh] w-full",
					"bg-gray-200 bg-cover bg-[left_-10rem_top] lg:bg-center",
					"bg-[url('/images/collections/patent-wonder-banner.webp')]",
				)}
			/>
			<Row.Single className="text-base">
				<h1 className="ln-title">Patent Wonder</h1>
				<p>
					Admired for its glossy and glamorous appearance, patent at
					Launer is a polished affair. It emits a lustre that elevates
					a stylish bag into a sublime statement in enticing colours.
					Essential black and brown are joined by red, purple and
					shades of blue. Fall in love with this low-key luxe finish
					that will abide in your wardrobe through the years.
				</p>
			</Row.Single>
			<Products
				className="container"
				skus={["2826-02", "3085-02", "3121-01", "3087-02"]}
			/>
			<Products
				className="container"
				skus={["3013-04", "3013-02", "3011-01", "2910-01"]}
			/>
			<Row.WithImage
				reverse
				image="/images/collections/patent-wonder-lisa.webp"
				className="bg-[#F1EEEB] text-[#746C6C]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Lisa</h2>
				</header>
				<p className="my-10">
					The beauty of Launer's traditional turned-edge leatherwork
					is evident on the double handled Lisa. A stylish bag that’s
					sleek in appearance yet easily accommodates the everyday
					essentials and personal items when you are on-the-go. The
					immaculate patent leather and soft suede lining speak
					volumes through the exquisitely understated design, making
					the Lisa a study in elegant and undeniable quality.
				</p>
				<Link
					to="/s/lisa"
					className="!mt-20 inline-block text-sm uppercase tracking-widest"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["2947-05", "3097-22", "2826-01", "3046-01"]}
			/>
			<Products
				className="container"
				skus={["3050-18", "3087-02", "738-06", "2941-02"]}
			/>
			<Row.WithImage
				image="/images/collections/patent-wonder-traviata.webp"
				className="bg-[#F1EEEB] text-[#746C6C]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Traviata</h2>
				</header>
				<p className="my-10">
					The Traviata is a legend, acclaimed for its structured
					silhouette, exquisite handcrafted detail and signature
					twisted rope emblem. This handbag icon was first introduced
					in 1989, achieving International status as the bag of choice
					for Her Late Majesty Queen Elizabeth II.
				</p>
				<Link
					to="/s/traviata"
					className="!mt-20 inline-block text-sm uppercase tracking-widest"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
			<Products
				className="container"
				skus={["686-04", "685-15", "254-03", "805-17"]}
			/>
			<Row.WithImage
				reverse
				image="/images/collections/patent-wonder-tosca.webp"
				className="bg-[#F1EEEB] text-[#746C6C]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Tosca</h2>
				</header>
				<p className="my-10">
					A beautiful and beloved clutch bag that is ideal for special
					occasions and events for day. Tosca is crafted in our
					stunning patent leather, an enlarged version of High
					Society. It retains its compact appearance and exquisite
					form, providing a style that is timeless with a detachable
					gold shoulder chain.
				</p>
				<Link
					to="/s/tosca"
					className="!mt-20 inline-block text-sm uppercase tracking-widest"
				>
					SHOP NOW
				</Link>
			</Row.WithImage>
		</div>
	);
}
