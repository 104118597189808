import { MessageForm } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-contact.jpg";

const Contact: React.FC = () => {
	useMetaTags({
		title: "Contact us",
		description:
			"The Launer team is on hand to answer your questions.  Please contact us using one of the options below most relevant to your enquiry. We look forward to hearing from you.",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container pb-20">
				<header className="ln-intro-grid">
					<h2 className="font-display">Contact Launer</h2>
					<div>
						<p>
							The Launer team is on hand to answer your questions.
							Please contact us using one of the options below
							most relevant to your enquiry. We look forward to
							hearing from you.
						</p>
					</div>
				</header>
				<div className="ln-section-grid gap-10">
					<div>
						<h3 className="ln-subtitle">Launer London Ltd</h3>
					</div>
					<div>
						<p>
							Classic House
							<br />
							365A Limpsfield Road
							<br />
							Warlingham
							<br />
							Surrey
							<br />
							CR6 9HA
							<br />
							United Kingdom
							<br />
							UK tel: 01883 625 562
							<br />
							International tel: 0044 1883 625 562
							<br />
							Email: sales@launer.com
						</p>

						<p className="mb-4">
							Alternatively, you can send us a message using the
							form below.
						</p>
						<MessageForm
							action="contact"
							fields={[
								{ title: "Name", required: true },
								{
									title: "Email",
									type: "email",
									required: true,
								},
								{ title: "Subject" },
								{
									title: "Message",
									type: "textarea",
									required: true,
								},
							]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
