import { MessageForm, RelatedPages } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
const bannerUrl = "/images/pages/showroom-banner.webp";
import related3Url from "../assets/related-craftsmanship.jpg";
import related2Url from "../assets/related-heritage.jpg";
import related1Url from "../assets/related-royalwarrant.jpg";
import { twJoin } from "tailwind-merge";

export default function Showroom() {
	useMetaTags({
		title: "Special Commissions Showroom",
		description: "Strictly by appointment",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2>Special Commissions Showroom</h2>
					<p>
						Strictly by appointment.
						<br />
						Book a preferable time via the form below
					</p>
				</header>
				<div className="ln-section-grid gap-10">
					<div>
						<div
							className={twJoin(
								"aspect-square bg-cover",
								"bg-[url('/images/pages/showroom-feature-1.webp')]",
							)}
						/>
					</div>
					<div className="top-0 self-start lg:sticky">
						<MessageForm
							action="showroom"
							className="grid-cols-2 items-start gap-10 xl:grid xl:space-y-0"
							fields={[
								{
									title: "Full name",
									required: true,
								},
								{
									title: "Email",
									type: "email",
									required: true,
								},
								{
									title: "Phone number",
									type: "tel",
									required: true,
								},
								{
									title: "Preferable date(s) for your visit",
									type: "text",
									required: true,
								},
								{
									title: "Products you are interested in",
									type: "text",
									required: true,
									placeholder: "Traviata, Judi",
									className: "col-span-2",
								},
								{
									title: "I would like to sign up to receive regular newsletters from Launer",
									name: "newsletter",
									type: "checkbox",
									className: "col-span-2",
								},
							]}
						/>
					</div>
				</div>
			</div>
			<RelatedPages
				pages={[
					{
						title: "Royal Warrant",
						link: "/royal-warrant",
						image: related1Url,
					},
					{
						title: "Heritage",
						link: "/heritage-story",
						image: related2Url,
					},
					{
						title: "Craftsmanship",
						link: "/craftsmanship",
						image: related3Url,
					},
				]}
			/>
		</div>
	);
}
