//type PinterestCommand = "load" | "page" | "set" | "track" | "setConsent";

type PinterestEventType =
	| "pagevisit"
	| "viewcategory"
	| "search"
	| "addtocart"
	| "checkout"
	| "watchvideo"
	| "signup"
	| "lead"
	| "custom";

type PinterestPageVisitData = {
	product_id: string;
};

type PinterestAddtoCartData = {
	product_id: string;
	value: number;
	order_quantity?: number;
	currency: string;
};
type PinterestCheckoutData = {
	value: number;
	order_quantity?: number;
	currency: string;
	order_id: string;
	promo_code?: string;
	property?: string;
	search_query?: string;
	video_title?: string;
	lead_type?: string;
	line_items: {
		product_name: string;
		product_id: string;
		product_category: string;
		product_price: number;
		product_variant_id?: string;
		product_variant?: string;
		product_quantity?: number;
		product_brand: string;
	}[];
};

type PinterestEventData<T = PinterestEventType> = T extends "pagevisit"
	? PinterestPageVisitData
	: T extends "addtocart"
	? PinterestAddtoCartData
	: T extends "checkout"
	? PinterestCheckoutData
	: never;

type PinterestTrackEvent =
	| ["track", Exclude<PinterestEventType, "addtocart" | "checkout">]
	| ["track", "pagevisit", PinterestPageVisitData]
	| ["track", "addtocart", PinterestAddtoCartData]
	| ["track", "checkout", PinterestCheckoutData];

type PinterestEvent = ["page"] | ["load", string] | PinterestTrackEvent;

declare global {
	interface Window {
		pintrk: {
			version: "3.0";
			queue: PinterestEvent[];
		};
	}
}

const pinterestTagId = String(import.meta.env.VITE_PINTEREST_TAG_ID);

export function install(): void {
	if (self.pintrk) return;
	self.pintrk = {
		version: "3.0",
		queue: [["load", pinterestTagId], ["page"]],
	};
	const script = document.createElement("script");
	script.src = "https://s.pinimg.com/ct/core.js";
	document.body.append(script);
}

export function uninstall() {
	if (!self.pintrk) return;
	// to be implemented
}

export function trackEvent<T extends PinterestEventType>(
	type: T,
	meta?: PinterestEventData<T>,
): void {
	if (!self.pintrk || !self.pintrk.queue) {
		return;
	}
	if (meta !== undefined) {
		//FIXME inferred typings breaking
		self.pintrk.queue.push(["track", type, meta] as PinterestTrackEvent);
		return;
	}
	//FIXME inferred typings breaking
	self.pintrk.queue.push(["track", type] as PinterestEvent);
}
