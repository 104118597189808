import type { CSSPropertiesWithMultiValues as CssProps } from "@emotion/serialize";

type CssExt = {
	WebkitFontSmoothing:
		| "auto"
		| "none"
		| "antialiased"
		| "subpixel-antialised";
	MozOsxFontSmoothing: "auto" | "grayscale";
};

type Style<T, B = CssProps> = { [K in Extract<keyof T, keyof B>]: B[K] } & {
	[K in Exclude<keyof T, keyof B>]: T[K] extends Record<string, unknown>
		? Style<T[K], B>
		: never;
};

function stylesheet<T extends Style<T, CssProps & CssExt>>(s: T): T {
	return s;
}

export default stylesheet;

export enum media {
	menuMobile = "@media screen and (max-width:930px)",
	menuTablet = "@media screen and (min-width:930px)",
	mobile = "@media screen and (max-width:48em)",
	mobileOnly = "@media screen and (min-width:930px) and (max-width:48em)",
	tablet = "@media screen and (min-width:48em)",
	tabletOnly = "@media screen and (min-width:48em) and (max-width:63.9em)",
	desktop = "@media screen and (min-width:64em)",
	desktopOnly = "@media screen and (min-width:64em) and (max-width:79.9em)",
	large = "@media screen and (min-width:80em)",
	largeOnly = "@media screen and (min-width:80em) and (max-width:1099px)",
	wide = "@media screen and (min-width:1100px)",
	narrow = "@media screen and (max-width: 1099px)",
	ie = "@media screen and (min-width:0\0)",
	ieTablet = "@media screen and (min-width:48em\0)",
	ieDesktop = "@media screen and (min-width:64em\0)",
	ieLarge = "@media screen and (min-width:80em\0)",
	chrome = "@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm)",
}

export enum colors {
	lighterGray = "#F8F8F8",
	lightGray = "#F2F2F2",
	gray = "#9B9797",
	darkGray = "#645D5D",
	darkerGray = "#362E2E",
	accent = "#A79D6C",
	green = "#28BA70",
	alertText = "#bf999a",
	alertBg = "#efe6e7",
}

export const fonts = stylesheet({
	sans: {
		fontFamily:
			"Montserrat, -apple-system, BlinkMacSystemFont, system-ui, sans-serif",
		fontWeight: 400,
	},
	serif: { fontFamily: "Libre Baskerville, Georgia, Times New Roman, serif" },
});

export const mixins = stylesheet({
	title: {
		...fonts.sans,
		fontWeight: 300,
		lineHeight: 1.4,
		letterSpacing: 2,
		textTransform: "uppercase",
	},
	bar: {
		marginLeft: "calc((-100vw + 100%)/2)",
		marginRight: "calc((-100vw + 100%)/2)",
		paddingLeft: "calc(( 100vw - 100%)/2)",
		paddingRight: "calc(( 100vw - 100%)/2)",
	},
	alert: {
		...fonts.sans,
		fontSize: 12,
		marginBottom: 30,
		padding: "1em",
		color: colors.alertText,
		backgroundColor: colors.alertBg,
	},
	ellipsis: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
});

enum icons {
	twitter = "\ue900",
	facebook = "\ue901",
	pinterest = "\ue90f",
	google = "\ue90e",
	whatsapp = "\ue910",
	burger = "\ue902",
	chevronRight = "\ue903",
	chevronDown = "\ue905",
	chevronLeft = "\ue904",
	chevronUp = "\ue906",
	instagram = "\ue907",
	shoppingBag = "\ue908",
	close = "\ue909",
	info = "\ue90a",
	check = "\ue90b",
	square = "\ue90c",
	palette = "\ue911",
	truck = "\ue90d",
	globe = "\ue912",
	wallet = "\ue913",
	alipay = "\ue914",
	wechat = "\ue915",
	applePay = "\ue919",
	googlePay = "\ue918",
	microsoft = "\ue91a",
	phone = "\ue916",
	card = "\ue917",
	search = "\ue91b",
	play = "\ue91c",
}

export const icon = (name: keyof typeof icons): Style<unknown> =>
	stylesheet({
		content: `'${icons[name]}'`,
		display: "inline-block",
		fontFamily: "icons",
		fontStyle: "normal",
		fontWeight: "normal",
		fontVariant: "normal",
		textTransform: "none",
		verticalAlign: "middle",
		WebkitFontSmoothing: "antialiased",
		MozOsxFontSmoothing: "grayscale",
	});

export function cx(
	classNames:
		| string
		| Record<string, unknown>
		| Array<string | Record<string, unknown> | undefined>,
): string {
	if (Array.isArray(classNames)) {
		return classNames
			.filter((i) => i)
			.map((i) => (typeof i === "object" ? cx(i) : i))
			.join(" ");
	}
	if (typeof classNames === "object") {
		const entries: [string, unknown][] = Object.entries(classNames);
		return cx(entries.map(([key, value]) => (value ? key : undefined)));
	}
	return classNames;
}
