import { Localized } from "@launerlondon/l10n";
import { SignInForm } from "@launerlondon/shop-components";
import { routes } from "@launerlondon/shop-router";
import { useNavigate, useSearchParams } from "react-router-dom";

const SignIn: React.FC = () => {
	const navigate = useNavigate();
	const [search] = useSearchParams();
	const next = search.get("next") || routes.account;
	return (
		<div className="container my-10 max-w-3xl font-sans md:my-20">
			<h2 className="ln-title mx-auto mb-10 max-w-sm text-center text-2xl uppercase md:mx-0 md:text-left">
				<Localized id="sign-in-title" />
			</h2>
			<div className="flex flex-col items-center gap-8 md:flex-row md:divide-x">
				<div className="text-center md:text-left">
					<h4 className="mb-2 text-lg">
						<Localized id="sign-in-benefits-title" />
					</h4>
					<ul className="list-inside space-y-1 text-xs font-normal md:list-disc">
						<Localized id="sign-in-benefits-points" html />
					</ul>
				</div>
				<SignInForm
					className="md:pl-10"
					onSuccess={() => {
						navigate(next, { replace: true });
					}}
				/>
			</div>
		</div>
	);
};

export default SignIn;
