import { useParams } from "react-router-dom";

export function useProductListParams() {
	const params = useParams() as { slug?: string; search?: string };
	const [slug, matchByStyle] = params.slug?.split("-by-style") || [];
	const byStyle = matchByStyle === "";
	return {
		slug,
		search: params.search,
		byStyle,
	};
}
