import axios from "axios";
import { Currency } from "@launerlondon/shared";

export type Rates = Record<string, number>;

export const liveRates = (async function () {
	type RatesResponse = {
		rates: Rates;
		base: Currency;
		date: string;
	};
	return axios
		.get<RatesResponse>(
			`https://api.frankfurter.app/latest?base=GBP&symbols=${[
				"GBP",
				"USD",
				"EUR",
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"HKD",
				"NOK",
				"NZD",
				"SEK",
				"SGD",
			].sort()}`,
		)
		.then((r) => {
			r.data.rates.GBP = 1;
			return r.data.rates;
		});
	// FIXME add fallback in case request fails (currently crashing)
	//.catch(() => ({ GBP: 1 } as RatesResponse["rates"]));
})();
