import stylesheet, { colors, fonts, icon } from "../styles";

type Props = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
	title: string;
	link?: string;
	onPress?(): void;
	type?: "normal" | "loading" | "success";
};

const Button: React.FC<Props> = ({
	link,
	onPress,
	type = "normal",
	...props
}) => (
	<button
		css={[styles.button, styles[type], props.disabled && styles.disabled]}
		onClick={
			link
				? () => {
						location.href = link;
				  }
				: onPress
		}
		{...props}
	>
		{props.title}
	</button>
);

const styles = stylesheet({
	button: {
		...fonts.sans,
		appearance: "none",
		fontSize: "0.813rem",
		fontWeight: 400,
		letterSpacing: "0.087rem",
		border: `2px solid ${colors.darkerGray}`,
		borderRadius: "0",
		padding: ".875rem 1.25rem",
		textTransform: "uppercase",
		color: "white",
		backgroundColor: colors.darkerGray,
		outline: "none",
		cursor: "pointer",
		":focus": { outline: "none" },
		":hover": {
			color: colors.darkerGray,
			borderColor: "currentColor",
			backgroundColor: "white",
		},
		"active, &:active": {
			color: "white",
			borderColor: colors.gray,
			backgroundColor: colors.gray,
		},
	},
	disabled: {
		opacity: 0.5,
		pointerEvents: "none",
		color: colors.darkerGray,
		border: "1px soild red",
		backgroundColor: "transparent",
	},
	normal: {},
	loading: { cursor: "wait" },
	success: {
		color: "white",
		borderColor: colors.green,
		backgroundColor: colors.green,
		":after": {
			...icon("chevronRight"),
			marginLeft: ".5em",
		},
	},
});

export default Button;
