import { RelatedPages } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-about.jpg";
import image1Url from "../assets/introducing-launer-event.jpg";
import image2Url from "../assets/introducing-launer-selfridges.jpg";
import related3Url from "../assets/related-craftsmanship.jpg";
import related2Url from "../assets/related-heritage.jpg";
import related1Url from "../assets/related-royalwarrant.jpg";

const About: React.FC = () => {
	useMetaTags({
		title: "Introducing Launer",
		description:
			"Celebrating 80 years of heritage, producing the very finest in understated luxury, creating structured handbags and small leather goods, employing time honoured skills and traditions.",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2>Introducing Launer</h2>
					<p>
						Welcome to the world of Launer and discover a British
						legacy built on tradition and fine craftsmanship.
						Celebrating 80 years of heritage, producing the very
						finest in understated luxury, creating structured
						handbags and small leather goods, employing time
						honoured skills and traditions.
					</p>
				</header>
				<div className="ln-section-grid">
					<div>
						<p>
							Today Launer is recognised the world over for its
							iconic structured style handbags and 'twisted rope'
							hero signature emblem, every piece is crafted by
							hand in England.
						</p>
						<p>
							Her Late Majesty Queen Elizabeth II was loyal to
							Launer for more than 50 years and Her Majesty Queen
							Camilla often carries our refined designs. Launer
							has attracted a new legion of dedicated customers
							propelling the brand on a global basis as a
							contemporary label. The status of the brand is such,
							that our handbags are often featured in films and
							prominent stage plays both in the UK and on
							Broadway. Most recently a number of styles appeared
							in Season Four of the award winning Netflix drama
							The Crown.
						</p>
						<p>
							Selling in the finest department stores of
							Selfridges and Fortnum and Mason in London. Launer
							can also be found Internationally, in Japan, Italy
							and China. Launer's quintessential British
							handwriting makes it hugely popular in the USA.
						</p>

						<figure className="grid gap-6">
							<img src={image1Url} />
							<img src={image2Url} />
							<figcaption className="ln-figcaption md:col-span-2">
								<p>
									Selling in the finest department stores of
									Selfridges and Fortnum and Mason in London.
								</p>
							</figcaption>
						</figure>
					</div>
				</div>
			</div>
			<RelatedPages
				pages={[
					{
						title: "Royal Warrant",
						link: "/royal-warrant",
						image: related1Url,
					},
					{
						title: "Heritage",
						link: "/heritage-story",
						image: related2Url,
					},
					{
						title: "Craftsmanship",
						link: "/craftsmanship",
						image: related3Url,
					},
				]}
			/>
		</div>
	);
};

export default About;
