import { LocalizationProvider } from "@launerlondon/l10n";
import { CookieContext, useCookieConsent } from "@launerlondon/shop-hooks";
import { RootState } from "@launerlondon/shop-types";
import { ErrorBoundary, withProfiler as sentryProfiler } from "@sentry/react";
import { Children, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Provider as ReduxProvider, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { PersistGate } from "redux-persist/integration/react";
import { Footer, Header, ScrollToTop } from "./components";
import { installTrackers, trackPageView } from "./lib/analytics";
import store, { persistor } from "./redux/store";

declare global {
	interface Window {
		__nav: (path: string) => void;
	}
}

const UnsafeNav: React.FC = () => {
	useEffect(() => trackPageView, []);
	self.__nav = useNavigate();
	return null;
};

const App: React.FC = sentryProfiler(() => {
	return (
		<div className="flex min-h-screen flex-col md:[overflow-anchor:none]">
			<UnsafeNav />
			<ScrollToTop />
			<Toaster containerClassName="!z-50 !text-sm" reverseOrder />
			<Header />
			<ErrorBoundary
				showDialog
				fallback={(e) => (
					e.resetError(), (<Navigate to="/oops" replace />)
				)}
			>
				<Outlet />
			</ErrorBoundary>
			<Footer />
		</div>
	);
});

const LocProviderWrapper: React.FC<React.PropsWithChildren> = (props) => {
	const lang = useSelector((s: RootState) => s.locale.lang);
	return (
		<LocalizationProvider locale={lang}>
			{Children.only(props.children)}
		</LocalizationProvider>
	);
};

const WithCookieConsent: React.FC<React.PropsWithChildren> = (props) => {
	const [consent] = useCookieConsent();
	useEffect(installTrackers, [consent]);
	return <>{props.children}</>;
};

const AppLayout: React.FC = () => {
	return (
		<CookieContext>
			<WithCookieConsent>
				<ParallaxProvider>
					<ReduxProvider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<LocProviderWrapper>
								<App />
							</LocProviderWrapper>
						</PersistGate>
					</ReduxProvider>
				</ParallaxProvider>
			</WithCookieConsent>
		</CookieContext>
	);
};

export default AppLayout;
