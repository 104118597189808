import type { CartItem, Product, ProductSnap } from "@launerlondon/products";
import {
	defaultRoundFactor,
	fmtOrderRef,
	getOrderTotal,
	getTotalNet,
	getTotalTax,
	RetailOrderRaw,
} from "@launerlondon/shared";
import { getConsentCookie } from "@launerlondon/shop-hooks";
import {
	install as installFacebookTracker,
	trackEvent as trackFacebookEvent,
	uninstall as uninstallFacebookTracker,
} from "./facebook";
import {
	fmtAnalyticsItemProduct,
	install as installGATracker,
	trackEvent as trackGAEvent,
	uninstall as uninstallGATracker,
} from "./ga";
import { trackEvent as trackMailchimpEvent } from "./mailchimp";
import {
	install as installPinterestTracker,
	trackEvent as trackPinterestEvent,
	uninstall as uninstallPinterestTracker,
} from "./pinterest";
import {
	install as installSentryTracker,
	uninstall as uninstallSentry,
} from "./sentry";

export function installTrackers() {
	const { analytics, marketing } = getConsentCookie();
	analytics ? installGATracker() : uninstallGATracker();
	analytics ? installSentryTracker() : uninstallSentry();
	marketing ? installPinterestTracker() : uninstallPinterestTracker();
	marketing ? installFacebookTracker() : uninstallFacebookTracker();
}

export function trackPageView() {
	const { analytics, marketing } = getConsentCookie();
	analytics && trackGAEvent("page_view", { page_path: location.pathname });
	marketing && trackFacebookEvent("track", "PageView");
}

export function trackViewItemList(products: ProductSnap[], listName?: string) {
	if (!products[0]?.sku) {
		return;
	}
	const { analytics, marketing } = getConsentCookie();
	analytics &&
		trackGAEvent("view_item_list", {
			item_list_name: listName,
			items: products.map(fmtAnalyticsItemProduct),
		});
	marketing && trackPinterestEvent("viewcategory");
	marketing &&
		trackFacebookEvent("track", "ViewContent", {
			content_type: "product",
			content_ids: products.map((p) => p.sku),
			content_name: listName,
		});
}

export function trackViewItem(product: Product) {
	if (!product.name) {
		return;
	}
	const { analytics, marketing } = getConsentCookie();
	analytics &&
		trackGAEvent("view_item", {
			items: [fmtAnalyticsItemProduct(product)],
		});
	marketing && trackPinterestEvent("pagevisit", { product_id: product.sku });
	marketing &&
		trackFacebookEvent("track", "ViewContent", {
			content_type: "product",
			content_ids: [product.sku],
			content_name: product.name,
		});
}

export function trackAddToCart(item: CartItem) {
	const { analytics, marketing } = getConsentCookie();
	analytics &&
		trackGAEvent("add_to_cart", {
			items: [fmtAnalyticsItemProduct(item)],
		});
	marketing &&
		trackPinterestEvent("addtocart", {
			product_id: item.sku,
			currency: "GBP",
			value: item.price?.gbp || 0,
		});
	marketing &&
		trackFacebookEvent("track", "AddToCart", {
			content_type: "product",
			content_ids: [item.sku],
			content_name: item.name,
			contents: [{ id: item.sku, quantity: 1 }],
		});
	marketing &&
		trackMailchimpEvent("addCartLine", {
			lineId: item.id,
			productId: item.sku,
			productPrice: item.price.gbp,
		});
}

export function trackRemoveFromCart(item: CartItem) {
	const { marketing } = getConsentCookie();
	marketing && trackMailchimpEvent("removeCartLine", { lineId: item.id });
}

export function trackBeginCheckout(order: RetailOrderRaw) {
	const total = getOrderTotal(order, defaultRoundFactor);
	const { analytics, marketing } = getConsentCookie();
	analytics &&
		trackGAEvent("begin_checkout", {
			currency: order.payment.currency,
			value: total.gross.gbp,
			items: order.items.map((item) => ({
				item_id: item.sku,
				item_name: item.name,
			})),
		});
	marketing &&
		trackFacebookEvent("track", "InitiateCheckout", {
			content_type: "product",
			content_ids: order.items.map((p) => p.sku),
			content_name: "Checkout",
			contents: order.items.map((p) => ({ id: p.sku, quantity: 1 })),
			value: total.gross[order.payment.currency],
			currency: order.payment.currency.toUpperCase(),
			num_items: order.items.length,
		});
}

export function trackPurchase(
	order: Omit<RetailOrderRaw, "ref"> & { ref: number },
) {
	const orderNumber = fmtOrderRef(order.ref);
	const total = getOrderTotal(order, defaultRoundFactor);
	const currency = order.payment.currency;
	const net = getTotalNet(total, order.customer.address.country);
	const tax = getTotalTax(total, order.customer.address.country);

	const { analytics, marketing } = getConsentCookie();

	analytics &&
		trackGAEvent("purchase", {
			transaction_id: orderNumber,
			currency: currency.toUpperCase(),
			value: net[currency],
			tax: tax[currency],
			shipping: total.shipping[currency],
			items: order.items.map((item) => ({
				item_id: item.sku,
				item_name: item.name,
				price: item.price[currency],
			})),
		});

	marketing &&
		trackPinterestEvent("checkout", {
			order_id: orderNumber,
			currency: "GBP",
			value: total.gross.gbp,
			line_items: order.items.map((item) => ({
				product_name: item.name,
				product_id: item.sku,
				// TODO pass category onto cart items
				product_category: "",
				product_price: item.price.gbp,
				product_quantity: 1,
				product_brand: "Launer",
			})),
		});

	marketing &&
		trackFacebookEvent("track", "Purchase", {
			content_type: "product",
			content_ids: order.items.map((p) => p.sku),
			content_name: `Order #${orderNumber}`,
			contents: order.items.map((p) => ({ id: p.sku, quantity: 1 })),
			value: total.gross[order.payment.currency],
			currency: order.payment.currency.toUpperCase(),
			num_items: order.items.length,
		});

	marketing && trackMailchimpEvent("createOrder", { orderId: orderNumber });
}
