import {
	getDynamicLocaleKey,
	getEmblemValueLocaleId,
	getGoldCornersValueLocaleId,
	getShieldingValueLocaleId,
	getStrapFittingValueLocaleId,
	getStrapStyleValueLocaleId,
	getSwatchOptionLocaleId,
	getWalletSizeValueLocaleId,
} from "@launerlondon/l10n";
import type { Amount } from "@launerlondon/shared";
import {
	defaultRoundFactor,
	fmtProductName,
	getItemOptionTotal,
} from "@launerlondon/shared";
import { Product, ProductSnap } from "../types";
import { voucherCreditLabel } from "./util";

export type CartItemSnapOption = Readonly<
	(
		| {
				type: "swatch";
				value: string | undefined;
				default: boolean;
				meta: {
					id: string;
					label: string;
					previewId?: string;
					thumb: string;
				};
		  }
		| {
				type: "strap_fitting";
				value: string | undefined;
				default: boolean;
				meta: {
					id: string;
					label: string;
					previewId?: string;
					thumb: string;
				};
		  }
		| {
				type: "gold_corners";
				value: number | undefined;
				default: boolean;
				meta: {
					id: string;
					label: string;
					previewId?: string;
					thumb: string;
				};
		  }
		| { type: "lettering"; value: string | undefined }
		| { type: "shielding"; value: true | undefined }
		| { type: "lining"; value: string }
		| { type: "voucher_credit"; value: string }
		| { type: "foiling_passport"; value: true | undefined }
		| { type: "strap_style"; value: string | undefined; default: boolean }
		| { type: "wallet_size"; value: string | undefined; default: boolean }
		| { type: "phone_size"; value: string | undefined; default: boolean }
		| { type: "tablet_size"; value: string | undefined; default: boolean }
		| { type: "emblem"; value: string | undefined; default: boolean }
	) & { multiplier: number }
>;

export type CartItemSnap = Readonly<{
	id: string;
	sku: string;
	options: CartItemSnapOption[];
}>;

export type CartItemOption = {
	label: string;
	value: string;
	labelLocaleId: string;
	valueLocaleId: string;
	default: boolean;
	multiplier: number;
};

export type CartItem = Readonly<{
	id: string;
	sku: string;
	name: string;
	price: Amount;
	options: CartItemOption[];
	thumb?: string;
}>;

const fmtLabel = (o: CartItemSnapOption): string => {
	const labels = {
		lettering: "Lettering",
		gold_corners: "Gold plated corners",
		shielding: "Faraday shield",
		wallet_size: "Wallet size",
		phone_size: "Phone model",
		tablet_size: "Tablet model",
		lining: "Lining colour",
		foiling_passport: "Passport foiling",
		strap_fitting: "Fittings",
		strap_style: "Strap type",
		emblem: "Emblem",
		fittings: "Fittings",
		voucher_credit: voucherCreditLabel,
	};
	return o.type === "swatch" ? o.meta.label : labels[o.type] || o.type;
};

const fmtValue = (o: CartItemSnapOption): string => {
	if (o.type === "gold_corners") {
		if (o.value === 1) {
			return "One pair";
		}
		if (o.value === 2) {
			return "Two pairs";
		}
	}
	if (["shielding", "foiling_passport"].includes(o.type) && o.value) {
		return "Yes";
	}
	if (o.type === "wallet_size") {
		if (o.value === "standard") {
			return 'Standard: 11cm x 9.5cm (4.25" x 3.75")';
		}
		if (o.value === "us") {
			return "US / New UK billnote";
		}
	}
	return o.value ? o.value.toString() : "—";
};

const fmtLabelLocaleId = (o: CartItemSnapOption): string => {
	const label = fmtLabel(o);
	return o.type === "swatch"
		? getSwatchOptionLocaleId(label)
		: `product-selector-${o.type}-label`;
};

const fmtValueLocaleId = (o: CartItemSnapOption): string => {
	return o.type === "swatch"
		? getDynamicLocaleKey("swatch", o.value)
		: o.type === "gold_corners"
		? getGoldCornersValueLocaleId(o.value)
		: o.type === "wallet_size"
		? getWalletSizeValueLocaleId(o.value)
		: o.type === "shielding"
		? getShieldingValueLocaleId(o.value)
		: o.type === "strap_fitting"
		? getStrapFittingValueLocaleId(o.value)
		: o.type === "strap_style"
		? getStrapStyleValueLocaleId(o.value)
		: o.type === "emblem"
		? getEmblemValueLocaleId(o.value)
		: fmtValue(o);
};

export function fmtCartItem(
	product: ProductSnap | Product,
	snap: CartItemSnap,
) {
	const options = snap.options
		.filter((o) => o.value)
		.map((o) => ({
			label: fmtLabel(o),
			value: fmtValue(o),
			labelLocaleId: fmtLabelLocaleId(o),
			valueLocaleId: fmtValueLocaleId(o),
			default: "default" in o && o.default,
			// voucher credit multiplier resets and is converted into price
			multiplier: o.type === "voucher_credit" ? 0 : o.multiplier,
		}));

	const voucherMultiplier = snap.options.find(
		(o) => o.type === "voucher_credit",
	)?.multiplier;

	const price = voucherMultiplier
		? getItemOptionTotal(
				{ multiplier: voucherMultiplier },
				product.price,
				defaultRoundFactor,
		  )
		: product.price;

	// If customer has changed at least one swatch remove colour from the product name
	const hasCustomName = snap.options.some(
		(o) => o.type === "swatch" && o.default == false,
	);

	const name = hasCustomName
		? fmtProductName(product.name)[0] || product.name
		: product.name;

	const thumb =
		"images" in product
			? product.images.product?.thumb
			: product.thumb_product?.thumb;

	const item: CartItem = {
		id: snap.id,
		sku: snap.sku,
		name,
		price,
		options,
		thumb,
	};

	return item;
}
