import { Localized } from "@launerlondon/l10n";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { routes } from "@launerlondon/shop-router";
import { useEffect, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import stylesheet, { colors, mixins } from "../styles";

type OrderStatus = "failed" | "succeeded";

const OrderStatus: React.FC = () => {
	const [ref, setRef] = useState<string | null>(null);
	const [status, setStatus] = useState<OrderStatus | null>(null);
	useMetaTags({ title: `Order status ${ref || ""}` }, [ref]);
	useEffect(() => {
		// auto-close when redirected from stripe return_url
		window.opener && window.close();
		const params = new URLSearchParams(location.search);
		setRef(params.get("ref"));
		setStatus(params.get("redirect_status") as OrderStatus);
	}, []);
	if (ref === null || status === null) {
		return null;
	}
	return (
		<div css={styles.confirmation}>
			<div css={styles.confirmationContainer}>
				<Localized
					id="order-confirm-title"
					children={<h2 css={styles.confirmationTitle} />}
				/>
				<Localized
					id="order-confirm-subtitle"
					vars={{ orderRef: ref }}
					children={<h3 css={styles.confirmationReference} />}
				/>
				{status === "succeeded" && (
					<>
						<Localized
							id="order-confirm-text"
							elems={{
								b: <b />,
								br: <br />,
								url: (
									<Link
										className="underline"
										to={generatePath(
											routes.accountOrderList,
										)}
									/>
								),
							}}
							children={
								<p className="mx-auto my-8 max-w-prose" />
							}
						/>
						<Localized
							id="order-confirm-thank_you"
							children={<p className="mt-4" />}
						/>
					</>
				)}
				{status === "failed" && (
					<Localized
						id="order-confirm-text--failed"
						children={<p />}
					/>
				)}
				<Localized
					id="order-confirm-signature"
					children={<h4 css={styles.confirmationSignature} />}
				/>
			</div>
		</div>
	);
};

const styles = stylesheet({
	confirmation: { marginBottom: "4em" },
	confirmationContainer: {
		fontSize: "0.875em",
		maxWidth: "50em",
		margin: "3em auto 1em auto",
		textAlign: "center",
	},
	confirmationTitle: {
		...mixins.title,
	},
	confirmationReference: {
		...mixins.title,
		fontSize: "1em",
	},
	confirmationSignature: {
		fontWeight: 300,
		lineHeight: 1.4,
		margin: 0,
		marginTop: 30,
		color: colors.darkerGray,
	},
});

export default OrderStatus;
