import {
	getProduct,
	getProducts,
	Product,
	ProductSnap,
} from "@launerlondon/products";
import { RootState } from "@launerlondon/shop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export function useProducts(skus?: string[]): ProductSnap[] {
	const locale = useSelector((s: RootState) => s.locale);
	const [products, setProducts] = useState<ProductSnap[]>([]);

	useEffect(() => {
		getProducts(
			locale.currency,
			locale.country,
			skus ? skus : undefined,
		).then(setProducts);
		//compare against stringified version of array
		//to avoid inifite loop on new array creation
		return () => setProducts([]);
	}, [skus?.toString(), locale.currency, locale.country]);

	return products;
}

export function useProduct(sku: string) {
	const locale = useSelector((s: RootState) => s.locale);
	const [product, setProduct] = useState<Product | undefined | null>();

	useEffect(() => {
		getProduct(locale.currency, locale.country, sku).then((p) =>
			setProduct(p || null),
		);
		return () => setProduct(undefined);
	}, [sku, locale.currency, locale.country]);

	return product;
}
