import swatches from "@launerlondon/shared/assets/swatches.json";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { ParallaxBanner } from "react-scroll-parallax";
import bannerUrl from "../assets/banner-about.jpg";
import cx from "classnames";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

type Swatch = (typeof swatches)[number]["children"][number];

const SwatchItem: React.FC<{ s: Swatch }> = ({ s }) => {
	const [zoom, setZoom] = useState(false);

	useEffect(() => {
		function onKeyDown(e: KeyboardEvent) {
			e.key === "Escape" && setZoom(false);
		}
		addEventListener("keyup", onKeyDown);
		return () => removeEventListener("keyup", onKeyDown);
	}, []);

	return (
		<motion.li
			onClick={() => setZoom((v) => !v)}
			className={cx(
				zoom ? "fixed z-40 cursor-zoom-out p-4" : "cursor-zoom-in",
				"inset-0",
				"flex flex-col items-center justify-center",
				"bg-white",
			)}
			initial={{ opacity: 0, x: -50 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0 }}
			transition={{
				type: "tween",
				duration: 0.3,
				ease: "easeOut",
			}}
		>
			<motion.img
				layout
				transition={{
					type: "tween",
					duration: 0.3,
					ease: "easeOut",
				}}
				className={cx("rounded", zoom && "max-h-[80vh]")}
				src={(zoom
					? s.thumb.replace("640x640", "1280x1280")
					: s.thumb
				)?.replace(".webp", ".jpeg")}
			/>
			<motion.span className="mt-2 block text-xs uppercase leading-normal">
				{s.name} ({s.id})
			</motion.span>
		</motion.li>
	);
};

const Swatches: React.FC = () => {
	useMetaTags({
		title: "Colour Portfolio",
		description:
			"From timeless and essential shades of black, brown and neutrals, to the vibrant brights and softer hues.",
		image: bannerUrl,
	});
	return (
		<div>
			<ParallaxBanner
				layers={[
					{
						speed: -20,
						expanded: false,
						children: (
							<div
								className="ln-banner-image"
								style={{ backgroundImage: `url(${bannerUrl}` }}
							/>
						),
					},
				]}
				className="ln-banner"
			/>
			<div className="container">
				<header className="ln-intro-grid">
					<h2 className="font-display">Colour Portfolio</h2>
					<div>
						<p>
							We offer one of the most extensive colour palettes
							across our stunning range of skins. From timeless
							and essential shades of black, brown and neutrals,
							to the vibrant brights and softer hues.
						</p>
					</div>
				</header>
			</div>
			<div className="container divide-y">
				{swatches
					.filter((g) => !g.id.startsWith("MC"))
					.map((g) => (
						<div key={g.id} className="my-10 font-sans">
							<h3 className="my-10 bg-white/90 py-4 pt-16 uppercase lg:text-lg">
								{g.name}
							</h3>
							<ul className="grid grid-cols-2 items-start gap-10 md:grid-cols-4 lg:grid-cols-6">
								{g.children.map((s) => (
									<SwatchItem key={s.id} s={s} />
								))}
							</ul>
						</div>
					))}
			</div>
		</div>
	);
};

export default Swatches;
