import { Localized } from "@launerlondon/l10n";
import { fmtOrderRef, RetailOrderRaw } from "@launerlondon/shared";
import { routes } from "@launerlondon/shop-router";
import { useEffect } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import { generatePath, Link } from "react-router-dom";
import { trackPurchase } from "../lib/analytics";
import { isEmulator } from "../lib/utils";
import { closeCart } from "../redux/actions";

type Props = ConnectedProps<typeof connector> & {
	orderRef: number;
	order?: RetailOrderRaw;
};

const connector = connect(null, { closeCart });

const CartConfirmation: React.FC<Props> = (props) => {
	const { orderRef, order, closeCart } = props;
	const orderNumber = fmtOrderRef(orderRef);

	useEffect(() => {
		if (!order) return;
		trackPurchase({ ...order, ref: orderRef });
		!isEmulator && closeCart();
	}, [order, orderRef, closeCart]);

	return (
		<div className="container mx-auto my-10 max-w-xl text-center lg:my-20">
			<header className="mb-8">
				<h2 className="font-display pb-2 text-lg font-light uppercase tracking-widest lg:border-b lg:text-xl">
					<Localized id="order-confirm-title" />
				</h2>
				<h3 className="mt-10 text-sm uppercase tracking-widest">
					<Localized
						id="order-confirm-subtitle"
						vars={{ orderRef: orderNumber }}
					/>
				</h3>
			</header>
			<div className="space-y-4 rounded-sm bg-gray-50 p-8 text-sm">
				<Localized id="order-confirm-text" children={<p />} />
				<ul className="mx-auto flex max-w-sm justify-center divide-x">
					{["handbags", "accessories"].map((k) => (
						<Localized
							key={k}
							id={`order-confirm-timings-${k}`}
							elems={{ b: <b className="block" /> }}
							children={<li className="my-6 px-8" />}
						/>
					))}
				</ul>
				<Localized
					id="order-confirm-progress"
					elems={{
						url: (
							<Link
								className="underline"
								to={generatePath(routes.accountOrderList)}
							/>
						),
					}}
					children={<p />}
				/>
				<Localized id="order-confirm-thank_you" children={<p />} />
				<Localized id="order-confirm-signature" children={<p />} />
			</div>
		</div>
	);
};

export default connector(CartConfirmation);
