import { VoucherRef, voucherFormat } from "@launerlondon/shared";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { useEffect, useRef } from "react";
import { Form, useActionData } from "react-router-dom";

const bannerUrl =
	"https://assets.launer.com/images/products/0001-01_20_1280x1280.jpeg";
const VoucherBalance: React.FC = () => {
	useMetaTags({
		title: "Check Launer Gift Card Balance",
		description:
			"Utility to help you to keep on top of the current balance of your gift card at Launer",
		image: bannerUrl,
	});
	const data = useActionData() as { voucher?: VoucherRef } | undefined;
	const voucher = data?.voucher;
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => inputRef.current?.focus(), [inputRef]);

	return (
		<div>
			<div className="container">
				<header className="ln-intro-grid">
					<h2>Gift Card Balance</h2>
					<p>
						Keep on top of the current balance of your gift card at
						Launer.
					</p>
				</header>
				<div className="ln-section-grid gap-10 pb-10">
					<div>
						<img src={bannerUrl} className="rounded" />
					</div>
					<Form method="POST">
						<p>
							Please type the code of your gift voucher here to
							check its current balance.
						</p>
						<input
							ref={inputRef}
							type="text"
							name="code"
							className="w-full max-w-[14rem] rounded border-0 bg-gray-200 p-4 font-mono text-xl"
							autoCorrect="off"
							autoComplete="off"
							required
							placeholder={voucherFormat.replace(/\w/g, "0")}
						/>
						<h2>
							Available balance:
							<b>
								{voucher
									? `${voucher.amount} ${voucher.currency}`
									: " 0 GBP"}
							</b>
						</h2>
						<button className="button m-0 w-full max-w-[14rem]">
							Check Balance
						</button>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default VoucherBalance;
