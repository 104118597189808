import { ShoppingBagIcon } from "@heroicons/react/24/outline";
import { routes } from "@launerlondon/shop-router";
import { RootState } from "@launerlondon/shop-types";
import cx from "classnames";
import { useSelector } from "react-redux";
import { generatePath, Link } from "react-router-dom";

const CartWidget: React.FC<{ className?: string }> = ({ className }) => {
	const empty = useSelector((s: RootState) => s.cart.length === 0);
	return (
		<Link
			to={generatePath(routes.checkout)}
			className={cx("relative", className)}
		>
			<ShoppingBagIcon className="h-5 w-5" />
			<div
				className={cx(
					empty && "hidden",
					"absolute bottom-0 right-0 h-2 w-2",
					"rounded-full bg-red-500",
				)}
			/>
		</Link>
	);
};

export default CartWidget;
