import { initializeApp, getApps } from "firebase/app";
import {
	Analytics,
	getAnalytics,
	setAnalyticsCollectionEnabled,
	isSupported,
	logEvent,
	Item,
	EventNameString,
} from "firebase/analytics";

declare global {
	interface Window {
		dataLayer: unknown[];
		gtag: (
			key: string,
			value: string,
			opts?: Record<string, unknown>,
		) => void;
	}
}

const config: Record<string, string> | null = JSON.parse(
	import.meta.env.VITE_FIREBASE_CONFIG || "null",
);

let app = getApps()[0];
let analytics: Analytics;

export const install = () => {
	if (!config) {
		console.error("missing firebase config");
		return;
	}
	if (!config.measurementId) {
		console.error("missing firebase config measurementId");
		return;
	}

	if (!app) {
		app = initializeApp(config);
	}

	// fix ga not assigning correct cookie domain on *.web.app
	// see: https://github.com/firebase/firebase-js-sdk/issues/4123
	self.dataLayer = self.dataLayer || [];
	self.gtag = function () {
		// eslint-disable-next-line prefer-rest-params
		self.dataLayer.push(arguments);
	};
	self.gtag("config", config.measurementId, {
		cookie_domain: location.hostname,
		cookie_flags: "SameSite=None;Secure",
	});

	analytics = getAnalytics(app);
	setAnalyticsCollectionEnabled(analytics, true);
};

export const uninstall = () => {
	if (analytics) {
		setAnalyticsCollectionEnabled(analytics, false);
	}
};

export const trackEvent = async (
	eventName: EventNameString,
	eventParams: Record<string, any>,
) => {
	if (!analytics || !isSupported()) return;
	return logEvent(analytics, eventName as string, eventParams);
};

export const fmtAnalyticsItemProduct = (product: {
	sku: string;
	name: string;
	price: { gbp: number };
}): Item => {
	return {
		item_id: product.sku,
		item_name: product.name,
		price: product.price.gbp,
	};
};
