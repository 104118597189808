import type { CartItemSnap } from "@launerlondon/products";
import type { Action, Reducer } from "redux";
import { sortCartItemOptions } from "../../lib/cart";
import type { RootThunkAction } from "../store";

type CartAction =
	| (Action<"ADD_CART_ITEM"> & { item: CartItemSnap })
	| (Action<"REMOVE_CART_ITEM"> & { itemId: string })
	| Action<"CLOSE_CART">;

//type CartActionCreator = ActionCreator<CartAction>;
type CartReducer = Reducer<CartItemSnap[], CartAction>;

const CartReducer: CartReducer = (state = [], action) => {
	switch (action.type) {
		case "ADD_CART_ITEM":
			sortCartItemOptions(action.item);
			return [...state.concat([action.item])];
		case "REMOVE_CART_ITEM": {
			return state.filter((item) => action.itemId !== item.id);
		}
		case "CLOSE_CART":
			return [];
		default:
			return state;
	}
};

type AddCartItem = (
	item: CartItemSnap,
) => RootThunkAction<CartAction, CartItemSnap>;
const addCartItem: AddCartItem = (item) => (dispatch, getState) => {
	const { cart } = getState();
	if (cart.some((i) => i.id === item.id)) return item;
	const itemId = item.id || Math.random().toString(36).substring(2, 4);
	const newItem = { ...item, id: itemId };
	dispatch({ type: "ADD_CART_ITEM", item: newItem });
	return newItem;
};

type RemoveCartItem = (itemdId: string) => RootThunkAction<CartAction, void>;
const removeCartItem: RemoveCartItem = (itemId) => (dispatch) => {
	dispatch({ type: "REMOVE_CART_ITEM", itemId });
};

type CloseCart = () => RootThunkAction<CartAction, void>;
const closeCart: CloseCart = () => (dispatch) => {
	dispatch({ type: "CLOSE_CART" });
};

export default CartReducer;
export { addCartItem, removeCartItem, closeCart };
