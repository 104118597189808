import { Localized } from "@launerlondon/l10n";
import { useCookieConsent } from "@launerlondon/shop-hooks";
import cx from "classnames";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { isDNT } from "../lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Props = {
	onSettingsClick?: () => void;
};

const CookieBar: React.FC<Props> = (props) => {
	const toastId = "cookieBar";
	const [consent, setConsent] = useCookieConsent();

	useEffect(() => {
		if (consent.essential) {
			toast.dismiss(toastId);
			return;
		}
		const id = toast.custom(
			(t) => (
				<div
					className={cx(
						t.visible ? "animate-enter" : "animate-leave",
						"flex w-full flex-col items-center rounded-lg",
						"p-4",
						"text-center font-sans text-xs",
						"bg-neutral-900/95 text-neutral-100",
						"backdrop-blur",
						"md:flex-row md:text-left",
					)}
				>
					<Localized
						id="cookie-bar-text"
						elems={{
							a: (
								<a
									className="underline"
									href="https://launer.com/terms-conditions-privacy"
									target="_blank"
								/>
							),
						}}
						children={
							<p className="mb-4 max-w-2xl md:mb-0 md:mr-4" />
						}
					/>
					<div
						className={cx(
							"flex w-full flex-col",
							"md:mb-0 md:ml-auto md:w-auto md:flex-row",
							!isDNT && "md:mt-8",
						)}
					>
						<button
							className="button button-ghost relative min-w-[260px] overflow-auto border text-xs md:mr-4"
							onClick={() =>
								setConsent({
									essential: true,
									analytics: !isDNT,
									marketing: !isDNT,
								})
							}
						>
							<Localized
								id={
									isDNT
										? "cookie-bar-button--accept-essential"
										: "cookie-bar-button--accept-all"
								}
								elems={{
									s: (
										<small
											className={cx(
												"absolute block ",
												"-top-2 left-1/2 -translate-x-1/2 px-2",
												"rounded-sm bg-neutral-900 text-white",
											)}
										/>
									),
								}}
								children={<span />}
							/>
						</button>
						{!isDNT && (
							<button
								className={cx(
									"text-[11px]",
									"md:absolute md:right-3 md:top-4",
									"md:flex md:items-center md:justify-end md:gap-1",
									"md:uppercase md:tracking-wider",
									"max-md:button max-md:button-ghost text-xs max-md:relative max-md:border",
								)}
								onClick={() =>
									setConsent({
										essential: true,
										analytics: false,
										marketing: false,
									})
								}
							>
								<span>Reject Non-Essential</span>
								<XMarkIcon
									className="-mt-1 h-6 w-6 max-md:hidden"
									strokeWidth={1.2}
								/>
							</button>
						)}
						<button
							className="button button-ghost border text-xs"
							onClick={props.onSettingsClick}
						>
							<Localized id="cookie-bar-button--custom" />
						</button>
					</div>
				</div>
			),
			{
				id: toastId,
				position: "bottom-left",
				duration: Infinity,
			},
		);
		return () => toast.dismiss(id);
	}, [consent]);

	return null;
};

export default CookieBar;
