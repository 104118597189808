import axios from "axios";
import { getCartUrl } from "../cart";
import store from "../../redux/store";
import { setCartId, setPendingItems } from "../../redux/actions";

const mcRequest = axios.create({
	method: "POST",
	baseURL: "/api/mcec",
});

type CartLine = { lineId: string; productId: string; productPrice: number };
async function addCartLine(item: CartLine) {
	const {
		meta: { cartId, customerId, pendingItems },
		cart,
	} = store.getState();
	const refId = cartId || customerId;

	// allow syncing previously added items
	if (!refId) {
		store.dispatch(setPendingItems([...(pendingItems || []), item]));
		return;
	}

	const checkoutUrl = await getCartUrl(cart);
	const r = await Promise.all(
		[item, ...(pendingItems || [])].map((l) => {
			return mcRequest({
				params: { action: "addCartLine" },
				data: {
					refId,
					lineId: l.lineId,
					productId: l.productId,
					productPrice: l.productPrice,
					checkoutUrl,
				},
			});
		}),
	);

	store.dispatch(setCartId(r[0]?.data.cartId || cartId));
	store.dispatch(setPendingItems(undefined));
}

async function removeCartLine(item: { lineId: string }) {
	const {
		meta: { cartId },
		cart,
	} = store.getState();
	if (!cartId) return;
	const checkoutUrl = await getCartUrl(cart);
	const r = await mcRequest({
		params: { action: "removeCartLine" },
		data: {
			cartId,
			lineId: item.lineId,
			checkoutUrl,
		},
	});
	/**
	 * once result is empty, it means it that the last cart item has been
	 * removed and the cart has now been deleted
	 */
	if (r.data.cartId === undefined) {
		store.dispatch(setCartId(undefined));
	}
}

async function createOrder(data: { orderId: string }) {
	const {
		meta: { cartId },
	} = store.getState();
	if (!cartId) return;

	await mcRequest({
		params: { action: "createOrder" },
		data: { cartId, orderId: data.orderId },
	});

	// remove cart id as order is now complete
	store.dispatch(setCartId(undefined));
}

type EventType = "addCartLine" | "removeCartLine" | "createOrder";
type EventData<T extends EventType> = T extends "addCartLine"
	? Parameters<typeof addCartLine>[0]
	: T extends "removeCartLine"
	? Parameters<typeof removeCartLine>[0]
	: T extends "createOrder"
	? Parameters<typeof createOrder>[0]
	: never;

export function trackEvent<T extends EventType>(event: T, data: EventData<T>) {
	if (event === "addCartLine") {
		addCartLine(data as EventData<"addCartLine">);
	}
	if (event === "removeCartLine") {
		removeCartLine(data as EventData<"removeCartLine">);
	}
	if (event === "createOrder") {
		createOrder(data as EventData<"createOrder">);
	}
}
