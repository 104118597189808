import type { RetailOrderRaw } from "@launerlondon/shared";
import {
	defaultRoundFactor,
	getItemTotal,
	getOrderTotal,
	getTotalTax,
} from "@launerlondon/shared";
import type { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

const STRIPE_PK = String(import.meta.env.VITE_STRIPE_PK);
const apiVersion = "2023-10-16";

const stripePromise = () => {
	//if (self.Stripe) {
	//	return self.Stripe(STRIPE_PK, { apiVersion }) as unknown as Stripe;
	//}
	// https://stripe.com/docs/disputes/prevention/advanced-fraud-detection
	// enabled as part of essential cookies to prevent fraud
	if (!self.Stripe) {
		loadStripe.setLoadParameters({ advancedFraudSignals: true });
	}
	return loadStripe(STRIPE_PK, { apiVersion });
};

export const getPaymentRequestType = async (stripe: Stripe) => {
	const dummyPr = {
		country: "GB",
		currency: "gbp",
		total: { label: "featureCheck", amount: 0 },
	};
	const hasPaymentMethod = await stripe
		.paymentRequest(dummyPr)
		.canMakePayment();
	if (!hasPaymentMethod) return;
	const r: "apple" | "microsoft" | "google" = hasPaymentMethod.applePay
		? "apple"
		: navigator.userAgent.match(/edg/i)
		? "microsoft"
		: "google";
	return r;
};

export const requestPayment = async (
	stripe: Stripe,
	order: RetailOrderRaw,
	callbacks: { onCancel(): void },
) => {
	const total = getOrderTotal(order, defaultRoundFactor);
	const pr = stripe.paymentRequest({
		country: "GB",
		currency: "gbp",
		total: { label: "Total", amount: total.gross.gbp * 100 },
		displayItems: [
			...order.items.map((item) => ({
				label: item.name,
				amount: getItemTotal(item, defaultRoundFactor).gbp * 100,
			})),
			{
				label: "Shipping",
				amount: total.shipping.gbp * 100,
			},
			{
				label: "Tax/duties",
				amount:
					getTotalTax(total, order.customer.address.country).gbp *
					100,
			},
		],
		shippingOptions: [
			{
				id: "standard",
				label: "Standard shipping",
				detail: "Via Royal Mail or DHL Parcel",
				amount: total.shipping.gbp * 100,
			},
		],
	});
	const isEnabled = await pr.canMakePayment();
	if (!isEnabled) return;

	return new Promise<string>((resolve) => {
		pr.on("cancel", callbacks.onCancel);
		pr.on("paymentmethod", (ev) => {
			resolve(ev.paymentMethod.id);
			ev.complete("success");
		});
		pr.show();
	});
};

export default stripePromise;
